import i18n from "@/plugins/i18n"
import { MENU, CATEGORY, mapQueryFieldName, } from "@/constants/mapVarNames"

export default {
  methods: {
    $_param_createParams() {
      const lookupState = this.$store.state.lookup
      const menu = lookupState.lookupMenu
      const category = lookupState.lookupCategory
      const { searchInput, sort, } = lookupState[menu][category]
      const filterItems = { ...lookupState[menu][category].filterItems, }
      const params = {
        sort,
        q: searchInput || null,
      }

      for (const prop in filterItems) {
        if (filterItems[prop] !== i18n.t("options.NOT_SELECTED")) {
          const key = mapQueryFieldName[prop] ? mapQueryFieldName[prop] : prop

          params[key] = filterItems[prop]
        }
      }

      if (menu === MENU.gamesets) {
        if (category === CATEGORY.gamedata || category === CATEGORY.rawdata) {
          params.key = lookupState.gamesets.gamedata.currentGame
        }

        params.hide_outlier = lookupState[menu][category].hideOutlier
      }

      return params
    },
  },
}

import PageTitle from "@/components/common/headers/PageTitle"
import Pagination from "@/components/lookup/Pagination"
import FilterList from "@/components/lookup/FilterList"
import Search from "@/components/lookup/Search"
import Excel from "@/components/lookup/Excel"

export default {
  components: {
    PageTitle,
    Pagination,
    FilterList,
    Search,
    Excel,
  },
  computed: {
    lookupMenu() {
      return this.$store.state.lookup.lookupMenu
    },
    lookupCategory() {
      return this.$store.state.lookup.lookupCategory
    },
    dataList() {
      return this.$store.state.lookup[this.lookupMenu][this.lookupCategory].dataList
    },
  },
}

import { setItem, } from "../../utils/storage"

export default {
  namespaced: true,
  state: {
    pageSize: {
      template: 20,
      criteria: 20,
      emailservice: 20,
      fakename: 20,
      funnel: 20,
    },
  },
  mutations: {
    SET_PAGE_SIZE(state, pageSizeInfo) {
      state.pageSize[pageSizeInfo["table_name"]] = pageSizeInfo["page_size"]
      setItem("pagesize", state.pageSize)
    },
    RESET_PAGE_SIZE_STATE(state, pageSizeObj) {
      state.pageSize = pageSizeObj
    },
  },
  actions: {
    setPageSize({ commit, }, pageSizeInfo) {
      commit("SET_PAGE_SIZE", pageSizeInfo)
    },
    resetPageSize({ commit, }, pageSizeObj) {
      commit("RESET_PAGE_SIZE_STATE", pageSizeObj)
    },
  },
}

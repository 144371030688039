import http from "./http"

export function requestCriteriaData(params) {
  return http.get("admin/criteria/", { params, })
}

export function requestCriteriaDetailData(criteriaId) {
  return http.get(`admin/criteria/${criteriaId}/`)
}

export function createCriteria(data) {
  return http.post("admin/criteria/", { ...data, })
}

export function deleteCriteria(criteriaId) {
  return http.delete(`admin/criteria/${criteriaId}/`)
}

export function editCriteria(criteriaId, data) {
  return http.put(`admin/criteria/${criteriaId}/`, { ...data, })
}

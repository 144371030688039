<template>
  <div>
    <PageTitle :value="title" />
    <div>
      <div
        v-for="(value, name) in userData"
        :key="name"
      >
        <Input
          v-if="inputData[name].type === 'text'"
          v-model="userData[name]"
          :label="name"
          :is-disabled="inputData[name].isDisabled"
        />
        <Checkbox
          v-else-if="inputData[name].type === 'checkbox'"
          v-model="userData[name]"
          :label="name"
        />
        <Select
          v-else-if="inputData[name].type === 'select'"
          v-model="userData[name]"
          :label="name"
          :items="items[name].options"
          :item-value="items[name].itemValue"
        />
        <div
          v-else-if="inputData[name].type === 'file'"
          class="file_wrapper"
        >
          <a
            v-if="userData.registration"
            class="link"
            :href="userData.registration"
            target="_blank"
          >
            {{ `${userData.registration.slice(0, 55)}...` }}
          </a>
          <v-file-input
            :id="name"
            v-model="pdfFile"
            label="upload pdf"
            accept=".pdf, .jpg, .png"
            @change="handleUpdatePdf"
          />
        </div>
      </div>
    </div>
    <div class="footer">
      <v-btn
        color="error"
        class="mr-12"
        @click="$_detail_handleOpen"
      >
        {{ $t("detail.WITHDRAW") }}
      </v-btn>
      <div class="save_btn">
        <SaveButton @click="handleSaveCompany(userData)" />
      </div>
    </div>
    <Modal
      v-if="isModalShown"
      :title="$t('modal.WARNING')"
      :desc="$t('modal.UNSUBSCRIBE_MESSAGE')"
      @yes="$_detail_handleProceed($_detail_handleDelete)"
      @no="$_detail_handleClose"
    />
  </div>
</template>

<script>
import PageTitle from "@/components/common/headers/PageTitle"
import Input from "@/components/common/formControls/Input"
import Checkbox from "@/components/common/formControls/Checkbox"
import SaveButton from "@/components/common/buttons/SaveButton"
import Select from "@/components/common/formControls/Select"
import Modal from "@/components/common/modals/Modal"
import detail from "@/mixins/detail"
import options from "@/mixins/options"

export default {
  components: {
    PageTitle,
    Input,
    Checkbox,
    SaveButton,
    Select,
    Modal,
  },
  mixins: [detail, options],
  data() {
    return {
      title: this.$t("nav.USER_COMPANY"),
      userData: {
        id: "",
        username: "",
        name: "",
        tel: "",
        company_name: "",
        company_size: "",
        registration: null,
        company_approved: "",
        consent_terms: "",
        consent_privacy: "",
        consent_push: "",
        consent_marketing: "",
        is_active: "",
        date_joined: "",
      },
      pdfFile: null,
      inputData: {
        id: {
          type: "text",
          isDisabled: true,
        },
        username: {
          type: "text",
          isDisabled: true,
        },
        name: {
          type: "text",
        },
        tel: {
          type: "text",
        },
        company_name: {
          type: "text",
        },
        company_size: {
          type: "select",
        },
        registration: {
          type: "file",
        },
        company_approved: {
          type: "checkbox",
        },
        consent_terms: {
          value: "",
          type: "checkbox",
        },
        consent_privacy: {
          value: "",
          type: "checkbox",
        },
        consent_push: {
          value: "",
          type: "checkbox",
        },
        consent_marketing: {
          value: "",
          type: "checkbox",
        },
        is_active: {
          type: "checkbox",
        },
        date_joined: {
          type: "text",
        },
      },
    }
  },
  methods: {
    handleSaveCompany() {
      const data = { ...this.userData, }

      delete data.registration
      this.$_detail_handleSave(data)
    },
    async handleUpdatePdf() {
      let fileData

      if (this.pdfFile) {
        fileData = new FormData()
        fileData.append("registration", this.pdfFile)
      } else {
        fileData = { registration: null, }
      }

      const data = await this.$_detail_handleSave(fileData, false)

      this.userData.registration = data.registration
    },
  },
}
</script>

<style scoped>
.file_wrapper {
  width: 100%;
}

.footer {
  display: flex;
  position: relative;
  margin: 2rem 0;
  width: 100%;
}
</style>

<template>
  <div class="d-flex flex-column">
    <div
      v-if="croppedImageSrc"
      class="preview_container"
    >
      <img
        :src="croppedImageSrc"
        class="preview"
        alt=""
      >
      <v-btn @click="resetThumbnail">
        {{ $t("detail.RESET_DEFAULT_IMAGE") }}
      </v-btn>
    </div>
    <v-file-input
      id="thumbnail"
      v-model="selectedFile"
      outlined
      dense
      type="file"
      class="file_input"
      accept=".jpg, .jpeg, .png"
      :label="$t('gamesets.THUMBNAIL')"
    />
    <v-dialog v-model="isModalShown">
      <CropperModal
        v-if="isModalShown"
        :image-src="imageSrc"
        @reset="resetThumbnail"
        @close="closeModal"
        @save="saveImage"
      />
    </v-dialog>
  </div>
</template>

<script>
import CropperModal from "@/components/inputs/image/CropperModal"
import { uploadThumbnail, } from "@/api/lookup"

export default {
  components: {
    CropperModal,
  },
  props: {
    previousImage: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      imageSrc: null,
      selectedFile: null,
      croppedImageSrc: this.previousImage ? this.previousImage.file : null,
      fileReader: new FileReader(),
      isModalShown: false,
    }
  },
  watch: {
    selectedFile(value) {
      if (value) {
        this.fileReader.readAsDataURL(value)
      } else {
        this.imageSrc = null
        this.croppedImageSrc = null
        this.$emit("set-image-id", null)
      }
    },
  },
  created() {
    this.fileReader.onload = (event) => {
      this.isModalShown = true
      this.imageSrc = event.target.result
    }
  },
  methods: {
    async saveImage(imageBlob) {
      this.isModalShown = false

      try {
        const file = new File([imageBlob], this.selectedFile.name)
        const formData = new FormData()

        formData.append("file", file)

        const response = await uploadThumbnail(formData)
        const files = response.data.data.file
        const id = response.data.data.id

        this.croppedImageSrc = files
        this.$emit("set-image-id", id)
      } catch (error) {
        throw Error(error)
      }
    },
    resetThumbnail() {
      this.croppedImageSrc = null
      this.imageSrc = null
      this.selectedFile = null
      this.$emit("set-image-id", null)
    },
    closeModal() {
      this.isModalShown = false
    },
  },
}
</script>

<style scoped>
.label_wrapper {
  width: 128px;
}

.file_input {
  width: 100%;
}

.preview_container {
  display: flex;
  align-items: flex-end;
  margin-bottom: 18px;
}

.preview {
  width: 200px;
  border-radius: 4px;
  border: 1px solid black;
  margin-right: 12px;
}

.uploader_wrapper {
  width: 100%;
}
</style>

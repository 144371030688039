<template>
  <div class="user_search_and_select_wrapper">
    <label
      for="input_elem"
      class="input_label"
    >
      {{ capitalize(desc) }}
    </label>
    <v-autocomplete
      :items="reconstructedData"
      :search-input.sync="search"
      dense
      outlined
      multiple
      class="auto_complete"
      @change="selectedUser"
    />
  </div>
</template>

<script>
export default {
  props: {
    desc: {
      type: String,
      default: "",
    },
    users: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      items: ["foo", "bar", "fizz", "buzz"],
      value: null,
      search: null,
      reconstructedData: [],
      selectedUsers: [],
    }
  },
  computed: {
    capitalize() {
      return (desc) => {
        return `${desc.slice(0, 1).toUpperCase()}${desc.slice(1).toLowerCase()}`
      }
    },
  },
  watch: {
    users() {
      this.reconstructionUsersData(this.users)
    },
  },
  methods: {
    selectedUser(userArray) {
      this.search = ""
      this.selectedUsers = userArray
      this.$emit("setUsers", this.selectedUsers)
    },
    reconstructionUsersData(users) {
      users.forEach((user) => {
        const userObj = {
          "text": user.name,
          "value": user,
        }

        this.reconstructedData.push(userObj)
      })
    },
  },
}
</script>

<style scoped>
.user_search_and_select_wrapper {
  width: 100%;
  height: 2.5rem;
  display: flex;
  font-size: 1rem;
  align-items: center;
  position: relative;
  margin-bottom: 0.5rem;
}

.input_label {
  width: 7rem;
  height: 100%;
  border: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 0.9rem;
  letter-spacing: -1;
  border-radius: 4px;
  margin-right: 1rem;
  flex-shrink: 0;
}

.auto_complete {
  width: calc(100% - 7rem);
  height: 100%;
}
</style>

<template>
  <div>
    <PageTitle
      :value="title"
      :suffix="$t('common.MANAGEMENT')"
    />
    <Search
      v-show="showTableStatus"
      class="search_component"
      :store-module-name="storeModuleName"
      :placeholder="$t('lookupPlaceholder.CRITERIA')"
      @search="setFetchedData"
    />
    <div class="create_button_position">
      <CreateButton
        class="createButton"
        @click="redirection({ id: 'new', })"
      />
    </div>
    <div
      v-if="showTableStatus"
      class="v-table-custom"
    >
      <v-data-table
        :headers="tableHeaders"
        :items="dataList"
        :items-per-page="pageSize"
        item-key="id"
        hide-default-footer
        class="elevation-1"
        @click:row="redirection"
      >
        <template #[`item.games`]="{ item }">
          <span
            v-for="(game, index) in item.games"
            :key="game.id"
            class="game_id"
          >
            {{ game.id }}<span v-if="isLastGame(index, item)">&#44;</span>
          </span>
        </template>
        <template #[`item.is_active`]="{ item }">
          <TableIcon :value="item.is_active" />
        </template>
        <template #[`item.created_at`]="{ item }">
          <span>
            {{ item.created_at.slice(0,10) }}
          </span>
        </template>
      </v-data-table>
    </div>
    <Pagination
      v-if="showTableStatus"
      :page-info="pageInfo"
      :target="pageTarget"
      :page-size="pageSize"
      @resetData="resetData"
      @updatePageSize="$_pageSize_updatePageSize"
    />
  </div>
</template>

<script>
import CreateButton from "@/components/common/buttons/CreateButton"
import PageTitle from "@/components/common/headers/PageTitle"
import Search from "@/components/common/Search"
import Pagination from "@/components/common/Pagination"
import bus from "../../utils/bus"
import errorHandling from "@/mixins/dataFetchErrorHandling"
import pageSize from "../../mixins/pageSize"
import TableIcon from "@/components/lookup/TableIcon"

export default {
  components: {
    TableIcon,
    Search,
    Pagination,
    PageTitle,
    CreateButton,
  },
  mixins: [errorHandling, pageSize],
  data() {
    return {
      tableHeaders: [
        { text: "id", align: "start", value: "id", },
        { text: "key", value: "key", sortable: false, },
        { text: "version", value: "version", sortable: false, },
        { text: "is_active", value: "is_active", sortable: false, },
        { text: "created_at", value: "created_at", sortable: false, }
      ],
      pageInfo: {},
      pageTarget: [null, null],
      dataList: [],
      createModal: false,
      showTableStatus: false,
      title: "",
      storeModuleName: "",
      pageSize: 20,
      fetchParams: null,
    }
  },
  created() {
    this.setInitialData()
  },
  methods: {
    async setInitialData() {
      const routeName = this.$route.name

      this.setPageTarget(routeName)
      this.setTitle(routeName)
      this.fetchParams = await this.$_pageSize_getTablePageSize(routeName)
      this.fetchData(this.fetchParams)
      this.setStoreModuleName(routeName)
    },
    setStoreModuleName(routeName) {
      this.storeModuleName = routeName.toLowerCase()
    },
    setTitle(routeName) {
      this.title = this.$t(`title.${routeName.toUpperCase()}`)
    },
    setPageTarget(routeName) {
      this.pageTarget[0] = `${routeName.slice(0, 1).toLowerCase()}dl`
      this.pageTarget[1] = `${routeName.slice(0, 1).toLowerCase()}pi`
    },
    async fetchData(keyword = {}) {
      try {
        const response = await this.$store.dispatch("criteria/fetchData", keyword)

        this.setFetchedData(response)
      } catch (error) {
        this.errorHandling(error)
      }
    },
    async setFetchedData(response) {
      this.showTableStatus = false
      this.dataList = response.data
      this.pageInfo = response.pagination
      bus.$emit("end:loading")
      this.showTableStatus = true
    },
    resetData(responseData) {
      this.dataList = responseData.dataList
      this.pageInfo = responseData.pageInfo
      bus.$emit("end:loading")
    },
    redirection(item) {
      this.$router.push({ name: "setCriteria", params: { id: item.id, }, })
    },
  },
}
</script>

<style scoped>
@import url("../../assets/styles/common/adminCommonComponents.css");

.search_component {
  margin-bottom: 1rem;
}

.createButton {
  margin-bottom: 1rem;
}

.create_button_position {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
</style>

<template>
  <div class="form_wrapper">
    <PageTitle
      :value="title"
      :suffix="$t('common.MANAGEMENT')"
    />
    <Search
      v-show="showTableStatus"
      class="search_component"
      :store-module-name="storeModuleName"
      :placeholder="$t('lookupPlaceholder.FORM')"
      @search="setFetchedData"
    />
    <div
      v-show="showTableStatus"
      class="filter_write_button_wrapper"
    >
      <Filters
        v-show="showTableStatus"
        :options="options"
        :store-module-name="storeModuleName"
        class="filter_component"
        @search="setFetchedData"
      />
      <CreateButton
        @click="createForm"
      />
    </div>
    <Table
      v-show="showTableStatus"
      ref="table"
      :headers="tableHeaders"
      :data="dataList"
      :title="title"
      :page-size="pageSize"
      class="template_table"
    />
    <Pagination
      v-if="showTableStatus"
      :page-info="pageInfo"
      :target="pageTarget"
      :page-size="pageSize"
      @resetData="resetData"
      @updatePageSize="$_pageSize_updatePageSize"
    />
  </div>
</template>

<script>
import Filters from "@/components/common/Filters.vue"
import CreateButton from "@/components/common/buttons/CreateButton"
import Pagination from "@/components/common/Pagination.vue"
import errorHandling from "@/mixins/dataFetchErrorHandling"
import PageTitle from "@/components/common/headers/PageTitle"
import Search from "@/components/common/Search.vue"
import Table from "@/components/common/Table.vue"
import pageSize from "../../mixins/pageSize"
import bus from "@/utils/bus"

export default {
  components: {
    CreateButton,
    Filters,
    PageTitle,
    Pagination,
    Search,
    Table,
  },
  mixins: [errorHandling, pageSize],
  data() {
    return {
      tableHeaders: [
        { text: "id", align: "start", value: "id", sortable: false, },
        { text: "name", value: "name", sortable: false, },
        { text: "tests", value: "tests", sortable: false, },
        { text: "is_active", value: "is_active", sortable: false, },
        { text: "created_at", value: "created_at", }
      ],
      options: [
        {
          id: "active",
          label: this.$t("filter.label.ACTIVE_STATUS"),
          value: [
            { text: this.$t("filter.option.ALL"), value: null, },
            { text: this.$t("filter.option.ACTIVE"), value: true, },
            { text: this.$t("filter.option.DISABLE"), value: false, }
          ],
        }
      ],
      pageInfo: { current_pa: 0, page_total: 0, },
      pageTarget: ["fodl", "fopi"],
      showTableStatus: false,
      storeModuleName: "",
      title: "",
      dataList: null,
      pageSize: 20,
    }
  },
  created() {
    this.setInitialData()
  },
  methods: {
    async setInitialData() {
      const routeName = this.$route.name

      this.setTitleName(routeName)
      this.setStoreModuleName(routeName)
      this.fetchParams = await this.$_pageSize_getTablePageSize(routeName)
      this.fetchData(this.fetchParams)
    },
    setTitleName(routeName) {
      this.title = this.$t(`title.${routeName.toUpperCase()}`)
    },
    setStoreModuleName(routeName) {
      this.storeModuleName = routeName.toLowerCase()
    },
    async fetchData(keyword = {}) {
      try {
        const response = await this.$store.dispatch("form/fetchData", keyword)

        this.setFetchedData(response)
      } catch (error) {
        this.errorHandling(error)
      }
    },
    setFetchedData(response) {
      this.showTableStatus = false
      this.dataList = response.data
      this.$refs.table.resetItems(this.dataList)
      this.pageInfo = response.pagination
      bus.$emit("end:loading")
      this.showTableStatus = true
    },
    createForm() {
      this.$router.push({ name: "setForm", params: { id: "new", }, })
    },
    resetData(responseData) {
      this.dataList = responseData.dataList
      this.pageInfo = responseData.pageInfo
      this.$refs.table.resetItems(this.dataList)
      bus.$emit("end:loading")
    },
  },
}
</script>

<style scoped>
.search_component {
  margin-bottom: 1rem;
}

.filter_write_button_wrapper {
  width: 100%;
  height: 100%;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}
</style>

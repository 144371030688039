<template>
  <v-btn
    color="primary"
    @click="handleClick"
  >
    {{ $t('common.CREATE') }}
  </v-btn>
</template>

<script>
export default {
  methods: {
    handleClick() {
      this.$emit("click")
    },
  },
}
</script>

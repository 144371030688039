<template>
  <div class="search_wrapper">
    <v-text-field
      v-model="value"
      type="search"
      clearable
      dense
      hide-details="true"
      class="ma-0"
      prepend-icon="mdi-magnify"
      :label="label"
      single-line
      @keypress.enter="handleSearch"
      @click:clear="handleClear"
    />
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      value: null,
    }
  },
  created() {
    const menu = this.$store.state.lookup.lookupMenu
    const category = this.$store.state.lookup.lookupCategory
    const searchInput = this.$store.state.lookup[menu][category].searchInput

    this.value = searchInput
  },
  methods: {
    handleSearch() {
      this.$store.commit("UPDATE_SEARCH_INPUT", this.value)
      this.$store.dispatch("fetchLookupData")
    },
    handleClear() {
      this.value = null
      this.handleSearch()
    },
  },
}
</script>

<style scoped>
.search_wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 0.2rem;
  padding: 0.25em 1em 0.25em;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  margin-bottom: 1em;
}
</style>

import * as lookupApi from "@/api/lookup"
import i18n from "@/plugins/i18n"
import { mapQueryFieldName, } from "@/constants/mapVarNames"
import { DEFAULT_PAGE_SIZE, } from "@/constants/sizes"
import {
  MENU,
  CATEGORY,
} from "@/constants/mapVarNames"

const commonLookupState = {
  searchInput: "",
  filterItems: {},
  pagination: {},
  dataList: [],
  sort: null,
  pageSize: DEFAULT_PAGE_SIZE,
}

export const users = {
  unsub: { ...commonLookupState, },
  staff: { ...commonLookupState, },
  person: { ...commonLookupState, },
  company: { ...commonLookupState, },
  approve: { ...commonLookupState, },
}

export const games = {
  game: { ...commonLookupState, },
}

export const gamesets = {
  currentItem: null,
  gameset: { ...commonLookupState, },
  division: { ...commonLookupState, },
  data: {
    ...commonLookupState,
    hideOutlier: false,
  },
  gamedata: {
    ...commonLookupState,
    currentIndex: null,
    currentGame: null,
    hideOutlier: false,
  },
  rawdata: {},
}

export const surveys = {
  survey: { ...commonLookupState, },
  data: { ...commonLookupState, },
}

export const authCodes = {
  authCode: { ...commonLookupState, },
}

export const state = () => ({
  users: { ...users, },
  games: { ...games, },
  gamesets: { ...gamesets, },
  surveys: { ...surveys, },
  authCodes: { ...authCodes, },
  lookupCategory: null,
  lookupMenu: null,
})

export const mutations = {
  INITIALIZE_FILTER_ITEMS(state, payload) {
    const menu = state.lookupMenu
    const category = state.lookupCategory

    state[menu][category].filterItems = payload
  },
  SET_LOOKUP_CATEGORY(state, payload) {
    const { menu, category, } = payload

    state.lookupMenu = menu
    state.lookupCategory = category
  },
  SET_CURRENT_DATA_ITEM(state, payload) {
    const { menu, item, } = payload

    state[menu].currentItem = item
  },
  SET_LOOKUP_DATA(state, payload) {
    const { menu, category, dataList, } = payload

    state[menu][category].dataList = dataList
  },
  SET_PAGINATION(state, payload) {
    const { menu, category, pagination, } = payload

    state[menu][category].pagination = { ...pagination, }
  },
  UPDATE_SEARCH_INPUT(state, payload) {
    const menu = state.lookupMenu
    const category = state.lookupCategory

    state[menu][category].searchInput = payload
  },
  SET_FILTER_ITEMS(state, payload) {
    const menu = state.lookupMenu
    const category = state.lookupCategory

    state[menu][category].filterItems = { ...payload, }
  },
  SET_FILTER_OPTIONS(state, payload) {
    const { item, value, } = payload
    const menu = state.lookupMenu
    const category = state.lookupCategory

    state[menu][category].filterItems[item] = value
  },
  SET_SORT(state, payload) {
    const menu = state.lookupMenu
    const category = state.lookupCategory

    state[menu][category].sort = payload
  },
  SET_PAGE_SIZE(state, payload) {
    const menu = state.lookupMenu
    const category = state.lookupCategory

    state[menu][category].pageSize = payload
  },
  SET_CURRENT_GAMEDATA_INDEX(state, payload) {
    state.gamesets.gamedata.currentIndex = payload
  },
  SET_CURRENT_GAME(state, payload) {
    state.gamesets.gamedata.currentGame = payload
  },
  SET_HIDE_OUTLIER(state, payload) {
    const menu = state.lookupMenu
    const category = state.lookupCategory

    state[menu][category].hideOutlier = payload
  },
}

export const actions = {
  async fetchLookupData({ commit, dispatch, state, }) {
    const menu = state.lookupMenu
    const category = state.lookupCategory
    const filterItems = { ...state[menu][category].filterItems, }
    const { searchInput, pageSize, sort, } = state[menu][category]
    const params = {
      sort,
      q: searchInput || null,
      page_size: pageSize,
    }

    let response

    for (const prop in filterItems) {
      if (filterItems[prop] !== i18n.t("options.NOT_SELECTED")) {
        const key = mapQueryFieldName[prop] ? mapQueryFieldName[prop] : prop

        params[key] = filterItems[prop]
      }
    }

    try {
      switch (menu) {
        case MENU.games: {
          response = await lookupApi.lookupGames({ params, })
          break
        }
        case MENU.gamesets: {
          if (category === CATEGORY.gameset) {
            response = await lookupApi.lookupGamesets({ params, })
          } else {
            const id = state.gamesets.currentItem.id

            params.hide_outlier = state.gamesets[category].hideOutlier
            params.key = state.gamesets[category].currentGame

            response = await lookupApi.lookupGamesetByCategory({ id, category, params, })
          }

          break
        }
        case MENU.users: {
          if (category !== CATEGORY.approve) {
            response = await lookupApi.lookupUsersByCategory({ category, params, })
          } else {
            response = await lookupApi.lookupUsersByCategory({ category: "company/approve", params, })
          }

          break
        }
        case MENU.surveys: {
          if (category === CATEGORY.survey) {
            response = await lookupApi.lookupSurveys({ params, })
          } else {
            const id = state.surveys.currentItem.id

            response = await lookupApi.lookupSurveyByCategory({ id, category, params, })
            commit("SET_CURRENT_DATA_ITEM", { menu: MENU.surveys, item: response.data.survey, })
          }

          break
        }
        case MENU.authCodes: {
          if (category === CATEGORY.authCode) {
            response = await lookupApi.lookupAuthCodes({ params, })
          }

          break
        }
      }

      const { data, pagination, } = response.data

      commit("SET_LOOKUP_DATA", { menu, category, dataList: data, })
      commit("SET_PAGINATION", { menu, category, pagination, })
    } catch (error) {
      dispatch("setError", error, { root: true, })
    }
  },
  setFilterItems({ commit, }, payload) {
    commit("SET_FILTER_ITEMS", payload)
  },
  resetFilterItems({ commit, state, }) {
    const menu = state.lookupMenu
    const category = state.lookupCategory
    const currentFilterItems = state[menu][category].filterItems
    const updatedFilterItems = {}

    for (const key in currentFilterItems) {
      updatedFilterItems[key] = null
    }

    commit("INITIALIZE_FILTER_ITEMS", updatedFilterItems)
  },
}

<template>
  <h1 class="mt-4 mb-4">
    {{ `${value} ${suffix}` }}
  </h1>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    suffix: {
      type: String,
      default: "",
    },
  },
}
</script>

<template>
  <div class="wrapper">
    <PageTitle :value="title" />
    <Search :label="$t('lookupPlaceholder.GAMESET')" />
    <v-app>
      <div class="filter_wrapper">
        <div class="filter">
          <FilterList />
        </div>
        <CreateButton @click="handleRedirect('createGameset')" />
      </div>
      <v-data-table
        class="elevation-1"
        :headers="headers"
        :items="dataList"
        :hide-default-footer="true"
        :server-items-length="dataList.length"
        @click:row="handleClickRow"
      >
        <template #[`item.data`]="{ item }">
          <v-btn
            class="blue-grey lighten-4"
            @click="(event) => handleToDataPage(event, item)"
          >
            <v-icon small>
              mdi-database-search-outline
            </v-icon>
          </v-btn>
        </template>
        <template #[`item.for_study`]="{ item }">
          <TableIcon :value="item.for_study" />
        </template>
        <template #[`item.for_mobile`]="{ item }">
          <TableIcon :value="item.for_mobile" />
        </template>
        <template #[`item.show_selfscore`]="{ item }">
          <TableIcon :value="item.show_selfscore" />
        </template>
        <template #[`item.is_started`]="{ item }">
          <TableIcon :value="item.is_started" />
        </template>
        <template #[`item.is_completed`]="{ item }">
          <TableIcon :value="item.is_completed" />
        </template>
        <template #[`item.is_deleted`]="{ item }">
          <TableIcon :value="item.is_deleted" />
        </template>
      </v-data-table>
      <Pagination />
    </v-app>
  </div>
</template>

<script>
import CreateButton from "@/components/common/buttons/CreateButton"
import TableIcon from "@/components/lookup/TableIcon"
import table from "@/mixins/table"
import { MENU, CATEGORY, } from "@/constants/mapVarNames"

export default {
  components: {
    CreateButton,
    TableIcon,
  },
  mixins: [table],
  data() {
    return {
      title: this.$t("nav.GAME_SET"),
      filterItems: {
        status: null,
        for_study: null,
        for_mobile: null,
      },
      headers: [
        {
          text: this.$t("headers.gameset.DATA"),
          value: "data",
          sortable: false,
        },
        {
          text: this.$t("headers.gameset.ID"),
          value: "id",
          sortable: false,
        },
        {
          text: this.$t("headers.gameset.COMPANY_NAME"),
          value: "user.company_name",
          sortable: false,
        },
        {
          text: this.$t("headers.gameset.USER"),
          value: "user.username",
          sortable: false,
        },
        {
          text: this.$t("headers.gameset.TEMPLATE"),
          value: "template.id",
          sortable: false,
        },
        {
          text: this.$t("headers.gameset.NAME"),
          value: "name",
          sortable: false,
        },
        {
          text: this.$t("headers.gameset.ENTRY"),
          value: "entry",
          sortable: false,
        },
        {
          text: this.$t("headers.gameset.MAX_ENTRY"),
          value: "max_entry",
          sortable: false,
        },
        {
          text: this.$t("headers.gameset.STATUS"),
          value: "status",
          sortable: false,
        },
        {
          text: this.$t("headers.gameset.VALID_FROM"),
          value: "valid_from",
          sortable: false,
        },
        {
          text: this.$t("headers.gameset.VALID_UNTIL"),
          value: "valid_until",
          sortable: false,
        },
        {
          text: this.$t("headers.gameset.FOR_STUDY"),
          value: "for_study",
          sortable: false,
        },
        {
          text: this.$t("headers.gameset.FOR_MOBILE"),
          value: "for_mobile",
          sortable: false,
        },
        {
          text: this.$t("headers.gameset.SHOW_SELFSCORE"),
          value: "show_selfscore",
          sortable: false,
        },
        {
          text: this.$t("headers.gameset.IS_STARTED"),
          value: "is_started",
          sortable: false,
        },
        {
          text: this.$t("headers.gameset.IS_COMPLETED"),
          value: "is_completed",
          sortable: false,
        },
        {
          text: this.$t("headers.gameset.IS_DELETED"),
          value: "is_deleted",
          sortable: false,
        }
      ],
    }
  },
  created() {
    this.$store.commit("SET_LOOKUP_CATEGORY", { menu: MENU.gamesets, category: CATEGORY.gameset, })
    this.$store.dispatch("setFilterItems", this.filterItems)
    this.$store.dispatch("fetchLookupData")
  },
  methods: {
    handleRedirect(routeName) {
      this.$router.push({ name: routeName, })
    },
    handleToDataPage(event, item) {
      event.stopPropagation()

      const id = item.id
      const menu = this.lookupMenu

      this.$store.commit("SET_CURRENT_DATA_ITEM", { menu, item, })
      this.$router.push({ name: "gamesetData", params: { id, }, })
    },
    handleClickRow(rowData) {
      const dataId = rowData.id

      this.$router.push({ name: "gamesetDetail", params: { id: dataId, }, })
    },
  },
}
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.filter_wrapper {
  display: flex;
  justify-content: space-between;
}
</style>

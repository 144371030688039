<template>
  <div>
    <div
      v-for="(item, name) in value"
      :key="name"
      class="list"
    >
      <v-text-field
        :id="name"
        :label="name"
        :value="value[name]"
        @input="(updatedItem) => handleUpdateValue(name, updatedItem)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  methods: {
    handleUpdateValue(name, item) {
      const updatedValue = { ...this.value, }

      updatedValue[name] = item
      this.$emit("input", updatedValue)
    },
  },
}
</script>

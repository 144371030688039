import Vue from "vue"
import Vuex from "vuex"
import createPersistedState from "vuex-persistedstate"

import * as admin from "./modules/admin"
import * as lookup from "./modules/lookup"
import * as error from "./modules/error"
import template from "./modules/template"
import criteria from "./modules/criteria"
import adminCommon from "./modules/adminCommon"
import pagination from "./modules/pagination"
import form from "./modules/form"

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: { admin, lookup, template, criteria, adminCommon, error, pagination, form, },
  plugins: [createPersistedState({
    paths: ["admin", "lookup"],
  })],
})

export default store

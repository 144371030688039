<template>
  <div class="nav_wrapper">
    <div class="logo_wrapper">
      <h2 :class="[isNavClosed && 'closed_logo_name', 'logo_name']">
        {{ $t("common.INNATE") }}
      </h2>
      <button
        class="nav_open_btn"
        @click="toggleNav"
      >
        <v-icon color="#fff">
          mdi-currency-eth
        </v-icon>
      </button>
    </div>
    <ul class="menu_list_wrapper">
      <Arcodian
        :icon-color="iconColor"
        :close-status="closeState"
        @open="toggleNav"
      />
      <li
        v-for="(item,index) in menuList"
        :key="index"
        :class="[isNavClosed && 'closed_nav_menu_item_wrapper', 'nav_menu_item_wrapper']"
        @click="moveTo(index)"
      >
        <v-icon
          :color="iconColor"
          large
        >
          {{ `mdi-${icons[index]}` }}
        </v-icon>
        <span :class="isNavClosed ? 'closed_nav_menu_item' : 'nav_menu_item'">
          {{ item }}
        </span>
      </li>
    </ul>
    <div class="nav_footer_wrapper">
      <v-icon
        color="#ffffff"
        @click="logout"
      >
        mdi-logout
      </v-icon>
    </div>
  </div>
</template>

<script>
import Arcodian from "./Arcodian.vue"

export default {
  components: { Arcodian, },
  data() {
    return {
      routerName: [
        "games",
        "Form",
        "Template",
        "gamesets",
        "Criteria",
        "surveys",
        "authCode",
        "Funnel",
        "EmailService",
        "FakeName"
      ],
      menuList: [
        this.$t("nav.GAME"),
        this.$t("nav.FORM"),
        this.$t("nav.TEMPLATE"),
        this.$t("nav.GAME_SET"),
        this.$t("nav.CRITERIA"),
        this.$t("nav.SURVEY"),
        this.$t("nav.AUTH_CODE"),
        this.$t("nav.FUNNEL"),
        this.$t("nav.EMAIL_SERVICE"),
        this.$t("nav.FAKE_NAME")
      ],
      icons: [
        "gamepad-variant-outline",
        "form-select",
        "file-cabinet",
        "file-table",
        "book-cog",
        "text-box-multiple-outline",
        "numeric",
        "openid",
        "email-edit",
        "format-text"
      ],
      closeState: false,
      iconColor: "#212121",
      isNavClosed: false,
    }
  },
  methods: {
    toggleNav() {
      this.closeState = !this.closeState
      this.isNavClosed = !this.isNavClosed
      this.$emit("toggleNav")
    },
    moveTo(index) {
      if (this.$route.name !== this.routerName[index]) {
        this.$router.push({ name: this.routerName[index], })
      }
    },
    logout() {
      this.$store.dispatch("requestLogout")
    },
  },
}
</script>

<style>
.nav_wrapper  {
  width: 100%;
  height: 100%;
  background-color: #222;
  padding: 0 1rem;
  overflow: hidden;
  position: relative;
}

.logo_wrapper {
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.logo_name {
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}

.closed_logo_name {
  pointer-events: none;
  opacity: 0;
}

.nav_menu_item {
  color: #212121;
  font-weight: 600;
  opacity: 1;
  text-decoration: none;
}

.nav_menu_item_wrapper {
  width:100%;
  height: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center ;
  margin-bottom: 0.5rem;
  padding: 0 1rem;
  overflow: hidden;
  border-radius: 0.3rem;
  background-color: #fff;
  transition: all .2s ease;
  list-style: none;
  text-decoration: none;
  cursor: pointer;
}

.closed_nav_menu_item {
  opacity: 0;
  width: 0;
  pointer-events: none;
}

.closed_nav_menu_item_wrapper {
  padding: 0;
  justify-content: center;
}

.nav_footer_wrapper {
  width: 100%;
  height: 3rem;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  font-weight: 600;
  justify-content: flex-end;
}

.menu_list_wrapper {
  padding: 0 !important;
}
</style>

<template>
  <div class="wrapper">
    <div
      class="signup_wrapper"
      @keyup.enter="handleSubmit"
    >
      <h1 :class="[`text-h4`, 'font-weight-bold']">
        {{ $t("accounts.SIGNUP") }}
      </h1>
      <v-text-field
        id="username"
        v-model="username"
        :label="$t('accounts.USERNAME')"
        :placeholder="$t('accounts.USERNAME_PLACEHOLDER')"
      />
      <v-text-field
        id="password"
        v-model="password"
        type="password"
        :label="$t('accounts.PASSWORD')"
        :placeholder="$t('accounts.PASSWORD_PLACEHOLDER')"
      />
      <v-text-field
        id="confirm_password"
        v-model="confirmPassword"
        type="password"
        :label="$t('accounts.CONFIRM_PASSWORD')"
        :placeholder="$t('accounts.CONFIRM_PASSWORD_PLACEHOLDER')"
      />
      <v-text-field
        id="name"
        v-model="name"
        :label="$t('accounts.NAME')"
        :placeholder="$t('accounts.NAME_PLACEHOLDER')"
      />
      <v-text-field
        id="signup_key"
        v-model="signup_key"
        :label="$t('accounts.SIGNUP_KEY')"
        :placeholder="$t('accounts.SIGNUP_KEY_PLACEHOLDER')"
      />
      <span class="red--text text-accent-2">
        {{ $t("accounts.SIGNUP_KEY_SNIPPET") }}
      </span>
      <div class="btn_container">
        <v-btn
          color="primary"
          @click="handleSubmit"
        >
          {{ $t("accounts.SIGNUP") }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import * as accountsApi from "../../api/accounts"

export default {
  data() {
    return {
      username: "",
      password: "",
      confirmPassword: "",
      name: "",
      signup_key: "",
    }
  },
  methods: {
    async handleSubmit() {
      const personData = {
        username: this.username,
        password: this.password,
        name: this.name,
        signup_key: this.signup_key,
      }

      try {
        await accountsApi.requestSignup(personData)
        this.$router.push({ name: "login", })
      } catch (error) {
        this.$store.dispatch("setError", error)
      }
    },
  },
}
</script>

<style scoped>
.wrapper {
  display: flex;
  justify-content: center;
  margin-top: 180px;
}

.signup_wrapper {
  width: 80%;
  max-width: 464px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.btn_container {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>

import { getGameKeyFromPercentile, } from "@/utils/format"

export default {
  computed: {
    currentSortType() {
      return this.$store.state.lookup[this.lookupMenu][this.lookupCategory].sort
    },
  },
  methods: {
    $_sort_handleSort(value) {
      let sortType

      if (value === "id") {
        sortType = this.currentSortType === "earliest" ? "latest" : "earliest"
      } else if (value === "username") {
        sortType = this.currentSortType === "username_asc" ? "username_dsc" : "username_asc"
      } else if (value === "birthday") {
        sortType = this.currentSortType === "birthday_asc" ? "birthday_dsc" : "birthday_asc"
      } else if (value.includes("percentile")) {
        const gameKey = getGameKeyFromPercentile(value)

        sortType = this.currentSortType === `${gameKey}_asc` ? `${gameKey}_dsc` : `${gameKey}_asc`
      }

      this.$store.commit("SET_SORT", sortType)
      this.$store.dispatch("fetchLookupData")
    },
  },
}

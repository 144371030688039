import http from "./http"

export function fetchData(url, params) {
  return http.get(`things/${url}/`, { params, })
}

export function editData(url, itemId, data) {
  return http.patch(`things/${url}/${itemId}/`, data)
}

export function deleteData(url, itemId) {
  return http.delete(`things/${url}/${itemId}/`)
}

export function createData(url, data) {
  return http.post(`things/${url}/`, data)
}

<template>
  <div class="wrapper">
    <div class="header_wrapper">
      <h1 class="title_wrapper">
        {{ $t("surveys.SURVEY_DATA") }}
      </h1>
    </div>
    <div class="card_wrapper">
      <GamesetCard :card-data="cardData" />
    </div>
    <div class="search_wrapper">
      <Search :label="$t('lookupPlaceholder.DEFAULT')" />
    </div>
    <v-app>
      <v-data-table
        class="elevation-1"
        :headers="[...headers, ...surveyHeaders]"
        :items="dataList"
        :hide-default-footer="true"
        :server-items-length="dataList.length"
      />
      <Pagination />
      <Excel @click="fetchExcelFile" />
    </v-app>
  </div>
</template>

<script>
import GamesetCard from "@/components/gameset/GamesetCard"
import { MENU, CATEGORY, } from "@/constants/mapVarNames"
import table from "@/mixins/table"
import excel from "@/mixins/excel"
import param from "@/mixins/param"
import { getCurrentDate, } from "@/utils/date"
import { getExcelDataBySurveyId, } from "@/api/exportExcel"

export default {
  components: {
    GamesetCard,
  },
  mixins: [table, excel, param],
  data() {
    const basePropsHeader = [
      {
        class: "table_header_xs",
        text: this.$t("headers.ID"),
        value: "id",
        sortable: false,
      },
      {
        class: "table_header_xs",
        text: "username",
        value: "user.username",
        sortable: false,
      }
    ]

    return {
      cardData: {},
      surveyHeaders: [],
      headers: [...basePropsHeader],
      surveyId: this.$route.params.id,
    }
  },
  computed: {
    survey() {
      return this.$store.state.lookup.surveys.currentItem
    },
  },
  async created() {
    this.$store.commit("SET_LOOKUP_CATEGORY", { menu: MENU.surveys, category: CATEGORY.data, })
    await this.$store.dispatch("fetchLookupData")
    this.handleSetSurveyProps()
    this.handleSetCardData()
  },
  methods: {
    handleSetCardData() {
      this.cardData = {
        survey: `${this.survey.id} - ${this.survey.title}`,
        description: this.survey.description,
      }
    },
    handleSetSurveyProps() {
      const surveyQuestions = this.survey.questions
      const surveyPropsHeader = surveyQuestions.map((item, index) => {
        return {
          class: "table_header_lg",
          text: item,
          value: `answers[${index}]`,
          sortable: false,
        }
      })

      this.surveyHeaders = [...surveyPropsHeader]
    },
    async fetchExcelFile() {
      try {
        const params = this.$_param_createParams()
        const response = await getExcelDataBySurveyId({
          surveyId: this.survey.id,
          category: CATEGORY.data,
          params,
        })

        this.$_excel_downloadFile(response, `survey_${CATEGORY.data}_${getCurrentDate()}`)
      } catch (error) {
        this.$store.dispatch("setError", error)
      }
    },
  },
}
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header_wrapper {
  display: flex;
  align-items: center;
}

.card_wrapper {
  align-self: flex-start;
  margin-bottom: 1em;
}

.title_wrapper {
  margin: 1rem 0;
}

.search_wrapper {
  width: 720px;
}
</style>

import { render, staticRenderFns } from "./GameDetail.vue?vue&type=template&id=13bb5e7a&scoped=true&"
import script from "./GameDetail.vue?vue&type=script&lang=js&"
export * from "./GameDetail.vue?vue&type=script&lang=js&"
import style0 from "./GameDetail.vue?vue&type=style&index=0&id=13bb5e7a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13bb5e7a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
installComponents(component, {VBtn,VCard,VFileInput})

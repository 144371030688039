<template>
  <div class="wrapper">
    <PageTitle :value="title" />
    <Search :label="$t('lookupPlaceholder.SURVEY')" />
    <v-app>
      <div class="filter_wrapper">
        <div class="filter">
          <FilterList />
        </div>
        <CreateButton @click="handleRedirect('createSurvey')" />
      </div>
      <v-data-table
        class="elevation-1"
        :headers="headers"
        :items="dataList"
        :hide-default-footer="true"
        :server-items-length="dataList.length"
        @click:row="handleClickRow"
      >
        <template #[`item.data`]="{ item }">
          <v-btn
            class="blue-grey lighten-4"
            @click="(event) => handleToDataPage(event, item)"
          >
            <v-icon small>
              mdi-database-search-outline
            </v-icon>
          </v-btn>
        </template>
        <template #[`item.is_active`]="{ item }">
          <TableIcon :value="item.is_active" />
        </template>
        <template #[`item.created_at`]="{ item }">
          <span>
            {{ formatISOString(item.created_at) }}
          </span>
        </template>
      </v-data-table>
      <Pagination />
    </v-app>
  </div>
</template>

<script>
import CreateButton from "@/components/common/buttons/CreateButton"
import TableIcon from "@/components/lookup/TableIcon"
import table from "@/mixins/table"
import { MENU, CATEGORY, } from "@/constants/mapVarNames"
import { formatISOString, } from "@/utils/format"

export default {
  components: {
    CreateButton,
    TableIcon,
  },
  mixins: [table],
  data() {
    return {
      title: this.$t("nav.SURVEY"),
      filterItems: {
        is_active: null,
      },
      headers: [
        {
          text: this.$t("headers.gameset.DATA"),
          value: "data",
          sortable: false,
        },
        {
          text: this.$t("headers.ID"),
          value: "id",
        },
        {
          text: this.$t("headers.TITLE"),
          value: "title",
        },
        {
          text: this.$t("headers.ENTRY"),
          value: "entry",
        },
        {
          text: this.$t("headers.survey.IS_ACTIVE"),
          value: "is_active",
          sortable: false,
        },
        {
          text: this.$t("headers.survey.CREATED_AT"),
          value: "created_at",
          sortable: false,
        }
      ],
    }
  },
  created() {
    this.$store.commit("SET_LOOKUP_CATEGORY", { menu: MENU.surveys, category: CATEGORY.survey, })
    this.$store.dispatch("setFilterItems", this.filterItems)
    this.$store.dispatch("fetchLookupData")
  },
  methods: {
    formatISOString,
    handleRedirect(routeName) {
      this.$router.push({ name: routeName, })
    },
    handleToDataPage(event, item) {
      event.stopPropagation()

      const id = item.id
      const menu = this.lookupMenu

      this.$store.commit("SET_CURRENT_DATA_ITEM", { menu, item, })
      this.$router.push({ name: "surveyData", params: { id, }, })
    },
    handleClickRow(rowData) {
      const dataId = rowData.id

      this.$router.push({ name: "surveyDetail", params: { id: dataId, }, })
    },
  },
}
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.filter_wrapper {
  display: flex;
  justify-content: space-between;
}
</style>

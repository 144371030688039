<template>
  <div class="d-flex flex-column">
    <PageTitle :value="title" />
    <Input
      v-for="(value, name) in inputData"
      :key="name"
      v-model="gameData[name]"
      :label="name"
      :is-disabled="inputData[name].isDisabled"
    />
    <v-btn
      max-width="127"
      color="primary"
      depressed
      @click="handleAddScheme"
    >
      {{ $t("games.ADD_SCHEME") }}
    </v-btn>
    <v-card
      v-if="schemeList.length"
      class="mt-6 mb-6"
    >
      <DynamicTextFields
        :items="schemeList"
        @update-key="handleUpdateKeyByIndex"
        @update-value="handleUpdateValueByIndex"
        @remove="(index) => handleRemoveScheme(index)"
      />
    </v-card>
    <v-btn
      class="align-self-center"
      type="submit"
      max-width="64"
      @click="handleCreate"
    >
      {{ $t("detail.SAVE") }}
    </v-btn>
  </div>
</template>

<script>
import PageTitle from "@/components/common/headers/PageTitle"
import DynamicTextFields from "@/components/common/formControls/DynamicTextFields"
import Input from "@/components/common/formControls/Input"
import * as gameApi from "@/api/lookup"

export default {
  components: {
    PageTitle,
    DynamicTextFields,
    Input,
  },
  data() {
    return {
      title: this.$t("games.CREATE_TITLE"),
      gameData: {
        key: "",
        name: "",
        description: "",
        scheme: {},
      },
      schemeList: [],
      inputData: {
        key: {
          type: "text",
        },
        name: {
          type: "text",
        },
        description: {
          type: "text",
        },
      },
    }
  },
  methods: {
    async handleCreate() {
      const schemeList = this.schemeList
      const schemeListSize = schemeList.length

      for (let i = 0; i < schemeListSize; i++) {
        const key = schemeList[i].key
        const value = schemeList[i].value

        if (key) {
          this.gameData.scheme[key] = value
        }
      }

      try {
        await gameApi.createGame(this.gameData)
        this.$router.push({ name: "games", })
      } catch (error) {
        this.$store.dispatch("setError", error, { root: true, })
      }
    },
    handleAddScheme() {
      const scheme = { key: "", value: null, }

      this.schemeList.push(scheme)
    },
    handleRemoveScheme(targetIndex) {
      this.schemeList = this.schemeList.filter((item, index) => index !== targetIndex)
    },
    handleUpdateKeyByIndex(index, updatedKey) {
      this.schemeList[index].key = updatedKey
    },
    handleUpdateValueByIndex(index, updatedValue) {
      this.schemeList[index].value = updatedValue
    },
  },
}
</script>

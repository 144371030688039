<template>
  <v-card>
    <v-card-title class="d-flex justify-space-between">
      <span>
        {{ $t("modal.CROPPER_TITLE") }}
      </span>
      <v-icon @click="handleClickClose">
        mdi-close
      </v-icon>
    </v-card-title>
    <div class="img_wrapper">
      <img
        id="img"
        :src="imageSrc"
        alt="img"
      >
    </div>
    <v-card-actions class="d-flex justify-center">
      <v-btn
        class="pa-6"
        @click="handleSave"
      >
        {{ $t("modal.CROP_THUMBNAIL_COMPLETE") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Cropper from "cropperjs"

export default {
  props: {
    imageSrc: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      imgDOM: new Image(),
    }
  },
  mounted() {
    this.imgDOM = document.getElementById("img")
    this.cropper = new Cropper(this.imgDOM, {
      viewMode: 1,
      aspectRatio: 1,
      minCropBoxWidth: 50,
      minCropBoxHeight: 50,
      background: false,
      cropBoxMovable: true,
      cropBoxResizable: true,
      modal: true,
      movable: false,
      zoomable: false,
    })
  },
  destroyed() {
    this.cropper.destroy()
  },
  methods: {
    handleClickClose() {
      this.$emit("close")
    },
    isUnderSizeLimit(imgSize) {
      const bytesInKB = 1024
      const bytesInMB = bytesInKB * 1024
      const limitMB = 2

      return imgSize < limitMB * bytesInMB
    },
    validateImageFileSize(blob) {
      if (this.isUnderSizeLimit(blob.size)) {
        this.$emit("save", blob)
      } else {
        this.$emit("close")
        this.$emit("reset")
        alert(this.$t("error.IMAGE_SIZE_ERROR"))
      }
    },
    handleSave() {
      this.cropper.getCroppedCanvas().toBlob(this.validateImageFileSize, "image/jpeg")
    },
  },
}
</script>

<template>
  <v-container>
    <v-row
      v-for="(value, index) in items"
      :key="index"
      no-gutters
      class="d-flex justify-space-between align-center pr-4 pl-3"
    >
      <v-col :cols="4">
        <v-card class="pa-2 elevation-0">
          <Input
            :value="items[index].key"
            label="key"
            @input="(value) => updateKeyByIndex(index, value)"
          />
        </v-card>
      </v-col>
      <v-col :cols="4">
        <v-card class="pa-2 elevation-0">
          <Input
            :value="items[index].value"
            label="value"
            @input="(value) => updateValueByIndex(index, value)"
          />
        </v-card>
      </v-col>
      <v-col
        :cols="2"
        class="d-flex justify-center"
      >
        <v-btn
          depressed
          @click="handleClickDelete(index)"
        >
          {{ $t("common.DELETE") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Input from "@/components/common/formControls/Input"

export default {
  components: {
    Input,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    updateKeyByIndex(index, key) {
      this.$emit("update-key", index, key)
    },
    updateValueByIndex(index, value) {
      this.$emit("update-value", index, value)
    },
    handleClickDelete(index) {
      this.$emit("remove", index)
    },
  },
}
</script>

<template>
  <div class="wrapper">
    <div v-if="value === true">
      <v-icon
        color="green"
        small
      >
        mdi-check-circle
      </v-icon>
    </div>
    <div v-else-if="value === false">
      <v-icon
        color="red"
        small
      >
        mdi-close-circle
      </v-icon>
    </div>
    <div v-else>
      <div />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: null,
    },
  },
}
</script>

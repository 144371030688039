import {
  requestCriteriaData,
  requestCriteriaDetailData,
  createCriteria,
  deleteCriteria,
  editCriteria,
} from "../../api/criteria"
import bus from "../../utils/bus"

export default {
  namespaced: true,
  state: {
    criteriaData: [],
  },
  mutations: {
  },
  actions: {
    async fetchData({ dispatch, }, keywords = {}) {
      try {
        bus.$emit("start:loading")
        const response = await requestCriteriaData(keywords)

        if (response.status === 200) {
          return response.data
        }
      } catch (error) {
        dispatch("setError", error, { root: true, })
      }
    },
    async fetchCriteriaDetailData({ dispatch, }, criteriaId) {
      try {
        bus.$emit("start:loading")
        const response = await requestCriteriaDetailData(criteriaId)

        if (response.status === 200) {
          return response.data
        }
      } catch (error) {
        dispatch("setError", error, { root: true, })
      }
    },
    async editCriteriaData({ commit, }, payload) {
      try {
        bus.$emit("start:loading")
        const response = await editCriteria(payload.id, payload.data)

        if (response.status === 201) {
          bus.$emit("end:loading")

          return response
        }
      } catch (error) {
        return { data: error.response, status: error.response.status, }
      }
    },
    async createCriteriaData({ commit, }, data) {
      try {
        bus.$emit("start:loading")
        const response = await createCriteria(data)

        if (response.status === 201) {
          bus.$emit("end:loading")

          return response
        }
      } catch (error) {
        return { data: error.response, status: error.response.status, }
      }
    },
    async deleteCriteriaData({ dispatch, }, criteriaId) {
      try {
        bus.$emit("start:loading")
        const response = await deleteCriteria(criteriaId)

        if (response.status === 200) {
          return response.data
        }
      } catch (error) {
        dispatch("setError", error, { root: true, })
      }
    },
  },
}

<template>
  <div class="home_wrapper">
    this is home page
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>

import i18n from "../../plugins/i18n.js"
import { mapErrorMessage, } from "../../constants/mapVarNames"
import bus from "../../utils/bus"

export const state = () => ({
  error: {
    status: "",
    statusText: "",
    errorMessage: "",
  },
  isErrorExists: false,
})

export const mutations = {
  SET_ERROR(state, payload) {
    const { error, isErrorExists, } = payload

    if (isErrorExists) {
      state.error = { ...error, }
    }

    state.isErrorExists = isErrorExists
  },
}

export const actions = {
  setError({ commit, dispatch, }, payload) {
    bus.$emit("end:loading")

    const { status, statusText, data, } = payload.response
    let errorMessage = ""

    if (statusText === "Unauthorized") {
      errorMessage = data.detail
      dispatch("requestLogout", null, { root: true, })
    } else if (statusText === "Bad Request") {
      for (const key in data) {
        errorMessage += `${key}: ${data[key]}`
        errorMessage += "\n"
      }
    } else if (statusText === "Forbidden") {
      errorMessage = data.detail
    } else {
      errorMessage = i18n.t(mapErrorMessage.default)
    }

    commit("SET_ERROR", {
      error: {
        status,
        statusText,
        message: errorMessage,
      },
      isErrorExists: true,
    })
  },
}

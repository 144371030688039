<template>
  <div>
    <PageTitle :value="title" />
    <div>
      <div
        v-for="(value, name) in inputData"
        :key="name"
        class="li"
      >
        <Input
          v-if="inputData[name].type === 'text'"
          v-model="userData[name]"
          :label="name"
          :is-disabled="inputData[name].isDisabled"
        />
        <JsonTextarea
          v-else-if="inputData[name].type === 'textarea'"
          :id="name"
          v-model="userData[name]"
          :label="name"
        />
        <Checkbox
          v-else-if="inputData[name].type === 'checkbox'"
          v-model="userData[name]"
          :label="name"
        />
      </div>
    </div>
    <div class="footer">
      <div class="delete_btn_wrapper">
        <v-btn
          class="mr-12"
          color="error"
          @click="$_detail_handleOpen"
        >
          {{ $t("detail.DELETE") }}
        </v-btn>
      </div>
      <div class="save_btn_wrapper">
        <v-btn @click="handleSaveSurvey">
          {{ $t("detail.SAVE") }}
        </v-btn>
      </div>
    </div>
    <Modal
      v-if="isModalShown"
      :title="$t('modal.WARNING')"
      :desc="$t('modal.DELETE_SURVEY_MESSAGE')"
      @yes="$_detail_handleProceed($_detail_handleDelete)"
      @no="$_detail_handleClose"
    />
  </div>
</template>

<script>
import PageTitle from "@/components/common/headers/PageTitle"
import Input from "@/components/common/formControls/Input"
import JsonTextarea from "@/components/inputs/JsonTextarea"
import Checkbox from "@/components/common/formControls/Checkbox"
import Modal from "@/components/common/modals/Modal"
import detail from "@/mixins/detail"

export default {
  components: {
    PageTitle,
    Input,
    JsonTextarea,
    Checkbox,
    Modal,
  },
  mixins: [detail],
  data() {
    return {
      title: this.$t("nav.SURVEY"),
      userData: {
        id: "",
        title: "",
        description: "",
        contents: "",
        is_active: false,
      },
      inputData: {
        id: {
          type: "text",
          isDisabled: true,
        },
        title: {
          type: "text",
        },
        description: {
          type: "text",
        },
        contents: {
          type: "textarea",
        },
        is_active: {
          type: "checkbox",
        },
      },
    }
  },
  methods: {
    handleConvertContent(data) {
      const surveyData = { ...data, }
      const parsedContents = JSON.parse(this.userData.contents)

      surveyData.contents = parsedContents

      return surveyData
    },
    handleSaveSurvey() {
      this.$_detail_handleSave(this.handleConvertContent(this.userData))
    },
  },
}
</script>

<style scoped>
.footer {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;
}

.save_btn_wrapper {
  position: absolute;
  left: calc(50% - 32px);
}
</style>

<template>
  <div class="setForm_wrapper">
    <PageTitle
      :value="title"
      :suffix="$t('common.MANAGEMENT')"
    />
    <div class="setForm_elem_wrapper">
      <Input
        :value="`${formData.id}`"
        :is-disabled="true"
        label="id"
      />
      <Input
        v-model="formData.name"
        :is-disabled="false"
        label="name"
      />
      <Input
        v-model="formData.description"
        :is-disabled="false"
        label="description"
      />
      <Input
        :value="`${formData.tests}`"
        :is-disabled="true"
        label="tests"
      />
      <TextAreaForJSON
        :value="formData.sections"
        desc="section"
        @input="setSections"
        @checkJSONValidation="checkJSONValidation"
      />
      <Checkbox
        :value="formData.is_active"
        label="is_active"
        @input="setIsActive"
      />
      <Input
        v-if="!isCreateForm"
        :value="`${formData.created_at}`"
        :is-disabled="true"
        label="create_at"
      />
      <div class="button_wrapper">
        <DeleteButton
          @click="deleteForm"
        />
        <div class="save_btn_wrapper">
          <SaveButton
            @click="checkRequestDataValidation"
          />
        </div>
      </div>
      <Modal
        v-if="deleteStatus"
        :title="$t('common.DELETE_WARNING1')"
        :desc="$t('common.DELETE_WARNING2')"
        @yes="removeSelectedItem"
        @no="cancel"
      />
      <Notice
        v-if="saveStatus"
        :desc="$t('common.SAVE_COMPLETE')"
      />
    </div>
  </div>
</template>

<script>
import SaveButton from "@/components/common/buttons/SaveButton.vue"
import DeleteButton from "@/components/common/buttons/DeleteButton.vue"
import TextAreaForJSON from "@/components/common/TextAreaForJSON.vue"
import PageTitle from "@/components/common/headers/PageTitle.vue"
import Modal from "@/components/common/modals/Modal.vue"
import Notice from "@/components/common/modals/Notice.vue"
import Checkbox from "@/components/common/formControls/Checkbox.vue"
import Input from "@/components/common/formControls/Input.vue"
import bus from "@/utils/bus"

export default {
  components: {
    SaveButton,
    DeleteButton,
    TextAreaForJSON,
    PageTitle,
    Checkbox,
    Modal,
    Notice,
    Input,
  },
  data() {
    return {
      title: this.$t("title.FORM"),
      formId: null,
      formData: {
        id: "",
        name: "",
        description: "",
        is_active: false,
        tests: [],
        sections: [{ "test": "", "choices": [], }],
      },
      jsonValidateStatus: true,
      deleteStatus: false,
      saveStatus: false,
      stringifiedJSON: "",
    }
  },
  computed: {
    isCreateForm() {
      return this.formId === "new"
    },
  },
  beforeMount() {
    this.formId = this.$route.params.id

    if (!this.isCreateForm) {
      this.fetchFormDetailData()

      return
    }
  },
  methods: {
    async fetchFormDetailData() {
      const response = await this.$store.dispatch("form/fetchFormDetailData", this.formId)

      if (response.status === 200) {
        this.setFormData(response)
        bus.$emit("end:loading")
      }
    },
    setFormData(response) {
      this.formData = {
        ...this.formData,
        ...JSON.parse(JSON.stringify(response.data.data)),
      }

      this.setJsonParse(this.formData.sections)
    },
    setJsonParse(jsonDate) {
      this.stringifiedJSON = JSON.stringify(jsonDate, null, 4)
    },
    setIsActive(state) {
      this.formData.is_active = state
    },
    deleteForm() {
      if (this.isCreateForm) {
        return
      }

      this.upToTop()
      this.deleteStatus = true
    },
    upToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      })
    },
    setSections(json) {
      this.formData.sections = json
    },
    cancel() {
      this.deleteStatus = false
    },
    async removeSelectedItem() {
      const response = await this.$store.dispatch("form/deleteForm", this.formId)

      if (response.error) {
        this.cancel()
        alert(this.$i18n.t("template.DELETE_ERROR_MESSAGE"))

        return
      }

      this.moveToForm()
    },
    checkRequestDataValidation() {
      const data = {
        name: this.formData.name,
        description: this.formData.description,
        is_active: this.formData.is_active,
        sections: this.formData.sections,
      }

      if (!data.name.trim()) {
        alert(this.$i18n.t("form.NAME_ERROR"))

        return
      }

      if (!this.jsonValidateStatus) {
        alert(this.$i18n.t("form.JSON_VALIDATE_ERROR"))

        return
      }

      this.upToTop()
      this.saveForm(data)
    },
    checkJSONValidation(status) {
      this.jsonValidateStatus = status
    },
    async saveForm(data) {
      const url = this.isCreateForm ? "form/saveForm" : "form/patchFormData"
      const requestData = {
        formId: this.formId,
        data,
      }

      const response = await this.$store.dispatch(url, requestData)

      if (response.status === 200 || response.status === 201) {
        this.saveStatus = true
        this.moveToForm()
      }
    },
    moveToForm() {
      setTimeout(() => {
        this.$router.push({ name: "Form", })
      }, 700)
    },
  },
}
</script>

<style scoped>
.button_wrapper {
  position: relative;
  width: 100%;
  margin-top: 1rem;
  display: flex;
  align-items: center;
}

.check_form_component {
  margin-bottom: .5rem;
}

.save_btn_wrapper {
  position: absolute;
  left: calc(50% - 32px);
}

.save_modal_component {
  z-index: 999;
}
</style>

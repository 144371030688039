<template>
  <div class="wrapper">
    <h1 class="title_wrapper">
      {{ `${gameTitle} ${$t("gamesets.DATA")}` }}
    </h1>
    <div class="card_wrapper">
      <GamesetCard :card-data="cardData" />
    </div>
    <div class="table_wrapper">
      <v-data-table
        class="elevation-1"
        :headers="trials"
        :items="trialData"
        :hide-default-footer="true"
      />
    </div>
    <Excel @click="fetchExcelFile" />
  </div>
</template>

<script>
import GamesetCard from "@/components/gameset/GamesetCard"
import Excel from "@/components/lookup/Excel"
import excel from "@/mixins/excel"
import param from "@/mixins/param"
import { lookupRawDataByGamedataId, } from "@/api/lookup"
import { getRawDataByGamesetId, } from "@/api/exportExcel"
import { MENU, CATEGORY, } from "@/constants/mapVarNames"
import { gameKeys, } from "@/constants/gameProps"

export default {
  components: {
    GamesetCard,
    Excel,
  },
  mixins: [excel, param],
  data() {
    return {
      trials: [],
      trialData: [],
      trialHeaders: null,
      gameTitle: null,
      gameKey: this.$route.params.key,
    }
  },
  computed: {
    gameset() {
      return this.$store.state.lookup.gamesets.currentItem
    },
    currentIndex() {
      return this.$store.state.lookup.gamesets.gamedata.currentIndex
    },
    currentGamedata() {
      return this.$store.state.lookup.gamesets.gamedata.dataList[this.currentIndex]
    },
    cardData() {
      const username = this.currentGamedata.user.username
      const company_name = this.gameset.user ? this.gameset.user.company_name : ""
      const gameset = `${this.gameset.id} - ${this.gameset.name}`

      return {
        company_name: company_name ? company_name : "",
        user: username ? username : "",
        gameset: gameset && gameset,
      }
    },
  },
  async created() {
    this.$store.commit("SET_LOOKUP_CATEGORY", { menu: MENU.gamesets, category: CATEGORY.rawdata, })
    await this.fetchTableData()
    this.handleSetGameTitle()
  },
  methods: {
    handleSetTrialHeaders(data) {
      const trialHeaders = []

      for (const key in data) {
        if (Array.isArray(data[key])) {
          trialHeaders.push(key)
        }
      }

      this.trialHeaders = trialHeaders
    },
    handleSetGameTitle() {
      const games = this.gameset.template.games

      for (let i = 0; i < games.length; i++) {
        if (games[i].key === this.gameKey) {
          this.gameTitle = games[i].name

          return
        }
      }
    },
    async fetchTableData() {
      const id = this.currentGamedata.summary.id
      const key = this.$route.params.key
      let props = [{ text: "trial", value: "0", sortable: false, }]
      let data = []

      try {
        const response = await lookupRawDataByGamedataId(id, key)
        let trialLength = 0

        if (key === gameKeys.ALPHABET) {
          trialLength = response.data.data.alphabets.length
        } else {
          for (const key in response.data.data) {
            if (Array.isArray(response.data.data[key])) {
              trialLength = response.data.data[key].length

              break
            }
          }
        }

        this.handleSetTrialHeaders(response.data.data)

        for (let i = 1; i <= trialLength; i++) {
          const index = JSON.stringify(i)

          props.push({
            text: index,
            value: index,
            sortable: false,
          })
        }

        for (let i = 0; i < this.trialHeaders.length; i++) {
          const prop = this.trialHeaders[i]

          if (key === gameKeys.ALPHABET && prop === "answers") {
            let blankLength

            if (key === gameKeys.ALPHABET) {
              const alphbetsLength = response.data.data.alphabets.length
              const answersLength = response.data.data.answers.length

              blankLength = alphbetsLength - answersLength
            }

            const blanks = new Array(blankLength)
            const answers = blanks.concat(response.data.data[prop])

            data.push(Object.assign({}, [prop, ...answers]))
          } else {
            data.push(Object.assign({}, [prop, ...response.data.data[prop]]))
          }
        }

        this.trials = props
        this.trialData = data
      } catch (error) {
        throw new Error(error)
      }
    },
    async fetchExcelFile() {
      try {
        const params = this.$_param_createParams()
        const response = await getRawDataByGamesetId({
          gamedataId: this.currentGamedata.summary.id,
          params,
        })

        this.$_excel_downloadFile(response, `${this.gameKey}_rawdata`)
      } catch (error) {
        this.$store.dispatch("setError", error)
      }
    },
  },
}
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
}

.card_wrapper {
  margin-bottom: 1rem;
  align-self: flex-start;
}

.title_wrapper {
  margin: 1rem 0;
}
</style>

<template>
  <section class="upload_thumbnail_wrapper">
    <Spinner v-if="!thumbnailHasLoaded" />
    <div
      v-else
      class="upload_thumbnail_inner_wrapper"
    >
      <label
        class="input_label"
      >
        <img
          v-if="hasThumbnail"
          ref="preview"
          :src="previewThumbnail"
          draggable="false"
          alt="preview img"
          class="preview_thumbnail"
        >
        <pre
          v-else
          class="label_text"
        >{{ $t('template.UPLOAD_THUMBNAIL') }}</pre>
      </label>
      <v-btn
        class="change_thumbnail"
        @click="onChange"
      >
        {{ $t('detail.UPDATE') }}
      </v-btn>
    </div>
    <image-crop
      v-if="modalOpenStatus"
      @uploadImage="uploadThumbnail"
      @closeModal="closeModal"
    />
  </section>
</template>

<script>
import Spinner from "@/components/common/Spinner.vue"
import ImageCrop from "@/components/common/ImageCrop.vue"

export default {
  components: {
    Spinner,
    ImageCrop,
  },
  props: {
    templateId: {
      type: [String, Number],
      default: "",
    },
    thumbnailHasLoaded: {
      type: Boolean,
      default: false,
    },
    thumbnail: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      previewThumbnail: null,
      uploadedMessage: "",
      hasThumbnail: false,
      uploadedImageUrl: "",
      modalOpenStatus: false,
    }
  },
  watch: {
    thumbnailHasLoaded() {
      if (this.thumbnail) {
        this.setPreviewImage(this.thumbnail)
      }
    },
  },
  methods: {
    setInitialThumbnail(thumbnail) {
      this.hasThumbnail = !!thumbnail
      this.$refs.preview.src = thumbnail
    },
    onChange() {
      this.modalOpenStatus = true
    },
    setPreviewImage(thumbnail) {
      this.hasThumbnail = true
      this.previewThumbnail = thumbnail
    },
    removeThumbnail() {
      this.uploadThumbnail(null)
    },
    async uploadThumbnail(thumbnail) {
      const formData = new FormData()
      const config = { headers: { "Content-Type": "multipart/form-data", }, }
      let requestData = {}

      formData.append("thumbnail", thumbnail)
      requestData = {
        templateId: this.templateId,
        data: formData,
        config,
      }

      try {
        const response = await this.$store.dispatch("template/patchTemplateData", requestData)

        if (response.status === 200) {
          this.closeModal()
          this.setPreviewImage(response.data.data.thumbnail)
        }
      } catch (error) {
        alert(this.$i18n.t("template.UPLOAD_THUMBNAIL_ERROR"))
      }
    },
    closeModal() {
      this.modalOpenStatus = false
    },
  },
}
</script>

<style scoped>
.upload_thumbnail_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  border: 1px solid;
  border-radius: 4px;
}

.upload_thumbnail_inner_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input_label {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 0.9rem;
  letter-spacing: -1;
}

.label_text {
  font-family: inherit;
  text-align: center;
  width: 100%;
}

.upload_image {
  opacity: 0;
  width: 0;
  height: 0;
}

.preview_thumbnail_wrapper {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.preview_thumbnail {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  border: 1px solid;
}

.change_thumbnail {
  height: 1.5rem !important;
}
</style>

<template>
  <v-select
    :label="label"
    :value="value"
    :items="items"
    :item-value="itemValue"
    @change="handleSelect"
  />
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number, Object],
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
    itemValue: {
      type: String,
      default: "value",
    },
  },
  methods: {
    handleSelect(value) {
      this.$emit("input", value)
    },
  },
}
</script>

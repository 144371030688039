<template>
  <div class="wrapper">
    <div class="container first_container" />
    <div class="button_wrapper">
      <span
        class="button"
      >
        <span
          class="icon"
          @click="addTemplate"
        >&#11013;</span>
      </span>
      <span
        class="button"
      >
        <span
          class="icon right_arrow"
          @click="addGames"
        >&#11013;</span>
      </span>
    </div>
    <div class="container second_container" />
  </div>
</template>

<script>
export default {
  props: {
    templateData: {
      type: Object,
      default: () =>({}),
    },
    gameData: {
      type: Array,
      default: () =>[],
    },
  },
  data() {
    return {
      selectedElementTemplate: [],
      selectedElementGames: [],
      checkGames: [],
      allGames: [],
      initialTemplateData: { games: [], },
      containers: null,
      draggable: null,
    }
  },
  async mounted() {
    this.containers = document.querySelectorAll(".container")
    this.setContainerEvent()
  },
  created() {
    this.getGames()
  },
  methods: {
    getGames() {
      this.allGames = this.gameData
    },
    reset() {
      this.checkGames = []
      this.selectedElementTemplate = []
      this.selectedElementGames = []
      this.containers.forEach((container) => container.innerHTML = "")
    },
    setInitialTemplateData(gameData, templateData = this.initialTemplateData) {
      if (gameData === undefined) {
        return
      }

      this.reset()
      gameData.forEach((game) => {
        let state = true

        templateData.games.forEach((templateGame) => {
          if (templateGame.id === game.id) {
            state = false

            return
          }
        })

        if (state || this.checkGames.indexOf(game) !== -1) {
          this.checkGames.push(game)
        }
      })

      this.setTemplateGamesInContainer(0, templateData.games)
      this.setTemplateGamesInContainer(1, this.checkGames)
      this.draggable = document.querySelectorAll(".draggable")
      this.setDraggableEvent(this.draggable)
    },
    setTemplateGamesInContainer(target, items) {
      items.forEach((item) => {
        const pTag = document.createElement("p")

        pTag.dataset.value = item.id
        pTag.draggable = true
        pTag.textContent = `[${item.id}] ${item.name} \n (v.${item.version})`
        pTag.classList.add("draggable")

        if (target === 1) {
          this.containers[target].prepend(pTag)
        } else {
          this.containers[target].appendChild(pTag)
        }
      })
    },
    submit() {
      const items = [...document.querySelectorAll(".first_container .draggable")]

      if (items.length === 0) {
        alert(this.$t("template.TEMPLATE_ERROR"))

        return
      }

      const selectedGames = items.map((ele) => ({ id: parseInt(ele.dataset.value), }))

      this.$emit("setTemplateGames", selectedGames)
    },
    addTargetContainer(currentIndex, targetIndex, items) {
      if (items.length > 0) {
        items = this.sortedArray(items)
        items.forEach((item) => {
          item.classList.remove("selected")
          this.containers[currentIndex].removeChild(item)
          this.containers[targetIndex].appendChild(item)
        })

        if (currentIndex === 0) {
          this.sortedArrayGames()
        }
      }
    },
    sortedArray(arr) {
      return arr.sort((ele1, ele2) => {
        return ele1.dataset.value - ele2.dataset.value
      })
    },
    sortedArrayGames() {
      let items = [...document.querySelectorAll(".second_container .draggable")]

      this.containers[1].innerHtml = ""
      items = this.sortedArray(items)
      items.forEach((item) => this.containers[1].appendChild(item))
    },
    addTemplate() {
      this.addTargetContainer(1, 0, this.selectedElementGames)
      this.selectedElementGames = []
    },
    addGames() {
      this.addTargetContainer(0, 1, this.selectedElementTemplate)
      this.selectedElementTemplate = []
    },
    setDragStartEvent(e) {
      e.target.classList.add("dragging")
      this.selectedElementGames = this.selectedElementGames.filter((ele) => !ele.classList.contains("dragging"))
      this.selectedElementTemplate = this.selectedElementTemplate.filter((ele) => !ele.classList.contains("dragging"))
    },
    setDragEndEvent(e) {
      e.target.classList.remove("dragging")
    },
    setClickEvent(e) {
      e.target.classList.toggle("selected")

      if (e.target.parentElement.classList.contains("second_container")) {
        const index = this.selectedElementGames.indexOf(e.target)

        if (index === -1) {
          this.selectedElementGames.push(e.target)
        } else {
          this.selectedElementGames.splice(index, 1)
        }
      } else {
        const index = this.selectedElementTemplate.indexOf(e.target)

        if (index === -1) {
          this.selectedElementTemplate.push(e.target)
        } else {
          this.selectedElementTemplate.splice(index, 1)
        }
      }
    },
    setDraggableEvent(draggable) {
      draggable.forEach((draggable) => {
        draggable.addEventListener("dragstart", this.setDragStartEvent)
        draggable.addEventListener("dragend", this.setDragEndEvent)
        draggable.addEventListener("click", this.setClickEvent)
      })
    },
    setContainerEvent() {
      this.containers.forEach((container) => {
        container.addEventListener("dragover", this.setDragOverEvent)
      })
    },
    setDragOverEvent(e) {
      e.preventDefault()

      const afterElment = this.getDragAfterElement(e.target, e.clientY)
      const draggable = document.querySelector(".dragging")

      if (afterElment === null && e.target.classList.contains("container")) {
        e.target.appendChild(draggable)
      }

      if (afterElment !== null && e.target.classList.contains("container")) {
        e.target.insertBefore(draggable, afterElment)
      }

      if (e.target.classList.contains("second_container")) {
        this.sortedArrayGames()
      }

      draggable.classList.remove("selected")
    },
    getDragAfterElement(container, y) {
      const draggableElements = [...container.querySelectorAll(".draggable:not(.dragging)")]

      return draggableElements.reduce((closest, child) => {
        const box = child.getBoundingClientRect()
        const offset = y - box.top - box.height / 2

        if (offset < 0 &&  offset > closest.offset) {
          return { offset: offset, element: child, }
        } else {
          return closest
        }
      }, { offset: Number.NEGATIVE_INFINITY, }).element
    },
  },
}
</script>

<style scoped>
.wrapper {
  align-items: center;
  display: flex;
  height: 70%;
  justify-content: space-between;
  width: 100%;
  margin: 1rem 0;
}

.container {
  border: 2px solid #333;
  overflow-x: hidden;
  padding: 1rem;
  height: 34rem;
  margin: 0;
  border-radius: 4px;
}

.first_container {
  min-width: 200px;
  width: 25%;
}

.second_container {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
  min-width: 500px;
  width: 65%;
}

.button_wrapper {
  display: flex;
  flex-direction: column;
}

.button {
  align-items: center;
  display: flex;
  height: 2rem;
  justify-content: center;
  margin-bottom: 1rem;
  width: 3rem;
}

.wrapper >>> .draggable {
  background-color: rgb(236, 236, 236);
  border: 1px solid rgb(56, 56, 56);
  cursor: pointer;
  padding: 1rem;
  transition: all 0.7s;
  border-radius: 4px;
  color: rgb(56, 56, 56);
  font-weight: 600;
  letter-spacing: -1;
}

.wrapper >>> .draggable.dragging {
  opacity: 0.5;
}

.second_container >>> .draggable {
  max-height: 200px;
  min-height: 100px;
  width: 100%;
}

.wrapper >>> .draggable.selected {
  background-color: rgba(17, 109, 230, 0.3);
}

.icon{
  font-size: 1.5rem;
  cursor: pointer;
}

.right_arrow {
  transform: rotate(180deg);
}

@media screen and (max-width: 1060px) {
  .wrapper {
    flex-direction: column;
  }

  .first_container {
    width: 100%;
    max-height: 300px;
  }

  .second_container {
    min-width: 0;
    width: 100%;
    overflow-x: auto;
  }

  .draggable {
    min-width: 50px;
  }

  .button_wrapper {
    transform: rotate(90deg);
  }
}
</style>

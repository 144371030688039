import axiosInstance from "./http"

const setup = (store) => {
  axiosInstance.interceptors.request.use(
    async(config) => {
      let token = store.state.admin.adminData.key

      if (token) {
        config.headers.Authorization = `Token ${token}`
      }

      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
}

export default setup

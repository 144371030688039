export function formatDate(date) {
  let month = date.getMonth() + 1
  let day = date.getDate()

  month = month >= 10 ? month : "0" + month
  day = day >= 10 ? day : "0" + day

  return date.getFullYear() + "-" + month + "-" + day
}

export function formatISOString(ISOString) {
  const dateUTC = new Date(ISOString)
  const dateLocal = dateUTC.toString()
  const dateObj = new Date(dateLocal)
  const year = dateObj.getFullYear()
  let month = dateObj.getMonth() + 1
  let day = dateObj.getDate()
  let hour = dateObj.getHours()
  let minute = dateObj.getMinutes()

  month = month >= 10 ? month : "0" + month
  day = day >= 10 ? day : "0" + day
  hour = hour >= 10 ? hour : "0" + hour
  minute = minute >= 10 ? minute : "0" + minute

  return `${year}-${month}-${day} ${hour}:${minute}`
}

export function objectToString(object) {
  return JSON.stringify(object)
}

export function stringToObject(string) {
  return JSON.parse(string)
}

export function arrayToString(array) {
  let string = ""

  for (let i = 0; i < array.length; i++) {
    if (typeof array[i] === "object") {
      string += array[i].name
    } else {
      string += array[i]
    }

    if (i < array.length - 1) {
      string += ", "
    }
  }

  return string
}

export function getGameKeyFromPercentile(value) {
  const percentageIndex = value.indexOf("percentile")
  const dashIndex = percentageIndex - 1
  let index = percentageIndex
  let gameKeyIndex

  while (0 <= index) {
    if (value[index] === ".") {
      gameKeyIndex = index + 1

      break
    }

    index--
  }

  return value.slice(gameKeyIndex, dashIndex)
}

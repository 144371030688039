import bus from "@/utils/bus"

export default {
  methods: {
    errorHandling(error) {
      const statusText = error.response.statusText

      if (statusText === "Unauthorized") {
        this.$store.dispatch("setError", error)

        return
      }

      bus.$emit("end:loading")
      alert(this.$i18n.t("error.DATA_FETCH_ERROR"))
      this.$router.push({ name: "Home", })
    },
  },
}

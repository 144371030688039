import * as lookupApi from "@/api/lookup"
import { arrayToString, } from "@/utils/format"
import { MENU, CATEGORY, } from "@/constants/mapVarNames"

export default {
  data() {
    return {
      id: this.$route.params.id,
      isModalShown: false,
      isDataFetched: false,
      fetchedData: null,
    }
  },
  computed: {
    menu() {
      return this.$store.state.lookup.lookupMenu
    },
    category() {
      return this.$store.state.lookup.lookupCategory
    },
  },
  mounted() {
    this.$_detail_handleFetchDetailData(() => this.$_detail_handleSetIsDataFetched())
  },
  methods: {
    $_detail_handleSetIsDataFetched() {
      this.isDataFetched = true
    },
    $_detail_handleProceed(callback) {
      this.$_detail_handleClose()
      callback()
    },
    $_detail_handleOpen() {
      this.isModalShown = true
    },
    $_detail_handleClose() {
      this.isModalShown = false
    },
    $_detail_handleRedirect(routeName) {
      const menu = this.menu

      if (menu === MENU.users) {
        this.$router.push({ name: routeName, })
      } else if (menu === MENU.games || menu === MENU.gamesets || menu === MENU.surveys) {
        this.$router.push({ name: menu, })
      }
    },
    async $_detail_handleFetchDetailData(callback) {
      try {
        let response

        if (this.category === CATEGORY.game) {
          response = await lookupApi.lookupByGameId(this.id)
        } else if (this.category === CATEGORY.gameset) {
          response = await lookupApi.lookupByGameSetId(this.id)
        } else if (this.category === CATEGORY.survey) {
          response = await lookupApi.lookupBySurveyId(this.id)
        } else {
          response = await lookupApi.lookupByUserId(this.category, this.id)
        }

        const data = response.data.data

        if (this.category === CATEGORY.gameset) {
          this.fetchedData = data

          if (data.user) {
            data.user = data.user.username
          }

          if (data.template) {
            data.template = { id: data.template.id, }
          }

          if (data.survey) {
            data.survey = { id: data.survey.id, }
          }
        }

        if (this.category === CATEGORY.survey) {
          data.contents = JSON.stringify(data.contents, null, 2)
        }

        for (const key in this.userData) {
          if (Array.isArray(data[key])) {
            this.userData[key] = arrayToString(data[key])
          } else {
            this.userData[key] = data[key] && data[key]
          }
        }

        if (callback instanceof Function) {
          callback()
        }
      } catch (error) {
        this.$store.dispatch("setError", error, { root: true, })
      }
    },
    async $_detail_handleDelete() {
      try {
        if (this.menu === MENU.games) {
          await lookupApi.deleteGameById(this.id)
        } else if (this.menu === MENU.gamesets) {
          await lookupApi.deleteGamesetById(this.id)
        } else if (this.menu === MENU.surveys) {
          await lookupApi.deleteSurveyById(this.id)
        } else {
          await lookupApi.deleteUserById(this.category, this.id)
        }

        this.$_detail_handleRedirect(this.category)
      } catch (error) {
        this.$store.dispatch("setError", error, { root: true, })
      }
    },
    async $_detail_handleSave(data, redirect = true) {
      let response

      try {
        if (this.menu === MENU.games) {
          await lookupApi.patchGameById(this.id, data)
        } else if (this.menu === MENU.gamesets) {
          await lookupApi.patchGameSetById(this.id, data)
        } else if (this.menu === MENU.surveys) {
          await lookupApi.patchSurveyById(this.id, data)
        } else {
          response = await lookupApi.patchUserById(this.category, this.id, data)
        }

        if (redirect) {
          this.$_detail_handleRedirect(this.category)
        }

        return response && response.data.data
      } catch (error) {
        this.$store.dispatch("setError", error, { root: true, })
      }
    },
  },
}

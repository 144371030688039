<template>
  <div>
    <PageTitle :value="title" />
    <div class="padding" />
    <ul>
      <li
        v-for="(value, name) in inputData"
        :key="name"
        class="li"
      >
        <div
          v-if="inputData[name].type === 'text'"
          class="input_wrapper"
        >
          <Input
            v-model="surveyData[name]"
            :label="name"
          />
        </div>
        <div
          v-else-if="inputData[name].type === 'textarea'"
          class="input_wrapper"
        >
          <JsonTextarea
            v-model="surveyData[name]"
            :label="name"
          />
        </div>
        <div v-else-if="inputData[name].type === 'checkbox'">
          <Checkbox
            v-model="surveyData[name]"
            :label="name"
          />
        </div>
      </li>
    </ul>
    <div class="btn_wrapper">
      <v-btn
        type="submit"
        class="btn"
        @click="handleCreate"
      >
        {{ $t("detail.SAVE") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/common/headers/PageTitle"
import Input from "@/components/common/formControls/Input"
import JsonTextarea from "@/components/inputs/JsonTextarea"
import Checkbox from "@/components/common/formControls/Checkbox"
import { createSurvey, } from "@/api/lookup"

export default {
  components: {
    PageTitle,
    Input,
    JsonTextarea,
    Checkbox,
  },
  data() {
    return {
      title: this.$t("surveys.CREATE_TITLE"),
      surveyData: {
        title: "",
        description: "",
        contents: "",
        is_active: false,
      },
      inputData: {
        title: {
          type: "text",
        },
        description: {
          type: "text",
        },
        contents: {
          type: "textarea",
        },
        is_active: {
          type: "checkbox",
        },
      },
    }
  },
  methods: {
    async handleCreate() {
      try {
        const surveyData = { ...this.surveyData, }
        const parsedContents = JSON.parse(this.surveyData.contents)

        surveyData.contents = parsedContents
        await createSurvey(surveyData)
        this.$router.push({ name: "surveys", })
      } catch (error) {
        this.$store.dispatch("setError", error, { root: true, })
      }
    },
  },
}
</script>

<style scoped>
.padding {
  height: 1rem;
}

.li {
  list-style: none;
  display: flex;
}

.input_wrapper {
  width: 70%;
}

.label {
  width: 20%;
  max-width: 10rem;
}

.btn_wrapper {
  display: flex;
  justify-content: center;
}
</style>

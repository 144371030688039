import bus from "../utils/bus"

export default {
  data() {
    return {
      dataLoadingStatus: false,
    }
  },
  created() {
    bus.$on("start:loading", this.startSpinner)
    bus.$on("end:loading", this.endSpinner)
  },
  beforeDestroy() {
    bus.$off("start:loading", this.startSpinner)
    bus.$off("end:loading", this.endSpinner)
  },
  methods: {
    startSpinner() {
      this.dataLoadingStatus = true
    },
    endSpinner() {
      this.dataLoadingStatus = false
    },
  },
}

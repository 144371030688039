var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"template_wrapper"},[_c('PageTitle',{attrs:{"value":_vm.title,"suffix":_vm.$t('common.MANAGEMENT')}}),_c('Search',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTableStatus),expression:"showTableStatus"}],staticClass:"search_component",attrs:{"store-module-name":_vm.storeModuleName,"placeholder":_vm.$t('lookupPlaceholder.TEMPLATE')},on:{"search":_vm.setFetchedData}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTableStatus),expression:"showTableStatus"}],staticClass:"filter_write_button_wrapper"},[_c('Filters',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTableStatus),expression:"showTableStatus"}],staticClass:"filter_component",attrs:{"options":_vm.options,"store-module-name":_vm.storeModuleName},on:{"search":_vm.setFetchedData}}),_c('CreateButton',{on:{"click":function($event){return _vm.redirection({ id: 'new', })}}})],1),(_vm.showTableStatus)?_c('div',{staticClass:"v-table-custom"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.dataList,"items-per-page":_vm.pageSize,"item-key":"id","hide-default-footer":""},on:{"click:row":_vm.redirection},scopedSlots:_vm._u([{key:"item.games",fn:function(ref){
var item = ref.item;
return _vm._l((item.games),function(game,index){return _c('span',{key:game.id,staticClass:"game_id"},[_vm._v(" "+_vm._s(game.id)),(_vm.isLastGame(index, item))?_c('span',[_vm._v(",")]):_vm._e()])})}},{key:"item.users",fn:function(ref){
var item = ref.item;
return [_c('TableIcon',{attrs:{"value":_vm.hasUsers(item)}})]}},{key:"item.for_mobile",fn:function(ref){
var item = ref.item;
return [_c('TableIcon',{attrs:{"value":item.for_mobile}})]}},{key:"item.is_public",fn:function(ref){
var item = ref.item;
return [_c('TableIcon',{attrs:{"value":item.is_public}})]}},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [_c('TableIcon',{attrs:{"value":item.is_active}})]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.created_at.slice(0,10))+" ")])]}}],null,true)})],1):_vm._e(),(_vm.showTableStatus)?_c('Pagination',{attrs:{"page-info":_vm.pageInfo,"target":_vm.pageTarget,"page-size":_vm.pageSize},on:{"resetData":_vm.resetData,"updatePageSize":_vm.$_pageSize_updatePageSize}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="wrapper">
    <PageTitle :value="title" />
    <Search :label="$t('lookupPlaceholder.GAME')" />
    <v-app>
      <div class="filter_wrapper">
        <div class="filter">
          <FilterList />
        </div>
        <CreateButton @click="handleRedirect('createGame')" />
      </div>
      <v-data-table
        class="elevation-1"
        :headers="headers"
        :items="dataList"
        :hide-default-footer="true"
        :server-items-length="dataList.length"
        @click:row="handleClickRow"
      >
        <template #[`item.created_at`]="{ item }">
          <span>
            {{ formatISOString(item.created_at) }}
          </span>
        </template>
      </v-data-table>
      <Pagination />
    </v-app>
  </div>
</template>

<script>
import CreateButton from "@/components/common/buttons/CreateButton"
import table from "@/mixins/table"
import { MENU, CATEGORY, } from "@/constants/mapVarNames"
import { formatISOString, } from "@/utils/format"

export default {
  components: {
    CreateButton,
  },
  mixins: [table],
  data() {
    return {
      title: this.$t("nav.GAME"),
      filterItems: {
        is_active: null,
      },
      headers: [
        {
          text: this.$t("headers.games.ID"),
          value: "id",
        },
        {
          text: this.$t("headers.games.KEY"),
          value: "key",
        },
        {
          text: this.$t("headers.games.VERSION"),
          value: "version",
        },
        {
          text: this.$t("headers.games.NAME"),
          value: "name",
        },
        {
          text: this.$t("headers.games.DESCRIPTION"),
          value: "description",
        },
        {
          text: this.$t("headers.games.IS_ACTIVE"),
          value: "is_active",
          sortable: false,
        },
        {
          text: this.$t("headers.games.CREATED_AT"),
          value: "created_at",
          sortable: false,
        }
      ],
    }
  },
  created() {
    this.$store.commit("SET_LOOKUP_CATEGORY", { menu: MENU.games, category: CATEGORY.game, })
    this.$store.dispatch("setFilterItems", this.filterItems)
    this.$store.dispatch("fetchLookupData")
  },
  methods: {
    formatISOString,
    handleRedirect(routeName) {
      this.$router.push({ name: routeName, })
    },
    handleClickRow(rowData) {
      const dataId = rowData.id

      this.$router.push({ name: "gameDetail", params: { id: dataId, }, })
    },
  },
}
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.filter_wrapper {
  display: flex;
  justify-content: space-between;
}

.btn {
  width: 100px;
}
</style>

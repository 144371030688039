<template>
  <div class="wrapper">
    <PageTitle :value="$t('approve.TITLE')" />
    <Search :label="$t('lookupPlaceholder.COMPANY')" />
    <div class="refresh_btn_wrapper">
      <v-btn @click="handleClickRefresh">
        <v-icon left>
          mdi-refresh
        </v-icon>
        {{ $t("approve.REFRESH") }}
      </v-btn>
    </div>
    <v-app>
      <v-data-table
        class="elevation-1"
        :headers="headers"
        :items="dataList"
        :hide-default-footer="true"
        :server-items-length="dataList.length"
      >
        <template #[`item.registration`]="{ item }">
          <a
            :href="item.registration"
            target="_blank"
            class="link"
          >
            <v-icon small>
              mdi-paperclip
            </v-icon>
          </a>
        </template>
        <template #[`item.action`]="{ item }">
          <div
            v-if="updated"
            class="btn_cotainer"
          >
            <v-btn
              :color="checkedById[item.id].isChecked && checkedById[item.id].isApproved ? 'blue lighten-1' : 'blue'"
              @click="handleClickApprove(item.id)"
            >
              <v-icon
                v-if="checkedById[item.id].isChecked && checkedById[item.id].isApproved"
                small
                color="white"
              >
                mdi-check
              </v-icon>
              <span
                v-else
                class="btn_text"
              >
                {{ $t("approve.APPROVE") }}
              </span>
            </v-btn>
            <v-btn
              :color="checkedById[item.id].isChecked && !checkedById[item.id].isApproved ? 'red lighten-1' : 'red'"
              @click="handleClickDecline(item.id)"
            >
              <v-icon
                v-if="checkedById[item.id].isChecked && !checkedById[item.id].isApproved"
                small
                color="white"
              >
                mdi-check
              </v-icon>
              <span
                v-else
                class="btn_text"
              >
                {{ $t("approve.DECLINE") }}
              </span>
            </v-btn>
          </div>
        </template>
      </v-data-table>
      <Pagination />
    </v-app>
  </div>
</template>

<script>
import table from "@/mixins/table"
import * as lookupApi from "@/api/lookup"
import { MENU, CATEGORY, } from "@/constants/mapVarNames"

export default {
  mixins: [table],
  data() {
    return {
      headers: [
        {
          text: this.$t("headers.ID"),
          value: "id",
          sortable: true,
        },
        {
          text: this.$t("headers.USERNAME"),
          value: "username",
          sortable: true,
        },
        {
          text: this.$t("headers.NAME"),
          value: "name",
          sortable: false,
        },
        {
          text: this.$t("headers.COMPANY_NAME"),
          value: "company_name",
          sortable: false,
        },
        {
          text: this.$t("headers.TEL"),
          value: "tel",
          sortable: false,
        },
        {
          text: this.$t("headers.REGISTRATION"),
          value: "registration",
          sortable: false,
        },
        {
          text: this.$t("headers.ACTION"),
          value: "action",
          sortable: false,
          class: "action_header",
        }
      ],
      renderComponent: true,
      updated: true,
    }
  },
  computed: {
    checkedById() {
      const checkedById = {}

      if (!this.dataList) {
        return checkedById
      }

      for (let i = 0; i < this.dataList.length; i++) {
        const companyId = this.dataList[i].id

        checkedById[companyId] = { isChecked: false, isApproved: false, }
      }

      return checkedById
    },
  },
  created() {
    this.$store.commit("SET_LOOKUP_CATEGORY", { menu: MENU.users, category: CATEGORY.approve, })
    this.$store.dispatch("fetchLookupData")
  },
  methods: {
    async handleClickApprove(companyId) {
      try {
        await lookupApi.approveCompanyById(companyId)
        this.checkedById[companyId].isChecked = true
        this.checkedById[companyId].isApproved = true
        this.forceTableSlotUpdate()
      } catch (error) {
        this.$store.dispatch("setError", error)
      }
    },
    async handleClickDecline(companyId) {
      try {
        await lookupApi.declineCompanyById(companyId)
        this.checkedById[companyId].isChecked = true
        this.checkedById[companyId].isApproved = false
        this.forceTableSlotUpdate()
      } catch (error) {
        this.$store.dispatch("setError", error)
      }
    },
    handleClickRefresh() {
      this.$store.dispatch("fetchLookupData")
    },
    forceTableSlotUpdate() {
      this.updated = false
      this.updated = true
    },
  },
}
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.refresh_btn_wrapper {
  margin-bottom: 1rem;
}

.header {
  background: black;
  color: white;
  font-weight: bold;
  padding: 0.4em;
  padding-left: 1em;
  min-width: 100px;
}

.header_text {
  font-size: 0.9rem;
}

.table_header_xs {
  padding: 0 0.75em;
}

.table_header_sm {
  padding: 0.4em;
  min-width: 120px;
}

.table_header_md {
  padding: 0.4em;
  min-width: 160px;
}

.table_header_lg {
  padding: 0.4em;
  min-width: 200px;
}

.link {
  width: 100%;
  display: flex;
  justify-content: center;
  text-decoration: none;
}

.action_header {
  width: 172px;
  min-width: 172px;
}

.btn_cotainer {
  display: flex;
  justify-content: space-between;
  margin: 4px 0;
}

.btn_text {
  color: white;
}
</style>

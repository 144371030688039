<template>
  <div class="search_wrapper">
    <div class="input_wrapper">
      <span class="v_icon_wrapper">
        <v-icon>
          mdi-magnify
        </v-icon>
      </span>
      <input
        v-model="search"
        type="text"
        class="search_input"
        :placeholder="placeholder"
        @keypress.enter="checkKeyword"
      >
    </div>
    <Tags
      v-if="tagList.length"
      :tag-list="tagList"
      @deleteTags="deleteTags"
      @resetTags="resetTags"
      @reSearch="reSearch"
    />
  </div>
</template>

<script>
import Tags from "@/components/common/Tags.vue"

export default {
  components: {
    Tags,
  },
  props: {
    storeModuleName: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      tagList: [],
      inputTimer: null,
      search: "",
    }
  },
  methods: {
    checkKeyword() {
      if (!this.search.trim()) {
        this.search = ""

        return
      }

      const keywords = {
        q: this.search,
      }

      this.addTags(this.search.toLowerCase())
      this.searching(keywords)
      this.search = ""
    },
    async searching(keywords) {
      const response = await this.$store.dispatch(`${this.storeModuleName}/fetchData`, keywords)

      this.$emit("search", response)
    },
    checkTag(tag) {
      const index = this.tagList.indexOf(tag)

      return index
    },
    addTags(tag) {
      if (this.checkTag(tag) !== -1) {
        return
      }

      this.tagList.push(tag)
    },
    deleteTags(tag) {
      const index = this.checkTag(tag)

      this.tagList.splice(index, 1)

      if (this.tagList.length === 0) {
        this.searching({})
      }
    },
    reSearch(tag) {
      this.search = tag
      this.checkKeyword()
    },
    resetTags() {
      this.tagList = []
      this.searching({})
    },
  },
}
</script>

<style scoped>
.search_wrapper {
  width: 100%;
  height: 100%;
  border-radius: 0.2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0.3rem;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.input_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2rem;
}

.search_input {
  width: 95%;
  border-bottom: 2px solid rgba(128,128,128,0.3);
  outline: none;
  padding: 0.1rem;
  height: 100%;
  font-size: 0.9rem;
  margin-bottom: 0.3rem;
}

.v_icon_wrapper {
  width: 5%;
  display: flex;
  height: 100%;
  justify-content: center;
}
</style>

<template>
  <div class="criteria_item">
    <input
      type="Number"
      class="inpute_item"
      :value="value"
      @input="inputHandler"
    >
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: 0,
    },
  },
  methods: {
    inputHandler(e) {
      this.$emit("input", e.target.value)
    },
  },
}
</script>

<style scoped>
.criteria_item {
  width: 7rem;
  border-bottom: 1px solid rgba(0,0,0,0.6);
}

.inpute_item {
  width: 100%;
  text-align: center;
}
</style>

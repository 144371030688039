<template>
  <div class="wrapper">
    <PageTitle :value="title" />
    <div class="padding" />
    <div>
      <div
        v-for="(value, name) in inputData"
        :key="name"
        class="list"
      >
        <Input
          v-if="inputData[name].type === 'text'"
          v-model="userData[name]"
          :label="name"
          :is-disabled="inputData[name].isDisabled"
        />
        <Checkbox
          v-else-if="inputData[name].type === 'checkbox'"
          v-model="userData[name]"
          :label="name"
        />
        <div
          v-else-if="inputData[name].type === 'file'"
          class="file_container"
        >
          <div id="preview">
            <img
              v-if="thumbnailPreviewUrl"
              :src="thumbnailPreviewUrl"
              class="thumbnail"
            >
          </div>
          <v-file-input
            :id="name"
            label="upload image"
            show-size
            accept="image/*"
            @change="handleUploadImageFile"
          />
        </div>
      </div>
    </div>
    <div class="btn_wrapper">
      <DeleteButton @click="$_detail_handleOpen" />
      <SaveButton @click="handleSaveGame" />
    </div>
    <v-card
      v-if="userData.scheme"
      class="d-flex flex-column pa-6"
    >
      <h2 class="error--text align-self-center">
        {{ $t("games.WARNING") }}
      </h2>
      <TextFieldList v-model="userData.scheme" />
      <div class="btn">
        <v-btn
          class="align-self-center"
          color="secondary"
          @click="handleUpdateScheme"
        >
          {{ $t("detail.UPDATE") }}
        </v-btn>
      </div>
    </v-card>
    <Modal
      v-if="isModalShown"
      :title="$t('modal.UNSUBSCRIBE_MESSAGE')"
      :desc="$t('modal.DELETE_GAME_MESSAGE')"
      @yes="$_detail_handleProceed($_detail_handleDelete)"
      @no="$_detail_handleClose"
    />
  </div>
</template>

<script>
import TextFieldList from "@/components/inputs/TextFieldList"
import PageTitle from "@/components/common/headers/PageTitle"
import Input from "@/components/common/formControls/Input"
import Checkbox from "@/components/common/formControls/Checkbox"
import SaveButton from "@/components/common/buttons/SaveButton"
import DeleteButton from "@/components/common/buttons/DeleteButton"
import Modal from "@/components/common/modals/Modal"
import detail from "@/mixins/detail"
import * as gameApi from "@/api/lookup"

export default {
  components: {
    TextFieldList,
    PageTitle,
    Input,
    Modal,
    Checkbox,
    SaveButton,
    DeleteButton,
  },
  mixins: [detail],
  data() {
    return {
      title: this.$t("nav.GAME"),
      userData: {
        id: "",
        key: "",
        version: "",
        name: "",
        description: "",
        bg_image: "",
        is_active: "",
        created_at: "",
        scheme: {},
      },
      inputData: {
        key: {
          type: "text",
          isDisabled: true,
        },
        version: {
          type: "text",
          isDisabled: true,
        },
        name: {
          type: "text",
        },
        description: {
          type: "text",
        },
        bg_image: {
          type: "file",
        },
        is_active: {
          type: "checkbox",
        },
      },
      thumbnailFile: null,
      thumbnailPreviewUrl: "",
    }
  },
  watch: {
    "userData.bg_image": function() {
      this.handleSetImageFile(this.userData.bg_image)
    },
  },
  methods: {
    handleUploadImageFile(uploadedImage) {
      this.userData.bg_image = uploadedImage
    },
    handleSetImageFile(image) {
      if (!image) {
        this.thumbnailFile = null
        this.thumbnailPreviewUrl = ""

        return
      }

      this.thumbnailFile = image
      this.thumbnailPreviewUrl = (typeof image === "string" || image === null) ? image : URL.createObjectURL(image)
    },
    handleSaveGame() {
      const formData = new FormData()

      if (typeof this.userData.bg_image === "string" || this.userData.bg_image === null) {
        delete this.userData.bg_image
      }

      for (const key in this.userData) {
        formData.append(key, this.userData[key])
      }

      this.$_detail_handleSave(formData)
    },
    async handleUpdateScheme() {
      const gameId = this.userData.id
      const updatedScheme = this.userData.scheme

      try {
        await gameApi.updateSchemeByGameId(gameId, { scheme: updatedScheme, })
        this.$_detail_handleRedirect(this.menu)
      } catch (error) {
        this.$store.dispatch("setError", error)
      }
    },
  },
}
</script>

<style scoped>
.wrapper {
  width: 100%;
}

.padding {
  height: 1rem;
}

.list_wrapper {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.list {
  list-style: none;
  display: flex;
  align-items: center;
}

.file_container {
  width: 100%;
}

.btn_wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 2em;
}

.btn {
  align-self: center;
}

.thumbnail {
  width: 20rem;
  height: 20rem;
}
</style>

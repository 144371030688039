<template>
  <ul class="arcodian_wrapper">
    <li
      v-for="(item,index) in arcodianItemList"
      :key="index"
      :class="[index
        ? 'user_handle_item arcotian_item'
        : 'user_handle_label nav_menu_item_wrapper arcotian_item']"
    >
      <v-icon
        v-if="index === 0"
        large
        :color="iconColor"
      >
        mdi-table-account
      </v-icon>
      <v-icon
        v-else
        :color="iconColor"
      >
        mdi-account-multiple
      </v-icon>
      <span
        v-if="index === 0"
        :class="['nav_menu_item', closeStatus && 'closed']"
      >
        {{ item }}
      </span>
      <router-link
        v-else
        :to="`/${routerName[index]}`"
        class="nav_menu_item"
      >
        {{ item }}
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    closeStatus: {
      type: Boolean,
      default: false,
    },
    iconColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      arcodianItemList: [
        this.$t("nav.USER"),
        this.$t("nav.USER_STAFF"),
        this.$t("nav.USER_UNSUB"),
        this.$t("nav.USER_PERSON"),
        this.$t("nav.USER_COMPANY"),
        this.$t("nav.USER_APPROVE")
      ],
      routerName: [null, "staff", "unsub", "person", "company", "approve"],
      arcodian: null,
    }
  },
  watch: {
    closeStatus() {
      if (this.closeStatus && this.arcodian[0].classList.contains("show")) {
        this.setShowClass()
      }
    },
  },
  mounted() {
    this.arcodianOpenElem = document.querySelector(".user_handle_label")
    this.arcodian = document.querySelectorAll(".user_handle_item")
    this.arcodianOpenElem.addEventListener("click", this.openArcodian)
  },
  methods: {
    openArcodian() {
      if (!this.closeStatus) {
        this.setShowClass()
      } else {
        this.$emit("open")
        this.setShowClass()
      }
    },
    setShowClass() {
      this.arcodian.forEach((ele) => ele.classList.toggle("show"))
    },
  },
}
</script>

<style scoped>
.arcodian_wrapper {
  height: 100%;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  padding: 0;
}

.user_handle_label {
  cursor: pointer;
  height: 3rem;
  width: 100%;
  border: 1px solid;
}

.user_handle_item {
  height: 0;
  opacity: 0;
  transition: all 0.4s ease-out;
  visibility: hidden;
  width: 70%;
  border-radius: 0.3rem;
  background-color: #fff;
  font-size: 0.9rem;
}

.user_handle_item.show {
  height: 2rem;
  opacity: 1;
  margin-bottom: .5rem;
  visibility: visible;
}

.arcotian_item {
  display: flex;
  padding: 0 1rem;
  justify-content: space-between;
  align-items: center;
}

.closed {
  display: none;
}
</style>

<template>
  <div class="footer_wrapper">
    <div class="first_btn">
      <button
        class="btn"
        @click="handlePagination(pagination.first_link)"
      >
        {{ $t("pagination.FIRST") }}
      </button>
    </div>
    <div class="middle_btn">
      <button
        class="btn"
        @click="handlePagination(pagination.prev_link)"
      >
        {{ $t("pagination.PREV") }}
      </button>
      <div class="page">
        {{ pagination.current_page }} / {{ pagination.page_total }}
      </div>
      <button
        class="btn"
        @click="handlePagination(pagination.next_link)"
      >
        {{ $t("pagination.NEXT") }}
      </button>
    </div>
    <div class="page_selector_wrapper">
      <v-select
        class="page_selector"
        :value="pageSize"
        :items="pageSizes"
        dense
        @change="handleUpdateValue"
      />
    </div>
  </div>
</template>

<script>
import { DEFAULT_PAGE_SIZE, } from "@/constants/sizes"
import http from "@/api/http"

export default {
  data() {
    return {
      pageSizes: [
        {
          value: DEFAULT_PAGE_SIZE,
          text: this.$t("lookup.PAGE_SIZE", { size: DEFAULT_PAGE_SIZE, }),
        },
        {
          value: 50,
          text: this.$t("lookup.PAGE_SIZE", { size: 50, }),
        },
        {
          value: 100,
          text: this.$t("lookup.PAGE_SIZE", { size: 100, }),
        }
      ],
    }
  },
  computed: {
    menu() {
      return this.$store.state.lookup.lookupMenu
    },
    category() {
      return this.$store.state.lookup.lookupCategory
    },
    pagination() {
      return this.$store.state.lookup[this.menu][this.category].pagination
    },
    pageSize: {
      get() {
        return this.$store.state.lookup[this.menu][this.category].pageSize
      },
      set(value) {
        this.handleUpdatePageSize(value)
      },
    },
  },
  methods: {
    async handlePagination(url) {
      if (!url) {
        return
      }

      try {
        const menu = this.menu
        const category = this.category
        const response = await http.get(url)
        const { data: dataList, pagination, } = response.data

        this.$store.commit("SET_LOOKUP_DATA", { menu, category, dataList, })
        this.$store.commit("SET_PAGINATION", { menu, category, pagination, })
      } catch (error) {
        this.$store.dispatch("setError", error)
      }
    },
    handleUpdateValue(value) {
      this.pageSize = value
    },
    handleUpdatePageSize(value) {
      this.$store.commit("SET_PAGE_SIZE", value)
      this.$store.dispatch("fetchLookupData")
    },
  },
}
</script>

<style scoped>
.footer_wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 4rem;
  padding: 2.5em 0;
  height: 30px;
  font-size: 0.8rem;
  justify-content: space-between;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.5rem;
  height: 1.5rem;
  border-radius: 0.2rem;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  cursor: pointer;
}

.first_btn {
  align-items: flex-start;
  width: 128px;
}

.middle_btn {
  display: flex;
  align-items: center;
  align-self: center;
}

.btn:hover {
  background-color: rgb(228, 228, 228);
}

.page {
  margin: 0 1rem;
}

.page_selector_wrapper {
  width: 128px;
  align-items: center;
}

.page_selector {
  width: 8rem;
  font-size: 0.8rem;
}
</style>

<template>
  <div class="tags_wrapper">
    <span
      class="tag"
      @click="resetTags"
    >
      <v-icon small>mdi-refresh</v-icon>
    </span>
    <span
      v-for="(tag,index) in tagList"
      :key="index"
      class="tag"
    >
      <span @click="reSearch(tag)">
        {{ tag }}
      </span>
      <v-icon
        x-small
        class="icon"
        @click="deleteTag(tag)"
      >
        mdi-window-close
      </v-icon>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    tagList: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    reSearch(tag) {
      this.$emit("reSearch", tag)
    },
    resetTags() {
      this.$emit("resetTags")
    },
    deleteTag(tag) {
      this.$emit("deleteTags", tag)
    },
  },
}
</script>

<style scope>
.tags_wrapper {
  width: 95%;
  height: 2rem;
  display: flex;
  align-items: flex-end;
  border-radius: 0.2rem;
  overflow: hidden;
  padding: 0.1rem;
}

.tag {
  margin-right: 0.5rem;
  font-size: 0.7rem;
  padding: 0.3rem;
  border-radius: inherit;
  cursor: pointer;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.tag:hover {
  background-color: rgba(19, 82, 218, 0.1);
}

.icon {
  margin-left: 0.5rem;
}

.icon:hover {
  border-radius: inherit;
  background-color: rgba(7, 34, 156, 0.2);
}
</style>

import {
  fetchFunnels,
  fetchSurveys,
  fetchTemplates,
} from "@/api/lookup"

export default {
  data() {
    const defaultOptionText = this.$t("options.NOT_SELECTED")
    const defaultOptionObj = {
      text: this.$t("options.NOT_SELECTED"),
      value: null,
    }

    return {
      defaultOptionText,
      defaultOptionObj,
      items: {
        gender: {
          isOptional: true,
          options: [
            defaultOptionText,
            this.$t("options.GENDER_OPTION_1"),
            this.$t("options.GENDER_OPTION_2")
          ],
        },
        is_active: {
          isOptional: true,
          options: [
            defaultOptionText,
            this.$t("options.IS_ACTIVE_OPTION_1"),
            this.$t("options.IS_ACTIVE_OPTION_2")
          ],
        },
        province: {
          isOptional: true,
          options: [
            defaultOptionText,
            this.$t("options.PROVINCE_OPTION_1"),
            this.$t("options.PROVINCE_OPTION_2"),
            this.$t("options.PROVINCE_OPTION_3"),
            this.$t("options.PROVINCE_OPTION_4"),
            this.$t("options.PROVINCE_OPTION_5"),
            this.$t("options.PROVINCE_OPTION_6"),
            this.$t("options.PROVINCE_OPTION_7"),
            this.$t("options.PROVINCE_OPTION_8"),
            this.$t("options.PROVINCE_OPTION_9"),
            this.$t("options.PROVINCE_OPTION_10"),
            this.$t("options.PROVINCE_OPTION_11"),
            this.$t("options.PROVINCE_OPTION_12"),
            this.$t("options.PROVINCE_OPTION_13"),
            this.$t("options.PROVINCE_OPTION_14"),
            this.$t("options.PROVINCE_OPTION_15"),
            this.$t("options.PROVINCE_OPTION_16"),
            this.$t("options.PROVINCE_OPTION_17")
          ],
        },
        education: {
          isOptional: true,
          options: [
            defaultOptionText,
            this.$t("options.EDUCATION_OPTION_1"),
            this.$t("options.EDUCATION_OPTION_2"),
            this.$t("options.EDUCATION_OPTION_3"),
            this.$t("options.EDUCATION_OPTION_4"),
            this.$t("options.EDUCATION_OPTION_5"),
            this.$t("options.EDUCATION_OPTION_6"),
            this.$t("options.EDUCATION_OPTION_7"),
            this.$t("options.EDUCATION_OPTION_8")
          ],
        },
        experience: {
          isOptional: true,
          options: [
            defaultOptionText,
            this.$t("options.EXPERIENCE_OPTION_1"),
            this.$t("options.EXPERIENCE_OPTION_2"),
            this.$t("options.EXPERIENCE_OPTION_3"),
            this.$t("options.EXPERIENCE_OPTION_4"),
            this.$t("options.EXPERIENCE_OPTION_5"),
            this.$t("options.EXPERIENCE_OPTION_6"),
            this.$t("options.EXPERIENCE_OPTION_7"),
            this.$t("options.EXPERIENCE_OPTION_8"),
            this.$t("options.EXPERIENCE_OPTION_9"),
            this.$t("options.EXPERIENCE_OPTION_10"),
            this.$t("options.EXPERIENCE_OPTION_11")
          ],
        },
        position: {
          isOptional: true,
          options: [
            defaultOptionText,
            this.$t("options.POSITION_OPTION_1"),
            this.$t("options.POSITION_OPTION_2"),
            this.$t("options.POSITION_OPTION_3"),
            this.$t("options.POSITION_OPTION_4"),
            this.$t("options.POSITION_OPTION_5"),
            this.$t("options.POSITION_OPTION_6"),
            this.$t("options.POSITION_OPTION_7"),
            this.$t("options.POSITION_OPTION_8"),
            this.$t("options.POSITION_OPTION_9"),
            this.$t("options.POSITION_OPTION_10"),
            this.$t("options.POSITION_OPTION_11"),
            this.$t("options.POSITION_OPTION_12"),
            this.$t("options.POSITION_OPTION_13"),
            this.$t("options.POSITION_OPTION_14"),
            this.$t("options.POSITION_OPTION_15")
          ],
        },
        industry: {
          isOptional: true,
          options: [
            defaultOptionText,
            this.$t("options.BUSINESS_LINE_OPTION_1"),
            this.$t("options.BUSINESS_LINE_OPTION_2"),
            this.$t("options.BUSINESS_LINE_OPTION_3"),
            this.$t("options.BUSINESS_LINE_OPTION_4"),
            this.$t("options.BUSINESS_LINE_OPTION_5"),
            this.$t("options.BUSINESS_LINE_OPTION_6"),
            this.$t("options.BUSINESS_LINE_OPTION_7"),
            this.$t("options.BUSINESS_LINE_OPTION_8"),
            this.$t("options.BUSINESS_LINE_OPTION_9"),
            this.$t("options.BUSINESS_LINE_OPTION_10"),
            this.$t("options.BUSINESS_LINE_OPTION_11"),
            this.$t("options.BUSINESS_LINE_OPTION_12"),
            this.$t("options.BUSINESS_LINE_OPTION_13"),
            this.$t("options.BUSINESS_LINE_OPTION_14")
          ],
        },
        for_study: {
          isOptional: true,
          options: [
            defaultOptionText,
            this.$t("options.FOR_STUDY_OPTION_1"),
            this.$t("options.FOR_STUDY_OPTION_2")
          ],
        },
        for_mobile: {
          isOptional: true,
          options: [
            defaultOptionText,
            this.$t("options.FOR_MOBILE_OPTION_1"),
            this.$t("options.FOR_MOBILE_OPTION_2")
          ],
        },
        status: {
          isOptional: true,
          options: [
            defaultOptionText,
            this.$t("options.STATUS_OPTION_1"),
            this.$t("options.STATUS_OPTION_2"),
            this.$t("options.STATUS_OPTION_3"),
            this.$t("options.STATUS_OPTION_4")
          ],
        },
        used: {
          isOptional: true,
          options: [
            defaultOptionText,
            this.$t("options.USED_OPTION_1"),
            this.$t("options.USED_OPTION_2")
          ],
        },
        success: {
          isOptional: true,
          options: [
            defaultOptionText,
            this.$t("options.SUCCESS_OPTION_1"),
            this.$t("options.SUCCESS_OPTION_2")
          ],
        },
        company_size: {
          isOptional: false,
          options: [
            this.$t("options.COMPANY_SIZE_OPTION_1"),
            this.$t("options.COMPANY_SIZE_OPTION_2"),
            this.$t("options.COMPANY_SIZE_OPTION_3"),
            this.$t("options.COMPANY_SIZE_OPTION_4"),
            this.$t("options.COMPANY_SIZE_OPTION_5"),
            this.$t("options.COMPANY_SIZE_OPTION_6"),
            this.$t("options.COMPANY_SIZE_OPTION_7")
          ],
        },
        funnel: {
          options: [],
          itemValue: "name",
          isOptional: true,
          fetcher: fetchFunnels,
          formatter: (optionItem) => {
            return optionItem.name
          },
        },
        survey: {
          options: [],
          isOptional: true,
          fetcher: fetchSurveys,
          formatter: (optionItem) => {
            return `${optionItem.id}-${optionItem.title}`
          },
        },
        template: {
          options: [],
          isOptional: false,
          fetcher: fetchTemplates,
          formatter: (optionItem) => {
            return `${optionItem.id}-${optionItem.name}`
          },
        },
        division: {
          isOptional: true,
          options: [],
        },
      },
    }
  },
  methods: {
    async $_options_setFetchedOptions(item) {
      try {
        const fetcher = this.items[item].fetcher
        const response = await fetcher()
        const options = response.data.data

        if (this.items[item].formatter) {
          this.items[item].options = options.map((option) => ({
            ...option,
            value: { id: option.id, },
            text: this.items[item].formatter(option),
          }))
        }

        if (this.items[item].isOptional) {
          this.items[item].options.unshift({
            id: null,
            value: null,
            text: this.defaultOptionText,
          })

          if (this.items[item].itemValue) {
            this.items[item].options[0][this.items[item].itemValue] = this.defaultOptionText
          }
        }
      } catch (error) {
        this.$store.dispatch("setError", error)
      }
    },
    $_options_setDivisionOptions(divisions) {
      if (Array.isArray(divisions)) {
        this.items.division.options = [this.defaultOptionText, ...divisions]
      } else {
        this.items.division.options = []
      }
    },
  },
}

export const getItem = (key) => JSON.parse(localStorage.getItem(key)) || null

export const getKey = (key) => localStorage.getItem(key)

export const setItem = (key, payload) => localStorage.setItem(key, JSON.stringify(payload))

export const removeItem = (key) => localStorage.removeItem(key)

export const clearStorage = () => {
  localStorage.removeItem("vuex")
}

export const clearAllStorage = () => localStorage.clear()

<template>
  <div>
    <PageTitle :value="title" />
    <div>
      <div
        v-for="(value, name) in userData"
        :key="name"
      >
        <Input
          v-if="inputData[name].type === 'text'"
          v-model="userData[name]"
          :label="name"
          :is-disabled="inputData[name].isDisabled"
        />
        <Checkbox
          v-else-if="inputData[name].type === 'checkbox'"
          v-model="userData[name]"
          :label="name"
        />
        <Select
          v-else-if="inputData[name].type === 'select'"
          v-model="userData[name]"
          :label="name"
          :items="items[name].options"
          :item-value="items[name].itemValue"
        />
      </div>
    </div>
    <div class="footer">
      <v-btn
        color="error"
        class="mr-12"
        @click="$_detail_handleOpen"
      >
        {{ $t("detail.WITHDRAW") }}
      </v-btn>
      <div class="save_btn_wrapper">
        <SaveButton @click="$_detail_handleSave(setStringDataToArray(userData))" />
      </div>
    </div>
    <Modal
      v-if="isModalShown"
      :title="$t('modal.WARNING')"
      :desc="$t('modal.UNSUBSCRIBE_MESSAGE')"
      @yes="$_detail_handleProceed($_detail_handleDelete)"
      @no="$_detail_handleClose"
    />
  </div>
</template>

<script>
import PageTitle from "@/components/common/headers/PageTitle"
import Input from "@/components/common/formControls/Input"
import Checkbox from "@/components/common/formControls/Checkbox"
import SaveButton from "@/components/common/buttons/SaveButton"
import Select from "@/components/common/formControls/Select"
import Modal from "@/components/common/modals/Modal"
import options from "@/mixins/options"
import detail from "@/mixins/detail"

export default {
  components: {
    PageTitle,
    Input,
    SaveButton,
    Checkbox,
    Select,
    Modal,
  },
  mixins: [detail, options],
  data() {
    return {
      title: this.$t("nav.USER_PERSON"),
      userData: {
        id: "",
        username: "",
        name: "",
        tel: "",
        birthday: "",
        is_female: "",
        education: "",
        experience: "",
        major: "",
        position: "",
        interested_co: "",
        skills: "",
        tel_approved: "",
        code_approved: "",
        email_approved: "",
        consent_terms: "",
        consent_privacy: "",
        consent_push: "",
        consent_marketing: "",
        is_active: "",
        date_joined: "",
      },
      inputData: {
        id: {
          value: "",
          type: "text",
          isDisabled: true,
        },
        username: {
          value: "",
          type: "text",
          isDisabled: true,
        },
        name: {
          value: "",
          type: "text",
        },
        tel: {
          value: "",
          type: "text",
        },
        birthday: {
          value: "",
          type: "text",
        },
        is_female: {
          value: "",
          type: "checkbox",
        },
        education: {
          value: "",
          type: "select",
        },
        experience: {
          value: "",
          type: "select",
        },
        salary: {
          value: "",
          type: "select",
        },
        major: {
          value: "",
          type: "text",
        },
        position: {
          value: "",
          type: "text",
        },
        interested_co: {
          value: "",
          type: "text",
        },
        skills: {
          value: "",
          type: "text",
        },
        tel_approved: {
          value: "",
          type: "checkbox",
        },
        code_approved: {
          value: "",
          type: "checkbox",
        },
        email_approved: {
          value: "",
          type: "checkbox",
        },
        consent_terms: {
          value: "",
          type: "checkbox",
        },
        consent_privacy: {
          value: "",
          type: "checkbox",
        },
        consent_push: {
          value: "",
          type: "checkbox",
        },
        consent_marketing: {
          value: "",
          type: "checkbox",
        },
        is_active: {
          value: "",
          type: "checkbox",
        },
        date_joined: {
          value: "",
          type: "text",
        },
      },
    }
  },
  methods: {
    setStringDataToArray(data) {
      const interested_co = data.interested_co.split(",")
        .filter((co) => co.trim())
        .map((co) => ({ name: co.trim(), }))
      const skills = data.skills.split(",")
        .filter((skill) => skill.trim())
        .map((skill) => ({ name: skill.trim(), }))

      return { ...data, interested_co, skills, }
    },
  },
}
</script>

<style scoped>
.footer {
  display: flex;
  position: relative;
  margin: 2rem 0 6rem;
  width: 100%;
}

.save_btn_wrapper {
  position: absolute;
  left: calc(50% - 32px);
}
</style>

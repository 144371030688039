import { requestTemplates, requestTemplate, requestGames, editTemplateData, removeTemplate, createTemplate, } from "../../api/template"
import bus from "../../utils/bus"

export default {
  namespaced: true,
  state: {
    templateDefault: {},
    template: null,
    templatePageInfo: {},
    templateDetail: {},
    games: null,
  },
  mutations: {
    async SET_TEMPLATE(state, templateData) {
      state.template = templateData
      state.templatePageInfo = templateData.pagination
    },
    SET_TEMPLATE_DETAIL(state, payload) {
      state.templateDetail = payload
    },
    SET_GAMES(state, payload) {
      state.games = payload
    },
    async SET_PAGE_INFO(state, pageInfo) {
      state.templatePageInfo = pageInfo
    },
  },
  actions: {
    async fetchData({ commit, dispatch, }, keywords = {}) {
      try {
        bus.$emit("start:loading")
        const response = await requestTemplates(keywords)

        if (response.status === 200) {
          commit("SET_TEMPLATE", response.data)

          return response.data
        }
      } catch (error) {
        dispatch("setError", error, { root: true, })
      }
    },
    async fetchTemplateDetailData({ commit, dispatch, }, templateId) {
      try {
        bus.$emit("start:loading")
        const response = await requestTemplate(templateId)

        if (response.status === 200) {
          commit("SET_TEMPLATE_DETAIL", response.data)

          return response
        }
      } catch (error) {
        dispatch("setError", error, { root: true, })
      }
    },
    async fetchGames({ commit, dispatch, }) {
      try {
        const response = await requestGames()

        if (response.status === 200) {
          commit("SET_GAMES", response.data.data)

          return response.data.data
        }
      } catch (error) {
        dispatch("setError", error, { root: true, })
      }
    },
    async patchTemplateData({ commit, dispatch, }, payload) {
      try {
        const response = await editTemplateData(payload.templateId, payload.data, payload.config)

        if (response.status === 200) {
          commit("SET_TEMPLATE_DETAIL", response.data)

          return response
        }
      } catch (error) {
        dispatch("setError", error, { root: true, })
      }
    },
    async deleteTemplate({ commit, dispatch, }, templateId) {
      try {
        await removeTemplate(templateId)

        return { error: false, }
      } catch (error) {
        dispatch("setError", error, { root: true, })
      }
    },
    async saveTemplate({ commit, dispatch, }, templateData) {
      try {
        const response = await createTemplate(templateData.data)

        response.status = 201

        return response
      } catch (error) {
        dispatch("setError", error, { root: true, })
      }
    },
  },
}

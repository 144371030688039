<template>
  <v-card>
    <v-data-table
      dense
      :headers="headers"
      :items="items"
      :items-per-page="pageSize"
      item-key="id"
      hide-default-footer
      @click:row="editItem"
    >
      <template #[`item.games`]="{ item }">
        <span
          v-for="(game, index) in item.games"
          :key="game.id"
          class="game_id"
        >
          {{ game.id }}<span v-if="isLastGame(index, item)">&#44;</span>
        </span>
      </template>
      <template #[`item.users`]="{ item }">
        <TableIcon :value="hasUsers(item)" />
      </template>
      <template #[`item.for_mobile`]="{ item }">
        <TableIcon :value="item.for_mobile" />
      </template>
      <template #[`item.is_public`]="{ item }">
        <TableIcon :value="item.is_public" />
      </template>
      <template #[`item.is_active`]="{ item }">
        <TableIcon :value="item.is_active" />
      </template>
      <template #[`item.created_at`]="{ item }">
        <span>
          {{ item.created_at.slice(0,10) }}
        </span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import TableIcon from "@/components/lookup/TableIcon"

export default {
  components: {
    TableIcon,
  },
  props: {
    headers: {
      type: Array,
      default: () =>[],
    },
    data: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    pageSize: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      items: [],
    }
  },
  computed: {
    hasUsers() {
      return (item) => {
        return item.users.length > 0 ? true : null
      }
    },
    isLastGame() {
      return (index, item) => {
        return index + 1 < item.games.length
      }
    },
  },
  methods: {
    editItem(item) {
      this.$router.push({ name: `set${this.title.slice(0, 1) + this.title.slice(1).toLowerCase()}`, params: { id: item.id, }, })
    },
    resetItems(data) {
      this.items = data
    },
  },
}
</script>

<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding-left: 1rem !important;
  text-align: center;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  background-color: #000 !important;
  color: #fff;
}

.v-data-table > .v-data-table__wrapper > table {
  padding: 12px !important;
}

.theme--light.v-data-table .v-data-footer {
  border-top: 0 !important;
}
</style>

<template>
  <div class="wrapper">
    <div
      class="login_wrapper"
      @keyup.enter="handleSubmit"
    >
      <h1 :class="['text-h4', 'font-weight-bold']">
        {{ $t("accounts.LOGIN") }}
      </h1>
      <v-text-field
        id="username"
        v-model="username"
        :label="$t('accounts.USERNAME')"
        :placeholder="$t('accounts.USERNAME_PLACEHOLDER')"
      />
      <v-text-field
        id="password"
        v-model="password"
        type="password"
        :label="$t('accounts.PASSWORD')"
        :placeholder="$t('accounts.PASSWORD_PLACEHOLDER')"
      />
      <div class="btn_container">
        <v-btn
          color="primary"
          @click="handleSubmit"
        >
          {{ $t("accounts.LOGIN") }}
        </v-btn>
        <v-btn @click="handleRedirect('signup')">
          {{ $t("accounts.SIGNUP") }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      password: "",
    }
  },
  methods: {
    handleSubmit() {
      const adminData = {
        username: this.username,
        password: this.password,
      }

      this.$store.dispatch("requestLogin", adminData)
    },
    handleRedirect(routeName) {
      this.$router.push({ name: routeName, })
    },
  },
}
</script>

<style scoped>
.wrapper {
  display: flex;
  justify-content: center;
  margin-top: 180px;
}

.login_wrapper {
  width: 80%;
  max-width: 304px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.btn_container {
  height: 96px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1rem;
}
</style>

<template>
  <v-btn
    :color="color"
    :type="type"
    :width="width"
    :height="height"
    :disabled="isDisabled"
    :depressed="isDepressed"
    @click="handleClick"
  >
    {{ text }}
  </v-btn>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "button",
    },
    color: {
      type: String,
      default: undefined,
    },
    width: {
      type: Number,
      default: undefined,
    },
    height: {
      type: Number,
      default: undefined,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isDepressed: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick() {
      this.$emit("click")
    },
  },
}
</script>

<template>
  <div class="textarea_form_wrapper">
    <label
      for="textarea_form"
      class="textarea_label"
    >
      {{ capitalize(desc) }}
    </label>
    <v-jsoneditor
      :value="value"
      :options="options"
      :plus="false"
      width="200px"
      height="600px"
      class="textarea_elem"
      @input="inputHandler"
      @error="onError"
    />
  </div>
</template>

<script>
export default {
  props: {
    desc: {
      type: String,
      default: "",
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      options: {
        mode: "code",
        statusBar: false,
        mainMenuBar: false,
      },
    }
  },
  computed: {
    capitalize() {
      return (desc) => {
        return `${desc.slice(0, 1).toUpperCase()}${desc.slice(1).toLowerCase()}`
      }
    },
  },
  methods: {
    inputHandler(json) {
      this.$emit("checkJSONValidation", true)
      this.$emit("input", json)
    },
    onError() {
      this.$emit("checkJSONValidation", false)
    },
  },
}
</script>

<style scoped>
.textarea_form_wrapper {
  width: 100%;
  display: flex;
  font-size: 1rem;
  position: relative;
  margin-bottom: 0.5rem;
}

.textarea_label {
  width: 7rem;
  border: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 0.9rem;
  letter-spacing: -1;
  border-radius: 4px;
  height: 2.5rem;
  flex-shrink: 0;
}

.textarea_elem {
  width: calc(100% - 7rem);
  box-sizing: border-box;
  margin-left: 1rem;
  border: 1px solid rgba(0,0,0,0.3);
  border-radius: 0.2rem;
  resize: none;
}
</style>

import http from "./http"

export async function lookupUsersByCategory({ category, params, }) {
  return http.get(`admin/users/${category}/`, { params, })
}

export async function approveCompanyById(id) {
  return http.post(`admin/users/company/${id}/approve/`)
}

export async function declineCompanyById(id) {
  return http.post(`admin/users/company/${id}/decline/`)
}

export async function lookupByUserId(category, id) {
  return http.get(`admin/users/${category}/${id}/`)
}

export async function patchUserById(category, id, data) {
  return http.patch(`admin/users/${category}/${id}/`, data)
}

export async function deleteUserById(category, id) {
  return http.delete(`admin/users/${category}/${id}/`)
}

export async function lookupGames({ params, }) {
  return http.get("admin/games/", { params, })
}

export async function lookupByGameId(id) {
  return http.get(`admin/games/${id}/`)
}

export async function createGame(data) {
  return http.post("admin/game/", data)
}

export async function patchGameById(id, formData) {
  return http.patch(`admin/game/${id}/`, formData)
}

export async function deleteGameById(id) {
  return http.delete(`admin/game/${id}/`)
}

export async function updateSchemeByGameId(id, data) {
  return http.put(`admin/game/${id}/scheme/`, data)
}

export async function lookupGamesetByCategory({ id, category, params, }) {
  return http.get(`admin/gamesets/${id}/${category}/`, { params, })
}

export async function lookupGamesets({ params, }) {
  return http.get("admin/gamesets/", { params, })
}

export async function createGameset(data) {
  return http.post("admin/gameset/", data)
}

export async function deleteGamesetById(id) {
  return http.delete(`admin/gameset/${id}/`)
}

export async function closeGamesetById(id) {
  return http.post(`admin/gameset/${id}/close/`)
}

export async function lookupByGameSetId(id) {
  return http.get(`admin/gamesets/${id}/`)
}

export async function lookupRawDataByGamedataId(id, key) {
  return http.get(`admin/rawdata/${id}/?key=${key}`)
}

export async function patchGameSetById(id, data) {
  return http.patch(`admin/gameset/${id}/`, data)
}

export async function fetchFunnels() {
  return http.get("things/funnel/?page_size=all")
}

export async function fetchTemplates() {
  return http.get("admin/templates/all/?active=true")
}

export async function fetchSurveys() {
  return http.get("admin/surveys/all/?active=true")
}

export async function lookupSurveys({ params, }) {
  return http.get("admin/surveys/", { params, })
}

export async function createSurvey(data) {
  return http.post("admin/survey/", data)
}

export async function lookupBySurveyId(id) {
  return http.get(`admin/surveys/${id}/`)
}

export async function patchSurveyById(id, data) {
  return http.patch(`admin/survey/${id}/`, data)
}

export async function lookupSurveyByCategory({ id, category, params, }) {
  return http.get(`admin/surveys/${id}/${category}/`, { params, })
}

export async function deleteSurveyById(id) {
  return http.delete(`admin/survey/${id}/`)
}

export async function lookupAuthCodes({ params, }) {
  return http.get("admin/auth_codes/", { params, })
}

export async function uploadThumbnail(thumbnailData) {
  return http.post("things/file/", thumbnailData)
}

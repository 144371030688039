<template>
  <div class="wrapper">
    <PageTitle :value="title" />
    <Search :label="$t('lookupPlaceholder.AUTH_CODE')" />
    <v-app>
      <div class="filter_wrapper">
        <div class="filter">
          <FilterList />
        </div>
      </div>
      <v-data-table
        class="elevation-1"
        :headers="headers"
        :items="dataList"
        :hide-default-footer="true"
        :server-items-length="dataList.length"
      >
        <template #[`item.is_used`]="{ item }">
          <TableIcon :value="item.is_used" />
        </template>
        <template #[`item.created_at`]="{ item }">
          <span>
            {{ formatISOString(item.created_at) }}
          </span>
        </template>
        <template #[`item.tried_at`]="{ item }">
          <span>
            {{ formatISOString(item.tried_at) }}
          </span>
        </template>
      </v-data-table>
      <Pagination />
    </v-app>
  </div>
</template>

<script>
import TableIcon from "@/components/lookup/TableIcon"
import table from "@/mixins/table"
import { formatISOString, } from "@/utils/format"
import { MENU, CATEGORY, } from "@/constants/mapVarNames"

export default {
  components: { TableIcon, },
  mixins: [table],
  data() {
    return {
      title: this.$t("nav.AUTH_CODE"),
      filterItems: {
        used: null,
        success: null,
      },
      headers: [
        {
          text: this.$t("headers.authCode.ID"),
          value: "id",
          sortable: false,
        },
        {
          text: this.$t("headers.authCode.EMAIL"),
          value: "email",
          sortable: false,
        },
        {
          text: this.$t("headers.authCode.TEL"),
          value: "tel",
          sortable: false,
        },
        {
          text: this.$t("headers.authCode.CODE"),
          value: "code",
          sortable: false,
        },
        {
          text: this.$t("headers.authCode.WRONG_INPUT"),
          value: "wrong_input",
          sortable: false,
        },
        {
          text: this.$t("headers.authCode.IS_USED"),
          value: "is_used",
          sortable: false,
        },
        {
          text: this.$t("headers.authCode.CREATED_AT"),
          value: "created_at",
          sortable: false,
        },
        {
          text: this.$t("headers.authCode.TRIED_AT"),
          value: "tried_at",
          sortable: false,
        }
      ],
    }
  },
  created() {
    this.$store.commit("SET_LOOKUP_CATEGORY", { menu: MENU.authCodes, category: CATEGORY.authCode, })
    this.$store.dispatch("setFilterItems", this.filterItems)
    this.$store.dispatch("fetchLookupData")
  },
  methods: {
    formatISOString,
  },
}
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.filter_wrapper {
  display: flex;
  justify-content: space-between;
}

.btn {
  width: 100px;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('PageTitle',{attrs:{"value":_vm.title}}),_c('Search',{attrs:{"label":_vm.$t('lookupPlaceholder.GAMESET')}}),_c('v-app',[_c('div',{staticClass:"filter_wrapper"},[_c('div',{staticClass:"filter"},[_c('FilterList')],1),_c('CreateButton',{on:{"click":function($event){return _vm.handleRedirect('createGameset')}}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.dataList,"hide-default-footer":true,"server-items-length":_vm.dataList.length},on:{"click:row":_vm.handleClickRow},scopedSlots:_vm._u([{key:"item.data",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"blue-grey lighten-4",on:{"click":function (event) { return _vm.handleToDataPage(event, item); }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-database-search-outline ")])],1)]}},{key:"item.for_study",fn:function(ref){
var item = ref.item;
return [_c('TableIcon',{attrs:{"value":item.for_study}})]}},{key:"item.for_mobile",fn:function(ref){
var item = ref.item;
return [_c('TableIcon',{attrs:{"value":item.for_mobile}})]}},{key:"item.show_selfscore",fn:function(ref){
var item = ref.item;
return [_c('TableIcon',{attrs:{"value":item.show_selfscore}})]}},{key:"item.is_started",fn:function(ref){
var item = ref.item;
return [_c('TableIcon',{attrs:{"value":item.is_started}})]}},{key:"item.is_completed",fn:function(ref){
var item = ref.item;
return [_c('TableIcon',{attrs:{"value":item.is_completed}})]}},{key:"item.is_deleted",fn:function(ref){
var item = ref.item;
return [_c('TableIcon',{attrs:{"value":item.is_deleted}})]}}],null,true)}),_c('Pagination')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
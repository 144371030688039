<template>
  <div class="modal_wrapper">
    <v-card
      elevation="3"
      class="pa-4 rounded-lg"
      width="300px"
      min-height="200px"
    >
      <v-card-title class="text-h5">
        {{ title }}
      </v-card-title>
      <v-card-text class="body-1">
        {{ desc }}
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between">
        <v-btn
          color="error"
          class="text-button"
          @click="$emit('yes')"
        >
          {{ $t('modal.YES') }}
        </v-btn>
        <v-btn
          class="text-button"
          @click="$emit('no')"
        >
          {{ $t('modal.NO') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    desc: {
      type: String,
      default: "",
    },
  },
}
</script>

<style scoped>
/* TODO: TBD position */
.modal_wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}
</style>

import {
  fetchData,
  editData,
  deleteData,
  createData,
} from "../../api/adminCommon"
import bus from "../../utils/bus"

export default {
  namespaced: true,
  state: {
    dataList: null,
  },
  mutations: {
    SET_DATA(state, dataList) {
      state.dataList = dataList
    },
  },
  actions: {
    async fetchDataList({ commit, dispatch, }, payload) {
      try {
        bus.$emit("start:loading")
        const response = await fetchData(payload.url, payload.params)

        if (response.status === 200) {
          commit("SET_DATA", response.data)

          return response.data
        }
      } catch (error) {
        dispatch("setError", error, { root: true, })
      }
    },
    async updateDataItem({ dispatch, }, payload) {
      try {
        bus.$emit("start:loading")
        const response = await editData(payload.url, payload.id, payload.data)

        if (response.status === 200) {

          return response.data
        }
      } catch (error) {
        dispatch("setError", error, { root: true, })
      }
    },
    async deleteDataItem({ dispatch, }, payload) {
      try {
        await deleteData(payload.url, payload.id)
      } catch (error) {
        dispatch("setError", error, { root: true, })
      }
    },
    async createDataItem({ dispatch, }, payload) {
      try {
        bus.$emit("start:loading")
        const response = await createData(payload.url, payload.data)

        if (response.status === 201) {

          bus.$emit("end:loading")

          return response
        }
      } catch (error) {
        dispatch("setError", error, { root: true, })
      }
    },
  },
}

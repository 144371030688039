<template>
  <v-select
    v-model="selectedOption"
    :items="option.value"
    dense
    hide-details
    outlined
    class="filter_selector"
    :label="option.label"
    @input="selectOption(option.id, $event)"
  />
</template>

<script>
export default {
  props: {
    option: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedOption: null,
    }
  },
  methods: {
    selectOption(key, option) {
      this.selectedOption = option
      this.$emit("setParameter", { key, option, })
    },
    resetSelector() {
      this.selectedOption = null
    },
  },
}
</script>

<style scoped>
.filter_selector {
  padding: 0 6px;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: none;
  border-radius: 4px;
  list-style: none;
  height: 100%;
  width: 8em;
  margin: 0;
}
</style>

<template>
  <div class="filter_wrapper">
    <v-btn
      class="rest_btn"
      type="button"
      value="filter reset"
      @click="searchByFilter(null)"
    >
      {{ $t("headers.RESET_FILTER") }}
    </v-btn>
    <div
      v-for="option in options"
      :key="option.id"
      class="filter_selector"
    >
      <FilterSelector
        ref="selector"
        :key="option.label"
        :option="option"
        @setParameter="setParameter"
      />
    </div>
  </div>
</template>

<script>
import FilterSelector from "./FilterSelector.vue"

export default {
  components: {
    FilterSelector,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    storeModuleName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selectedOptions: {},
    }
  },
  created() {
    this.setInitialOption()
  },
  methods: {
    setInitialOption() {
      this.options.forEach((option) => this.selectedOptions[option.id] = null)
    },
    resetSelector() {
      this.setInitialOption()
      this.$refs.selector.forEach((ref) => ref.resetSelector())
    },
    setParameter({ key, option, }) {
      this.selectedOptions[key] = option
      this.searchByFilter(this.selectedOptions)
    },
    async searchByFilter(item) {
      let keywords = item

      if (item === null) {
        keywords = {}
        this.resetSelector()
      }

      const response = await this.$store.dispatch(`${this.storeModuleName}/fetchData`, keywords)

      this.$emit("search", response)
    },
  },
}
</script>

<style scoped>
.filter_wrapper {
  height: 40px;
  display: flex;
}

.filter_selector {
  margin-right: .5rem;
}

.rest_btn {
  height: 100%;
  padding: 0;
  margin-right: 0.75rem;
}
</style>

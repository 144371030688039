<template>
  <div class="footer_wrapper">
    <button
      class="btn first_btn"
      @click="pagination('first_link')"
    >
      {{ $t("pagination.FIRST") }}
    </button>
    <button
      class="btn prev_btn"
      @click="pagination('prev_link')"
    >
      {{ $t("pagination.PREV") }}
    </button>
    <div class="page">
      {{ pageInfo.current_page }} / {{ pageInfo.page_total }}
    </div>
    <button
      class="btn next_btn"
      @click="pagination('next_link')"
    >
      {{ $t("pagination.NEXT") }}
    </button>
    <div class="page_selector_wrapper">
      <v-select
        :value="pageSize"
        class="page_selector"
        :items="pageSizes"
        dense
        @change="updatePageSize"
      />
    </div>
  </div>
</template>

<script>
import { DEFAULT_PAGE_SIZE, } from "@/constants/sizes"
import http from "../../api/http"

export default {
  props: {
    pageInfo: {
      type: Object,
      default: () => ({}),
    },
    target: {
      type: Array,
      default: () => [],
    },
    pageSize: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      pageSizes: [
        {
          value: DEFAULT_PAGE_SIZE,
          text: this.$t("lookup.PAGE_SIZE", { size: DEFAULT_PAGE_SIZE, }),
        },
        {
          value: 50,
          text: this.$t("lookup.PAGE_SIZE", { size: 50, }),
        },
        {
          value: 100,
          text: this.$t("lookup.PAGE_SIZE", { size: 100, }),
        }
      ],
    }
  },
  methods: {
    async pagination(target) {
      const link = this.pageInfo[target]

      if (link === null) {
        return
      }

      const { data, } = await http.get(link)

      this.$emit("resetData", { dataList: data.data, pageInfo: data.pagination, })
    },
    updatePageSize(pageSize) {
      this.$emit("updatePageSize", pageSize)
    },
  },
}
</script>

<style scoped>
.footer_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4rem;
  padding: 12px;
  position: relative;
  font-size: 0.8rem;
}

.first_btn {
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.5rem;
  height: 1.5rem;
  border-radius: 0.2rem;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  cursor: pointer;
}

.btn:hover {
  background-color: rgb(228, 228, 228);
}

.page {
  margin: 0 1rem;
}

.page_selector_wrapper {
  position: absolute;
  right: 0;
}

.page_selector {
  width: 8rem;
  font-size: 0.8rem;
}
</style>

import http from "./http"

export async function getExcelDataByUsersCategory({ category, params, }) {
  return http.get(`admin/users/${category}/export/`, {
    responseType: "blob",
    params,
  })
}

export async function getExcelDataByGamesetId({ gamesetId, category, params, }) {
  return http.get(`admin/gamesets/${gamesetId}/${category}/export/`, {
    responseType: "blob",
    params,
  })
}

export async function getExcelDataBySurveyId({ surveyId, category, params, }) {
  return http.get(`admin/surveys/${surveyId}/${category}/export/`, {
    responseType: "blob",
    params,
  })
}

export async function getFaceStudyByGamesetId({ gamesetId, }) {
  return http.get(`admin/gameset/${gamesetId}/facestudy/export/`, {
    responseType: "blob",
  })
}

export async function getDetectionStudyByGamesetId({ gamesetId, }) {
  return http.get(`admin/gameset/${gamesetId}/detectionstudy/export/`, {
    responseType: "blob",
  })
}

export async function getRawDataByGamesetId({ gamedataId, params, }) {
  return http.get(`admin/rawdata/${gamedataId}/export/`, {
    responseType: "blob",
    params,
  })
}

export async function getAllRawData({ gamesetId, params, }) {
  return http.get(`admin/allrawdata/${gamesetId}/export/`, {
    responseType: "blob",
    params,
  })
}

<template>
  <ul class="filter_wrapper">
    <li class="filter_item">
      <Button
        :text="$t('headers.RESET_FILTER')"
        @click="handleResetFilters"
      />
    </li>
    <li
      v-for="(item, name) in filterItems"
      :key="name"
      class="filter_item"
    >
      <FilterListItem
        v-show="name !== 'key'"
        :name="name"
        :menu="lookupMenu"
        :category="lookupCategory"
        :item-value="items[name].itemValue"
        :options="items[name].options"
      />
    </li>
  </ul>
</template>

<script>
import options from "../../mixins/options"
import FilterListItem from "./FilterListItem"
import Button from "@/components/common/buttons/Button"
import { MENU, CATEGORY, } from "@/constants/mapVarNames"

export default {
  components: {
    Button,
    FilterListItem,
  },
  mixins: [options],
  computed: {
    lookupMenu() {
      return this.$store.state.lookup.lookupMenu
    },
    lookupCategory() {
      return this.$store.state.lookup.lookupCategory
    },
    filterItems() {
      return this.$store.state.lookup[this.lookupMenu][this.lookupCategory].filterItems
    },
    gameset() {
      return this.$store.state.lookup.gamesets.currentItem
    },
  },
  created() {
    const menu = this.lookupMenu
    const category = this.lookupCategory

    if (menu === MENU.users && category === CATEGORY.unsub) {
      this.$_options_setFetchedOptions("funnel")
    }

    if (menu === MENU.gamesets && (category === CATEGORY.data || category === CATEGORY.gamedata)) {
      const divisions = this.gameset.divisions

      this.$_options_setDivisionOptions(divisions)
    }
  },
  methods: {
    handleResetFilters() {
      this.$store.dispatch("resetFilterItems")
      this.$store.dispatch("fetchLookupData")
    },
  },
}
</script>

<style scoped>
.filter_wrapper {
  display: flex;
  padding: 0;
  margin-bottom: 0.75em;
}

.filter_item {
  list-style: none;
  margin-right: 0.75rem;
}
</style>

export default {
  methods: {
    $_excel_downloadFile(fileResponse, fileName) {
      const blob = new Blob(
        [fileResponse.data],
        { type: fileResponse.headers["content-type"], }
      )
      const link = document.createElement("a")

      link.href = URL.createObjectURL(blob)
      link.download = fileName
      link.click()
      URL.revokeObjectURL(link.href)
    },
  },
}

<template>
  <div class="wrapper">
    <div class="header_wrapper">
      <h1 class="title_wrapper">
        {{ `${$t("gamesets.GAMESET_DATA")} (${$t("gamesets.DIVISION")})` }}
      </h1>
      <v-btn
        color="primary"
        class="btn"
        @click="handleRedirect('gamesetData')"
      >
        {{ $t("gamesets.TO_GAMESET_DATA") }}
      </v-btn>
    </div>
    <div class="card_wrapper">
      <GamesetCard
        :card-data="cardData"
      />
    </div>
    <div class="search_wrapper">
      <Search :label="$t('lookupPlaceholder.DEFAULT')" />
    </div>
    <v-app>
      <FilterList />
      <v-data-table
        v-if="headers"
        class="elevation-1"
        :headers="headers"
        :items="dataList"
        :options.sync="sortOptions"
        :hide-default-footer="true"
        :server-items-length="dataList.length"
      />
      <Pagination />
      <Excel @click="fetchExcelFile" />
    </v-app>
  </div>
</template>

<script>
import GamesetCard from "@/components/gameset/GamesetCard.vue"
import table from "@/mixins/table"
import excel from "@/mixins/excel"
import param from "@/mixins/param"
import sort from "@/mixins/sort"
import { getExcelDataByGamesetId, } from "@/api/exportExcel"
import { MENU, CATEGORY, } from "@/constants/mapVarNames"
import { getCurrentDate, } from "@/utils/date"

export default {
  components: {
    GamesetCard,
  },
  mixins: [table, excel, param, sort],
  data() {
    return {
      filterItems: {},
      sortOptions: {},
      headers: null,
    }
  },
  computed: {
    gameset() {
      return this.$store.state.lookup.gamesets.currentItem
    },
    cardData() {
      const gameset = `${this.gameset.id} - ${this.gameset.name}`
      let company_name = ""
      let username = ""

      if (this.gameset.user) {
        company_name = this.gameset.user.company_name
        username = this.gameset.user.username
      }

      return {
        company_name,
        username,
        gameset: gameset ? gameset : "",
        template: gameset ? gameset : "",
      }
    },
  },
  watch: {
    sortOptions: {
      handler(value) {
        if (value.sortBy[0]) {
          this.$_sort_handleSort(value.sortBy[0])
        }
      },
      deep: true,
    },
  },
  created() {
    this.$store.commit("SET_LOOKUP_CATEGORY", { menu: MENU.gamesets, category: CATEGORY.division, })
    this.$store.dispatch("setFilterItems", this.filterItems)
    this.$store.dispatch("fetchLookupData")
    this.handleSetGameProps()
  },
  methods: {
    handleRedirect(routeName) {
      this.$router.push({ name: routeName, })
    },
    handleSetGameProps() {
      const data = this.dataList[0]
      let headerText = []

      for (const key in data) {
        headerText.push(key)
      }

      const headers = headerText.map((item) => ({
        class: item.includes("percentile") ? "table_header_lg" : "table_header_xs",
        text: item,
        value: item,
        sortable: item.includes("percentile") ? true : false,
      }))

      this.headers = [...headers]
    },
    async fetchExcelFile() {
      try {
        const params = this.$_param_createParams()
        const response = await getExcelDataByGamesetId({
          gamesetId: this.gameset.id,
          category: CATEGORY.division,
          params,
        })

        this.$_excel_downloadFile(response, `gameset_${CATEGORY.division}_${getCurrentDate()}`)
      } catch (error) {
        this.$store.dispatch("setError", error)
      }
    },
  },
}
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header_wrapper {
  display: flex;
  align-items: center;
}

.card_wrapper {
  align-self: flex-start;
}

.title_wrapper {
  margin: 1rem 0;
}

.search_wrapper {
  width: 720px;
}

.btn {
  margin-left: 2rem;
}
</style>

<template>
  <div class="wrapper">
    <PageTitle :value="$t('nav.USER_UNSUB')" />
    <Search :label="$t('lookupPlaceholder.UNSUB')" />
    <v-app>
      <FilterList />
      <v-data-table
        class="elevation-1"
        :headers="headers"
        :items="dataList"
        :options.sync="sortOptions"
        :hide-default-footer="true"
        :server-items-length="dataList.length"
        @click:row="handleClickRow"
      />
      <Pagination />
      <Excel @click="fetchExcelFile" />
    </v-app>
  </div>
</template>

<script>
import table from "@/mixins/table"
import excel from "@/mixins/excel"
import param from "@/mixins/param"
import sort from "@/mixins/sort"
import { MENU, CATEGORY, } from "@/constants/mapVarNames"
import { getExcelDataByUsersCategory, } from "@/api/exportExcel"

export default {
  mixins: [table, excel, param, sort],
  data() {
    return {
      filterItems: {
        gender: null,
        province: null,
        funnel: null,
        education: null,
        experience: null,
        position: null,
        industry: null,
      },
      headers: [
        {
          text: this.$t("headers.ID"),
          value: "id",
          sortable: true,
        },
        {
          text: this.$t("headers.USERNAME"),
          value: "username",
          sortable: true,
        },
        {
          text: this.$t("headers.BIRTHDAY"),
          value: "birthday",
          sortable: true,
        },
        {
          text: this.$t("headers.GENDER"),
          value: "gender",
          sortable: false,
        },
        {
          text: this.$t("headers.FUNNEL"),
          value: "funnel",
          sortable: false,
        },
        {
          text: this.$t("headers.PROVINCE"),
          value: "province",
          sortable: false,
        },
        {
          text: this.$t("headers.EDUCATION"),
          value: "education",
          sortable: false,
        },
        {
          text: this.$t("headers.EXPERIENCE"),
          value: "experience",
          sortable: false,
        },
        {
          text: this.$t("headers.POSITION"),
          value: "position",
          sortable: false,
        },
        {
          text: this.$t("headers.BUSINESS_LINE"),
          value: "industry",
          sortable: false,
        }
      ],
      sortOptions: {},
    }
  },
  watch: {
    sortOptions: {
      handler(value) {
        if (value.sortBy[0]) {
          this.$_sort_handleSort(value.sortBy[0])
        }
      },
      deep: true,
    },
  },
  created() {
    this.$store.commit("SET_LOOKUP_CATEGORY", { menu: MENU.users, category: CATEGORY.unsub, })
    this.$store.dispatch("setFilterItems", this.filterItems)
    this.$store.dispatch("fetchLookupData")
  },
  methods: {
    async fetchExcelFile() {
      try {
        const params = this.$_param_createParams()
        const response = await getExcelDataByUsersCategory({ category: CATEGORY.unsub, params, })

        this.$_excel_downloadFile(response, CATEGORY.unsub)
      } catch (error) {
        this.$store.dispatch("setError", error)
      }
    },
    handleClickRow(rowData) {
      const dataId = rowData.id

      this.$router.push({ name: "unsubDetail", params: { id: dataId, }, })
    },
  },
}
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>

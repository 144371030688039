import http from "./http"

export function requestForms(params) {
  return http.get("admin/forms/", { params, })
}

export function requestFormsAll(params) {
  return http.get("admin/forms/all/", { params, })
}

export function requestForm(id) {
  return http.get(`admin/forms/${id}/`)
}

export function editFormData(formId, data, config) {
  return http.patch(`admin/form/${formId}/`, data, config)
}

export function removeForm(formId) {
  return http.delete(`admin/form/${formId}/`)
}

export function createForm(data) {
  return http.post("admin/form/", data)
}

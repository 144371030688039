var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('PageTitle',{attrs:{"value":_vm.$t('nav.USER_STAFF')}}),_c('Search',{attrs:{"label":_vm.$t('lookupPlaceholder.STAFF')}}),_c('v-app',[_c('FilterList'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.dataList,"options":_vm.sortOptions,"hide-default-footer":true,"server-items-length":_vm.dataList.length},on:{"update:options":function($event){_vm.sortOptions=$event},"click:row":_vm.handleClickRow},scopedSlots:_vm._u([{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [_c('TableIcon',{attrs:{"value":item.is_active}})]}},{key:"item.is_staff",fn:function(ref){
var item = ref.item;
return [_c('TableIcon',{attrs:{"value":item.is_staff}})]}},{key:"item.is_admin",fn:function(ref){
var item = ref.item;
return [_c('TableIcon',{attrs:{"value":item.is_admin}})]}},{key:"item.is_superuser",fn:function(ref){
var item = ref.item;
return [_c('TableIcon',{attrs:{"value":item.is_superuser}})]}}],null,true)}),_c('Pagination')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
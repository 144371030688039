<template>
  <div class="wrapper">
    <label
      :for="labelFor"
      class="label"
    >
      {{ labelText }}
    </label>
  </div>
</template>

<script>
export default {
  props: {
    labelFor: {
      type: String,
      default: null,
    },
    labelText: {
      type: String,
      required: true,
    },
  },
}
</script>

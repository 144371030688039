import { render, staticRenderFns } from "./CreateSurvey.vue?vue&type=template&id=8c09ca42&scoped=true&"
import script from "./CreateSurvey.vue?vue&type=script&lang=js&"
export * from "./CreateSurvey.vue?vue&type=script&lang=js&"
import style0 from "./CreateSurvey.vue?vue&type=style&index=0&id=8c09ca42&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c09ca42",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})

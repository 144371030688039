import Vue from "vue"
import App from "./App.vue"
import store from "./store"
import router from "./router"
import i18n from "./plugins/i18n"
import vuetify from "./plugins/vuetify"
import cropperjs from "./plugins/cropperjs"
import codemirror from "./plugins/codemirror"
import vjsoneditor from "./plugins/vjsoneditor"
import setupInterceptors from "./api/setupInterceptors"

setupInterceptors(store)
Vue.config.productionTip = false
Vue.config.devtools = process.env.NODE_ENV === "development" ? true : false

new Vue({
  vjsoneditor,
  codemirror,
  cropperjs,
  vuetify,
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app")

import { getItem, } from "../utils/storage"

export default {
  methods: {
    async $_pageSize_getTablePageSize(routeName) {
      try {
        const pageSizeObj = await getItem("pagesize")
        const fetchParams = {
          page_size: 0,
        }

        this.pageSize = pageSizeObj[routeName.toLowerCase()]
        fetchParams["page_size"] = this.pageSize

        return fetchParams
      } catch (error) {
        console.log(error)
      }
    },
    $_pageSize_updatePageSize(pageSize) {
      if (this.pageSize === pageSize) {
        return
      }

      const pageSizeInfo = {
        table_name: this.storeModuleName,
        page_size: pageSize,
      }

      this.pageSize = pageSize
      this.$store.dispatch("pagination/setPageSize", pageSizeInfo)
      this.fetchData({ page_size: pageSize, })
    },
  },
}

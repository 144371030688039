<template>
  <div class="modal_wrapper">
    <v-card
      elevation="3"
      class="pa-4 rounded-lg d-flex flex-column align-center text-center"
      width="300px"
      min-height="200px"
    >
      <v-card-title class="text-h5 mb-4">
        {{ title }}
      </v-card-title>
      <v-card-text class="body-1">
        {{ desc }}
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    isNotification: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    desc: {
      type: String,
      default: "",
    },
  },
}
</script>

<style scoped>
/* TODO: TBD position */
.modal_wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}
</style>

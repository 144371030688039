<template>
  <div class="template_wrapper">
    <PageTitle
      :value="title"
      :suffix="$t('common.MANAGEMENT')"
    />
    <Search
      v-show="showTableStatus"
      class="search_component"
      :store-module-name="storeModuleName"
      :placeholder="$t('lookupPlaceholder.TEMPLATE')"
      @search="setFetchedData"
    />
    <div
      v-show="showTableStatus"
      class="filter_write_button_wrapper"
    >
      <Filters
        v-show="showTableStatus"
        :options="options"
        :store-module-name="storeModuleName"
        class="filter_component"
        @search="setFetchedData"
      />
      <CreateButton @click="redirection({ id: 'new', })" />
    </div>
    <div
      v-if="showTableStatus"
      class="v-table-custom"
    >
      <v-data-table
        :headers="tableHeaders"
        :items="dataList"
        :items-per-page="pageSize"
        item-key="id"
        hide-default-footer
        class="elevation-1"
        @click:row="redirection"
      >
        <template #[`item.games`]="{ item }">
          <span
            v-for="(game, index) in item.games"
            :key="game.id"
            class="game_id"
          >
            {{ game.id }}<span v-if="isLastGame(index, item)">&#44;</span>
          </span>
        </template>
        <template #[`item.users`]="{ item }">
          <TableIcon :value="hasUsers(item)" />
        </template>
        <template #[`item.for_mobile`]="{ item }">
          <TableIcon :value="item.for_mobile" />
        </template>
        <template #[`item.is_public`]="{ item }">
          <TableIcon :value="item.is_public" />
        </template>
        <template #[`item.is_active`]="{ item }">
          <TableIcon :value="item.is_active" />
        </template>
        <template #[`item.created_at`]="{ item }">
          <span>
            {{ item.created_at.slice(0,10) }}
          </span>
        </template>
      </v-data-table>
    </div>
    <Pagination
      v-if="showTableStatus"
      :page-info="pageInfo"
      :target="pageTarget"
      :page-size="pageSize"
      @resetData="resetData"
      @updatePageSize="$_pageSize_updatePageSize"
    />
  </div>
</template>

<script>
import Filters from "@/components/common/Filters.vue"
import PageTitle from "@/components/common/headers/PageTitle.vue"
import CreateButton from "@/components/common/buttons/CreateButton"
import Search from "@/components/common/Search.vue"
import Pagination from "@/components/common/Pagination.vue"
import bus from "@/utils/bus"
import errorHandling from "@/mixins/dataFetchErrorHandling"
import pageSize from "../../mixins/pageSize"
import TableIcon from "@/components/lookup/TableIcon"

export default {
  components: {
    Search,
    Pagination,
    Filters,
    PageTitle,
    CreateButton,
    TableIcon,
  },
  mixins: [errorHandling, pageSize],
  data() {
    return {
      tableHeaders: [
        { text: "id", align: "start", value: "id", sortable: false, },
        { text: "name", value: "name", sortable: false, },
        { text: "games", value: "games", sortable: false, },
        { text: "eet", value: "eet", sortable: false, },
        { text: "description", value: "description",  sortable: false, },
        { text: "form", value: "form.name", sortable: false, },
        { text: "users", value: "users", sortable: false, },
        { text: "for_mobile", value: "for_mobile", sortable: false, },
        { text: "is_public", value: "is_public", sortable: false, },
        { text: "is_active", value: "is_active", sortable: false, },
        { text: "created_at", value: "created_at", sortable: false, }
      ],
      options: [
        {
          id: "active",
          label: this.$t("filter.label.ACTIVE_STATUS"),
          value: [
            { text: this.$t("filter.option.ALL"), value: null, },
            { text: this.$t("filter.option.ACTIVE"), value: true, },
            { text: this.$t("filter.option.DISABLE"), value: false, }
          ],
        },
        {
          id: "mobile",
          label: this.$t("filter.label.IS_MOBILE"),
          value: [
            { text: this.$t("filter.option.ALL"), value: null, },
            { text: this.$t("filter.option.FOR_MOBILE"), value: true, },
            { text: this.$t("filter.option.FOR_PC"), value: false, }
          ],
        }
      ],
      pageInfo: { current_pa: 0, page_total: 0, },
      pageTarget: ["tdl", "tpi"],
      dataList: null,
      showTableStatus: false,
      title: "",
      storeModuleName: "",
      pageSize: 20,
      fetchParams: null,
    }
  },
  computed: {
    hasUsers() {
      return (item) => {
        return item.users.length > 0 ? true : null
      }
    },
    isLastGame() {
      return (index, item) => {
        return index + 1 < item.games.length
      }
    },
  },
  created() {
    this.setInitialData()
  },
  methods: {
    async setInitialData() {
      const routeName = this.$route.name

      this.setTitleName(routeName)
      this.setStoreModuleName(routeName)
      this.fetchGames()
      this.fetchParams = await this.$_pageSize_getTablePageSize(routeName)
      this.fetchData(this.fetchParams)
    },
    setTitleName(routeName) {
      this.title = this.$t(`title.${routeName.toUpperCase()}`)
    },
    async fetchData(keyword = {}) {
      try {
        const response = await this.$store.dispatch("template/fetchData", keyword)

        this.setFetchedData(response)
      } catch (error) {
        this.errorHandling(error)
      }
    },
    setStoreModuleName(routeName) {
      this.storeModuleName = routeName.toLowerCase()
    },
    setFetchedData(response) {
      this.showTableStatus = false
      this.dataList = response.data
      this.pageInfo = response.pagination
      bus.$emit("end:loading")
      this.showTableStatus = true
    },
    async fetchGames() {
      await this.$store.dispatch("template/fetchGames")
    },
    resetData(responseData) {
      this.dataList = responseData.dataList
      this.pageInfo = responseData.pageInfo
      bus.$emit("end:loading")
    },
    redirection(item) {
      this.$router.push({ name: "setTemplate", params: { id: item.id, }, })
    },
    resetItems(data) {
      this.items = data
    },
  },
}
</script>

<style scoped>
.template_wrapper {
  width: 100%;
}

.title_header {
  margin: 1rem 0;
}

.search_component {
  margin-bottom: 1rem;
}

.filter_write_button_wrapper {
  width: 100%;
  height: 100%;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}
</style>

<template>
  <v-card
    class="mx-auto pa-8 pt-6 pb-3"
    width="720"
    outlined
  >
    <v-container>
      <div
        v-for="(item, name) in cardItemList"
        :key="name"
      >
        <v-row>
          <v-col
            class="ma-0 pa-0"
            cols="12"
            sm="3"
          >
            <span>
              {{ name }}
            </span>
          </v-col>
          <v-col
            class="ma-0 pa-0"
          >
            <v-text-field
              v-model="cardItemList[name]"
              outlined
              dense
              disabled
            />
          </v-col>
        </v-row>
      </div>
      <v-row class="d-flex flex-row justify-end">
        <slot name="buttons" />
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: {
    cardData: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      cardItemList: { ...this.cardData, },
    }
  },
  watch: {
    cardData: {
      deep: true,
      handler(data) {
        this.cardItemList = { ...data, }
      },
    },
  },
}
</script>

<style scoped>
.box {
  width: 720px;
}
</style>

import {
  requestFormsAll,
  requestForms,
  requestForm,
  editFormData,
  removeForm,
  createForm,
} from "../../api/form"
import i18n from "../../plugins/i18n.js"
import bus from "../../utils/bus"

export default {
  namespaced: true,
  actions: {
    async fetchData({ dispatch, }, keywords = {}) {
      try {
        bus.$emit("start:loading")
        const response = await requestForms(keywords)

        if (response.status === 200) {
          return response.data
        }
      } catch (error) {
        dispatch("setError", error, { root: true, })
      }
    },
    async fetchDataAll({ dispatch, }, keywords = {}) {
      try {
        bus.$emit("start:loading")
        const response = await requestFormsAll(keywords)

        if (response.status === 200) {
          return response.data
        }
      } catch (error) {
        dispatch("setError", error, { root: true, })
      }
    },
    async fetchFormDetailData({ dispatch, }, formId) {
      try {
        bus.$emit("start:loading")
        const response = await requestForm(formId)

        if (response.status === 200) {
          return response
        }
      } catch (error) {
        dispatch("setError", error, { root: true, })
      }
    },
    async patchFormData({ dispatch, }, payload) {
      try {
        const response = await editFormData(payload.formId, payload.data, payload.config)

        if (response.status === 200) {
          return response
        }
      } catch (error) {
        dispatch("formErrorHandling", error.response.data)
      }
    },
    async deleteForm({ dispatch, }, formId) {
      try {
        await removeForm(formId)

        return { error: false, }
      } catch (error) {
        dispatch("setError", error, { root: true, })
      }
    },
    async saveForm({ dispatch, }, formData) {
      try {
        const response = await createForm(formData.data)

        response.status = 201

        return response
      } catch (error) {
        dispatch("formErrorHandling", error.response.data)
      }
    },
    formErrorHandling({ dispatch, }, errorResponse) {
      alert(`${i18n.t("form.SECTIONS_TEST_ERROR")} ${Object.values(errorResponse)[0]}`)
    },
  },
}

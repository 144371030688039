export const gameKeys = Object.freeze({
  EMOTICON: "emoticon",
  BALLOON: "balloon",
  GO: "go",
  ALPHABET: "alphabet",
  NUMBER: "number",
  BAR: "bar",
  MONEY1: "money1",
  CARD: "card",
  TOWER: "tower",
  FACE: "face",
})

import http from "./http"

export function requestTemplates(params) {
  return http.get("admin/templates/", { params, })
}

export function requestTemplate(id) {
  return http.get(`admin/templates/${id}/`)
}

export function requestGames() {
  return http.get("admin/games/all/?active=true")
}

export function editTemplateData(templateId, data, config) {
  return http.patch(`admin/template/${templateId}/`, data, config)
}

export function removeTemplate(templateId) {
  return http.delete(`admin/template/${templateId}/`)
}

export function createTemplate(data) {
  return http.post("admin/template/", data)
}

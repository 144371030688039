<template>
  <div>
    <PageTitle :value="title" />
    <div>
      <div
        v-for="(value, name) in userData"
        :key="name"
        class="list"
      >
        <Input
          v-if="inputData[name].type === 'text'"
          v-model="userData[name]"
          :label="name"
          :is-disabled="inputData[name].isDisabled"
        />
        <Checkbox
          v-else-if="inputData[name].type === 'checkbox'"
          v-model="userData[name]"
          :label="name"
        />
        <Select
          v-else-if="inputData[name].type === 'select'"
          v-model="userData[name]"
          :label="name"
          :items="items[name].options"
          :item-value="items[name].itemValue"
        />
      </div>
    </div>
    <div class="footer">
      <v-btn
        color="error"
        @click.stop="$_detail_handleOpen"
      >
        {{ $t("detail.WITHDRAW") }}
      </v-btn>
      <div class="save_btn">
        <SaveButton @click="$_detail_handleSave(userData)" />
      </div>
    </div>
    <Modal
      v-if="isModalShown"
      :title="$t('modal.WARNING')"
      :desc="$t('modal.UNSUBSCRIBE_MESSAGE')"
      @yes="$_detail_handleProceed($_detail_handleDelete)"
      @no="$_detail_handleClose"
    />
  </div>
</template>

<script>
import PageTitle from "@/components/common/headers/PageTitle"
import Input from "@/components/common/formControls/Input"
import Checkbox from "@/components/common/formControls/Checkbox"
import SaveButton from "@/components/common/buttons/SaveButton"
import Select from "@/components/common/formControls/Select"
import Modal from "@/components/common/modals/Modal"
import options from "@/mixins/options"
import detail from "@/mixins/detail"

export default {
  components: {
    PageTitle,
    Input,
    Checkbox,
    SaveButton,
    Select,
    Modal,
  },
  mixins: [detail, options],
  data() {
    return {
      title: this.$t("nav.USER_UNSUB"),
      userData: {
        id: "",
        username: "",
        birthday: "",
        is_female: "",
        funnel: "",
        province: "",
        education: "",
        experience: "",
        position: "",
        industry: "",
        is_active: "",
        date_joined: "",
      },
      inputData: {
        id: {
          value: "",
          type: "text",
          isDisabled: true,
        },
        username: {
          value: "",
          type: "text",
          isDisabled: true,
        },
        birthday: {
          value: "",
          type: "text",
        },
        is_female: {
          value: "",
          type: "checkbox",
        },
        funnel: {
          value: "",
          type: "select",
        },
        province: {
          value: "",
          type: "select",
        },
        education: {
          value: "",
          type: "select",
        },
        experience: {
          value: "",
          type: "select",
        },
        position: {
          value: "",
          type: "select",
        },
        industry: {
          value: "",
          type: "select",
        },
        is_active: {
          value: "",
          type: "checkbox",
        },
        date_joined: {
          value: "",
          type: "text",
        },
      },
    }
  },
  async created() {
    const selectTypeItem = "funnel"

    await this.$_options_setFetchedOptions(selectTypeItem)
    this.inputData.funnel.options = this.items[selectTypeItem].options
  },
}
</script>

<style scoped>
.footer {
  display: flex;
  position: relative;
  margin: 2rem 0;
  width: 100%;
}

.save_btn {
  position: absolute;
  left: calc(50% - 32px);
}
</style>

import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("@/views/Home.vue"),
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("@/views/accounts/Signup.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/accounts/Login.vue"),
  },
  {
    path: "/set/template",
    name: "Template",
    component: () => import("@/views/setTemplate/TemplateList.vue"),
  },
  {
    path: "/set/template/:id",
    name: "setTemplate",
    component: () => import("@/views/setTemplate/setTemplate.vue"),
  },
  {
    path: "/game/create",
    name: "createGame",
    component: () => import("@/views/lookup/games/CreateGame.vue"),
  },
  {
    path: "/game/sets",
    name: "gamesets",
    component: () => import("@/views/lookup/gamesets/Gamesets.vue"),
  },
  {
    path: "/game/set/create",
    name: "createGameset",
    component: () => import("@/views/lookup/gamesets/CreateGameset.vue"),
  },
  {
    path: "/game/set/:id/data",
    name: "gamesetData",
    component: () => import("@/views/lookup/gamesets/gamesetData/Data.vue"),
  },
  {
    path: "/game/set/:id/division",
    name: "gamesetDivision",
    component: () => import("@/views/lookup/gamesets/gamesetData/Division.vue"),
  },
  {
    path: "/game/set/:id/game/:game_key",
    name: "gamesetGame",
    component: () => import("@/views/lookup/gamesets/gamesetData/Game.vue"),
  },
  {
    path: "/game/set/:id",
    name: "gamesetDetail",
    component: () => import("@/views/lookup/gamesets/GamesetDetail.vue"),
  },
  {
    path: "/game/:key/:gamedata_id",
    name: "rawdata",
    component: () => import("@/views/lookup/gamesets/gamesetData/RawData.vue"),
  },
  {
    path: "/game/:id",
    name: "gameDetail",
    component: () => import("@/views/lookup/games/GameDetail.vue"),
  },
  {
    path: "/games",
    name: "games",
    component: () => import("@/views/lookup/games/Games.vue"),
  },
  {
    path: "/funnel",
    name: "Funnel",
    component: () => import("@/views/adminCommon/commonComponents.vue"),
  },
  {
    path: "/criteria",
    name: "Criteria",
    component: () => import("@/views/criteria/Criteria.vue"),
  },
  {
    path: "/criteria/:id",
    name: "setCriteria",
    component: () => import("@/views/criteria/setCriteria.vue"),
  },
  {
    path: "/emailservice",
    name: "EmailService",
    component: () => import("@/views/adminCommon/commonComponents.vue"),
  },
  {
    path: "/fakename",
    name: "FakeName",
    component: () => import("@/views/adminCommon/commonComponents.vue"),
  },
  {
    path: "/survey/:id",
    name: "surveyDetail",
    component: () => import("@/views/lookup/surveys/SurveyDetail.vue"),
  },
  {
    path: "/surveys",
    name: "surveys",
    component: () => import("@/views/lookup/surveys/Surveys.vue"),
  },
  {
    path: "/survey/create",
    name: "createSurvey",
    component: () => import("@/views/lookup/surveys/CreateSurvey.vue"),
  },
  {
    path: "/surveys/:id/data",
    name: "surveyData",
    component: () => import("@/views/lookup/surveys/surveyData/Data.vue"),
  },
  {
    path: "/unsub",
    name: "unsub",
    component: () => import("@/views/lookup/users/Unsub.vue"),
  },
  {
    path: "/approve",
    name: "approve",
    component: () => import("@/views/lookup/users/ApproveCompany.vue"),
  },
  {
    path: "/company",
    name: "company",
    component: () => import("@/views/lookup/users/Company.vue"),
  },
  {
    path: "/person",
    name: "person",
    component: () => import("@/views/lookup/users/Person.vue"),
  },
  {
    path: "/staff",
    name: "staff",
    component: () => import("@/views/lookup/users/Staff.vue"),
  },
  {
    path: "/unsub/:id",
    name: "unsubDetail",
    component: () => import("@/views/lookup/users/UnsubDetail.vue"),
  },
  {
    path: "/company/:id",
    name: "companyDetail",
    component: () => import("@/views/lookup/users/CompanyDetail.vue"),
  },
  {
    path: "/person/:id",
    name: "personDetail",
    component: () => import("@/views/lookup/users/PersonDetail.vue"),
  },
  {
    path: "/staff/:id",
    name: "staffDetail",
    component: () => import("@/views/lookup/users/StaffDetail.vue"),
  },
  {
    path: "/form",
    name: "Form",
    component: () => import("@/views/form/Form.vue"),
  },
  {
    path: "/form/:id",
    name: "setForm",
    component: () => import("@/views/form/SetForm.vue"),
  },
  {
    path: "/auth-code",
    name: "authCode",
    component: () => import("@/views/lookup/authCodes/AuthCodes.vue"),
  }
]

const router = new VueRouter({
  mode: "hash",
  routes,
})

export default router

<template>
  <div>
    <PageTitle
      :value="title"
      :suffix="$t('common.MANAGEMENT')"
    />
    <Input
      :value="`${criteriaData.id}`"
      :is-disabled="true"
      label="id"
    />
    <Input
      v-model="criteriaData.key"
      :is-disabled="false"
      label="key"
    />
    <Input
      v-model="criteriaData.version"
      :is-disabled="true"
      label="version"
    />
    <div
      v-for="(category) in categoryList"
      :key="category"
      class="category_list_wrapper"
    >
      <div class="category_name">
        {{ category }}
      </div>
      <div
        class="criteria_header"
      >
        <span
          v-for="(item,index) in criteriaHeaders"
          :key="index"
          class="header_item "
        >
          {{ item }}
        </span>
      </div>
      <div
        v-for="(key,index) in criteriaAgeArray"
        :key="index"
        class="criteria_item_list"
      >
        <div class="criteria_item_wrapper">
          <div class="criteria_label ">
            {{ key }}
          </div>
          <CriteriaItemForm
            v-model="criteriaData[`${category}_mean`][key]"
          />
          <CriteriaItemForm
            v-model="criteriaData[[`${category}_std`]][key]"
          />
        </div>
      </div>
    </div>
    <Checkbox
      :value="criteriaData.is_active"
      label="is_active"
      @input="setIsActive"
    />
    <Input
      v-model="criteriaData.created_at"
      :is-disabled="true"
      label="created at"
    />
    <div class="criteria_button_wrapepr">
      <SaveButton
        @click="checkKeyValue"
      />
    </div>
    <Notice
      v-if="saveStatus"
      :desc="$t('common.SAVE_COMPLETE')"
    />
  </div>
</template>

<script>
import SaveButton from "@/components/common/buttons/SaveButton"
import CriteriaItemForm from "@/components/criteria/CriteriaItemForm"
import PageTitle from "@/components/common/headers/PageTitle"
import Checkbox from "@/components/common/formControls/Checkbox"
import Input from "@/components/common/formControls/Input"
import Notice from "@/components/common/modals/Notice"
import bus from "../../utils/bus"

export default {
  components: {
    SaveButton,
    CriteriaItemForm,
    PageTitle,
    Checkbox,
    Notice,
    Input,
  },
  data() {
    return {
      criteriaData: {
        id: "",
        key: "",
        version: 1,
        is_active: false,
        created_at: "",
        female_mean: { "0-17": 0, "18-19": 0, "20-24": 0, "25-29": 0, "30-34": 0, "35-39": 0, "40-44": 0, "45-49": 0, "50-54": 0, "55-59": 0, "60-64": 0, "65-999": 0, },
        female_std: { "0-17": 0, "18-19": 0, "20-24": 0, "25-29": 0, "30-34": 0, "35-39": 0, "40-44": 0, "45-49": 0, "50-54": 0, "55-59": 0, "60-64": 0, "65-999": 0, },
        male_mean: { "0-17": 0, "18-19": 0, "20-24": 0, "25-29": 0, "30-34": 0, "35-39": 0, "40-44": 0, "45-49": 0, "50-54": 0, "55-59": 0, "60-64": 0, "65-999": 0, },
        male_std: { "0-17": 0, "18-19": 0, "20-24": 0, "25-29": 0, "30-34": 0, "35-39": 0, "40-44": 0, "45-49": 0, "50-54": 0, "55-59": 0, "60-64": 0, "65-999": 0, },
      },
      criteriaAgeArray: ["0-17", "18-19", "20-24", "25-29", "30-34", "35-39", "40-44", "45-49", "50-54", "55-59", "60-64", "65-999"],
      criteriaHeaders: ["age", "mean", "std"],
      categoryList: ["female", "male"],
      pageTarget: ["cdl", "cpi"],
      saveStatus: false,
      title: this.$t("title.CRITERIA"),
      criteriaId: 0,
    }
  },
  created() {
    this.criteriaId = this.$route.params.id

    if (this.criteriaId !== "new") {
      this.fetchCriteriaData()
    }
  },
  methods: {
    async fetchCriteriaData() {
      const response = await this.$store.dispatch("criteria/fetchCriteriaDetailData", this.criteriaId)

      this.criteriaData = {
        ...this.criteriaData,
        ...JSON.parse(JSON.stringify(response.data)),
      }

      bus.$emit("end:loading")
    },
    setIsActive(state) {
      this.criteriaData.is_active = state
    },
    checkKeyValue() {
      if (!this.criteriaData.key.trim()) {
        alert(this.$i18n.t("criteria.ERROR_BY_KEY"))

        return
      }

      this.saveItem()
    },
    async saveItem() {
      let response

      if (this.criteriaId === "new") {
        response = await this.$store.dispatch("criteria/createCriteriaData", this.criteriaData)
      } else {
        response = await this.$store.dispatch("criteria/editCriteriaData", { id: this.criteriaId, data: this.criteriaData, })
      }

      const errorState = this.checkErrorStatus(response, this.criteriaId)

      if (errorState) {
        return
      }

      this.upToTop()
      this.saveStatus = true
      bus.$emit("end:loading")
      this.moveToCriteria()
    },
    checkErrorStatus(response, workType) {
      if (!(response.status === 400)) {
        return false
      }

      bus.$emit("end:loading")

      if (workType === "new") {
        alert(`${this.$i18n.t("criteria.CREATE_KEY_ERROR")}`)
        this.$router.push({ name: "Criteria", })

        return true
      }

      alert(`${this.$i18n.t("criteria.CREATE_VERSION_ERROR")}`)
      this.$router.push({ name: "Criteria", })

      return true
    },
    moveToCriteria() {
      setTimeout(() => {
        this.$router.push({ name: "Criteria", })
      }, 700)
    },
    async removeSelectedItem() {
      await this.$store.dispatch("criteria/deleteCriteriaData", this.criteriaId)
      this.moveToCriteria()
    },
    upToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      })
    },
    cancel() {
      this.deleteStatus = false
    },
  },
}
</script>

<style scoped>

.category_list_wrapper {
  border: 1px solid;
  margin: 2rem 0;
  padding: 1rem;
  width: 30rem;
  position: relative;
}

.category_list_wrapper:last-child {
  margin: 0;
}

.category_name {
  position: absolute;
  top: -0.8rem;
  width: 4rem;
  text-align: center;
  font-weight: 600;
  background-color: #fff;
}

.criteria_item_wrapper, .criteria_header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 0.5rem;
}

.criteria_label {
  width: 7rem;
  text-align: center;
}

.header_item {
  width: 7rem;
  text-align: center;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.criteria_button_wrapepr {
  width: 100%;
  height: 4rem;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

import http from "./http"

export async function connect() {
  return http.post("accounts/connect/")
}

export async function requestSignup(payload) {
  return http.post("admin/signup/", payload)
}

export async function requestLogin(payload) {
  return http.post("admin/login/", payload)
}

export async function requestLogout() {
  return http.post("accounts/logout/")
}

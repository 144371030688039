<template>
  <div class="text-center">
    <v-card>
      <v-card-title>
        {{ `[${error.status}] ${error.statusText}` }}
      </v-card-title>
      <v-card-text>
        <slot name="body">
          <span style="white-space: pre;">
            {{ error.message }}
          </span>
        </slot>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="handleCloseModal"
        >
          {{ $t("modal.CLOSE") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  computed: {
    error() {
      return this.$store.state.error.error
    },
  },
  methods: {
    handleCloseModal() {
      this.$store.commit("SET_ERROR", { isErrorExists: false, })
    },
  },
}
</script>

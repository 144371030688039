<template>
  <v-app>
    <div id="app">
      <section
        v-if="isKeyExists"
        :class="isNavClosed ? 'closed_side_bar' : 'side_bar'"
      >
        <Nav @toggleNav="toggleNav" />
      </section>
      <section
        :class="[isKeyExists
          ? (isNavClosed ? 'nav_closed_contents_wrapper' : 'contents_wrapper')
          : 'full_contents_wrapper']"
      >
        <router-view />
        <Spinner v-if="dataLoadingStatus" />
      </section>
      <v-dialog
        v-model="isModalShown"
        width="500"
      >
        <Modal />
      </v-dialog>
    </div>
  </v-app>
</template>

<script>
import Nav from "./components/common/Nav.vue"
import setSpinner from "./mixins/setSpinnerEvent.js"
import Spinner from "./components/common/Spinner.vue"
import Modal from "./components/common/Modal.vue"
import { getItem, setItem, clearAllStorage, } from "./utils/storage"

export default {
  components: {
    Nav,
    Spinner,
    Modal,
  },
  mixins: [setSpinner],
  data() {
    return {
      sideBar: null,
      isNavClosed: false,
    }
  },
  computed: {
    isModalShown() {
      return this.$store.state.error.isErrorExists
    },
    isKeyExists() {
      return this.$store.state.admin.adminData.key
    },
  },
  watch: {
    isKeyExists() {
      this.isNavClosed = false
    },
  },
  created() {
    this.setPageSizeObjInLocalStorage()
    this.removeBeforeUnload()
    this.clearAllStorageBeforeLeave()
  },
  beforeMount() {
    if (!this.isKeyExists) {
      this.$router.push({ name: "login", })
    } else {
      this.$store.dispatch("connect")
    }
  },
  beforeDestroy() {
    this.removeBeforeUnload()
  },
  methods: {
    askQuitBrowser() {
      clearAllStorage()
    },
    removeBeforeUnload() {
      window.removeEventListener("beforeunload", this.askQuitBrowser)
    },
    clearAllStorageBeforeLeave() {
      window.addEventListener("beforeunload", this.askQuitBrowser)
    },
    toggleNav() {
      this.isNavClosed = !this.isNavClosed
    },
    async setPageSizeObjInLocalStorage() {
      const pagesizeObj = await getItem("pagesize")

      if (!pagesizeObj) {
        await setItem("pagesize", this.$store.state.pagination.pageSize)

        return
      }

      this.$store.dispatch("pagination/resetPageSize", pagesizeObj)
    },
  },
}
</script>

<style>
@import url('./assets/styles/common/default.css');
@import url('./assets/styles/common/jsonEditor.css');
@import url("./assets/styles/common/v_table.css");

#app {
  width:100%;
  min-height: 100vh;
  display: flex;
}

.side_bar {
  min-height: 100%;
  width: 250px;
}

.closed_side_bar {
  width: 100px;
}

.side_bar.close {
  width: 80px;
  min-width: 80px;
}

.contents_wrapper {
  position: relative;
  width: calc(100vw - 250px);
  min-height: 100%;
  padding: 1rem 3rem;
  min-width: 600px;
}

.nav_closed_contents_wrapper {
  width: calc(100vw - 100px);
  padding: 1rem 3rem;
  min-width: 600px;
}

.full_contents_wrapper {
  width: 100vw;
  min-width: 600px;
}

.router-link-exact-active {
  text-decoration: none;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.pageSize,"item-key":"id","hide-default-footer":""},on:{"click:row":_vm.editItem},scopedSlots:_vm._u([{key:"item.games",fn:function(ref){
var item = ref.item;
return _vm._l((item.games),function(game,index){return _c('span',{key:game.id,staticClass:"game_id"},[_vm._v(" "+_vm._s(game.id)),(_vm.isLastGame(index, item))?_c('span',[_vm._v(",")]):_vm._e()])})}},{key:"item.users",fn:function(ref){
var item = ref.item;
return [_c('TableIcon',{attrs:{"value":_vm.hasUsers(item)}})]}},{key:"item.for_mobile",fn:function(ref){
var item = ref.item;
return [_c('TableIcon',{attrs:{"value":item.for_mobile}})]}},{key:"item.is_public",fn:function(ref){
var item = ref.item;
return [_c('TableIcon',{attrs:{"value":item.is_public}})]}},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [_c('TableIcon',{attrs:{"value":item.is_active}})]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.created_at.slice(0,10))+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
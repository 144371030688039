<template>
  <v-text-field
    :value="value"
    :label="label"
    :disabled="isDisabled"
    @input="handleInput"
  />
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number],
      default: "",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleInput(value) {
      this.$emit("input", value)
    },
  },
}
</script>

<template>
  <div>
    <PageTitle :value="title" />
    <div class="padding" />
    <ul>
      <li class="li">
        <div class="thumbnail_input_wrapper">
          <Uploader @set-image-id="handleSetImageId" />
        </div>
      </li>
      <li
        v-for="(value, name) in inputData"
        :key="name"
        class="li"
      >
        <div
          v-if="inputData[name].type === 'text'"
          class="input_wrapper"
        >
          <Input
            v-model="gamesetData[name]"
            :label="name"
            :is-disabled="inputData[name].isDisabled"
          />
        </div>
        <div v-else-if="inputData[name].type === 'checkbox'">
          <Checkbox
            v-model="gamesetData[name]"
            :label="name"
          />
        </div>
        <div v-else-if="inputData[name].type === 'select'">
          <Select
            v-model="gamesetData[name]"
            :label="name"
            :items="inputData[name].options"
            :item-value="inputData[name].itemValue"
          />
        </div>
      </li>
    </ul>
    <div class="btn_wrapper">
      <v-btn @click="handleCreate">
        {{ $t("detail.SAVE") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/common/headers/PageTitle"
import Uploader from "@/components/inputs/image/Uploader"
import Input from "@/components/common/formControls/Input"
import Checkbox from "@/components/common/formControls/Checkbox"
import Select from "@/components/common/formControls/Select"
import { createGameset, } from "@/api/lookup"
import options from "@/mixins/options"
import { formatDate, } from "@/utils/format"

export default {
  components: {
    PageTitle,
    Input,
    Uploader,
    Checkbox,
    Select,
  },
  mixins: [options],
  data() {
    const date = new Date()
    const currentDate = formatDate(date)

    return {
      title: this.$t("gamesets.CREATE_TITLE"),
      gamesetData: {
        thumbnail: null,
        template: null,
        name: "",
        description: "",
        divisions: "",
        survey: null,
        max_entry: null,
        valid_from: currentDate,
        valid_until: currentDate,
        is_public: false,
        for_study: false,
        show_selfscore: false,
        code_required: false,
        tel_required: false,
        email_required: false,
      },
      inputData: {
        template: {
          type: "select",
          placeholder: this.$t("gamesets.TEMPLATE_PLACEHOLDER"),
          options: [],
        },
        name: {
          type: "text",
        },
        description: {
          type: "text",
        },
        divisions: {
          type: "text",
        },
        survey: {
          type: "select",
          placeholder: this.$t("gamesets.SURVEY_PLACEHOLDER"),
          options: [],
        },
        max_entry: {
          type: "text",
        },
        valid_from: {
          type: "text",
          placeholder: this.$t("gamesets.DATE_PLACEHOLDER"),
        },
        valid_until: {
          type: "text",
          placeholder: this.$t("gamesets.DATE_PLACEHOLDER"),
        },
        is_public: {
          type: "checkbox",
        },
        for_study: {
          type: "checkbox",
        },
        show_selfscore: {
          type: "checkbox",
        },
        code_required: {
          type: "checkbox",
        },
        tel_required: {
          type: "checkbox",
        },
        email_required: {
          type: "checkbox",
        },
      },
    }
  },
  async created() {
    for (const key in this.inputData) {
      if (this.inputData[key].type !== "select") {
        continue
      }

      await this.$_options_setFetchedOptions(key)

      this.inputData[key].options = this.items[key].options
    }
  },
  methods: {
    handleSetImageId(imageId) {
      if (!imageId) {
        this.gamesetData.thumbnail = null

        return
      }

      if (this.gamesetData.thumbnail) {
        this.gamesetData.thumbnail.id = imageId
      } else {
        this.gamesetData.thumbnail = {
          id: imageId,
        }
      }
    },
    async handleCreate() {
      const data = { ...this.gamesetData, }

      data.divisions = data.divisions.split(",")
        .map((division) => division.trim())

      try {
        await createGameset(data)
        this.$router.push({ name: "gamesets", })
      } catch (error) {
        this.$store.dispatch("setError", error)
      }
    },
  },
}
</script>

<style scoped>
.padding {
  height: 1rem;
}

.li {
  list-style: none;
  display: flex;
}

.thumbnail_input_wrapper {
  width: 70%;
  margin-bottom: 12px;
}

.input_wrapper {
  width: 70%;
}

.btn_wrapper {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
}
</style>

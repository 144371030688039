<template>
  <div class="wrapper">
    <h1 class="title_wrapper">
      {{ gameTitle }}
    </h1>
    <div class="card_wrapper">
      <GamesetCard
        :card-data="cardData"
      >
        <template #buttons>
          <Excel
            v-if="gameKey === 'detection'"
            class="mr-4"
            :is-disabled="!gameset.is_completed"
            :title="$t('lookup.EXPORT_DETECTION_STUDY')"
            @click="fetchExcelFile('detectionStudy')"
          />
          <Excel
            :is-disabled="!gameset.is_completed"
            :title="$t('lookup.EXPORT_ALL_RAWDATA')"
            @click="() => fetchExcelFile('allRawData')"
          />
        </template>
      </GamesetCard>
    </div>
    <div class="search_wrapper">
      <Search :label="$t('lookupPlaceholder.DEFAULT')" />
    </div>
    <v-app>
      <FilterList />
      <div class="checkbox_wrapper">
        <Checkbox
          :value="hideOutlier"
          @input="handleUpdateCheckbox"
        />
        <span>
          {{ $t("gamesets.HIDE_OUTLIER") }}
        </span>
      </div>
      <v-data-table
        v-if="dataList.length && gameHeaders"
        class="elevation-1"
        dense
        :headers="[...headers, ...gameHeaders]"
        :items="dataList"
        :options.sync="sortOptions"
        :items-per-page="20"
        :hide-default-footer="true"
        :server-items-length="dataList.length"
      >
        <template
          v-for="header in gameHeaders"
          #[`item.${header.value}`]="{ item }"
        >
          <div
            :key="header.text"
            :class="item.summary[`${item.summary.key}_percentile`] === -1 && 'bg_color'"
          >
            {{ formattedValue(item.summary[header.text]) }}
          </div>
        </template>
        <template #[`item.user.username`]="{ item }">
          <div
            class="link"
            @click="handleRedirect('rawdata', item)"
          >
            {{ item.user.username }}
          </div>
        </template>
      </v-data-table>
      <Pagination />
      <Excel @click="fetchExcelFile" />
    </v-app>
  </div>
</template>

<script>
import sort from "@/mixins/sort"
import table from "@/mixins/table"
import excel from "@/mixins/excel"
import param from "@/mixins/param"
import GamesetCard from "@/components/gameset/GamesetCard"
import Checkbox from "@/components/common/formControls/Checkbox"
import { MENU, CATEGORY, } from "@/constants/mapVarNames"
import {
  getAllRawData,
  getExcelDataByGamesetId,
  getDetectionStudyByGamesetId,
} from "@/api/exportExcel"

export default {
  components: {
    Checkbox,
    GamesetCard,
  },
  mixins: [sort, excel, table, param],
  data() {
    const basePropsHeader = [
      {
        class: "table_header_xs",
        text: this.$t("headers.ID"),
        value: "id",
        sortable: false,
      },
      {
        class: "table_header_xs",
        text: "username",
        value: "user.username",
        sortable: false,
      },
      {
        class: "table_header_sm",
        text: "name",
        value: "user.name",
        sortable: false,
      },
      {
        class: "table_header_sm",
        text: "division",
        value: "division",
        sortable: false,
      },
      {
        class: "table_header_xs",
        text: "age",
        value: "user.age",
        sortable: false,
      },
      {
        class: "table_header_xs",
        text: "gender",
        value: "user.gender",
        sortable: false,
      },
      {
        class: "table_header_sm",
        text: "experience",
        value: "user.experience",
        sortable: false,
      },
      {
        class: "table_header_sm",
        text: "position",
        value: "user.position",
        sortable: false,
      },
      {
        class: "table_header_sm",
        text: "funnel",
        value: "user.funnel",
        sortable: false,
      },
      {
        class: "table_header_sm",
        text: "education",
        value: "user.education",
        sortable: false,
      },
      {
        class: "table_header_sm",
        text: "elapsed_time",
        value: "summary.elapsed_time",
        sortable: false,
      },
      {
        class: "table_header_sm",
        text: "device",
        value: "summary.device",
        sortable: false,
      },
      {
        class: "table_header_sm",
        text: "os",
        value: "summary.os",
        sortable: false,
      },
      {
        class: "table_header_sm",
        text: "browser",
        value: "summary.browser",
        sortable: false,
      }
    ]

    return {
      gameTitle: null,
      gamesetId: this.$route.params.id,
      gameKey: this.$route.params.game_key,
      filterItems: {
        division: null,
      },
      hideOutlier: false,
      headers: [...basePropsHeader],
      gameHeaders: null,
      sortOptions: {},
    }
  },
  computed: {
    gameset() {
      return this.$store.state.lookup.gamesets.currentItem
    },
    cardData() {
      const gameset = `${this.gameset.id} - ${this.gameset.name}`
      let company_name = ""
      let username = ""

      if (this.gameset.user) {
        company_name = this.gameset.user.company_name
        username = this.gameset.user.username
      }

      return {
        company_name: company_name,
        user: username,
        gameset: gameset ? gameset : "",
        template: gameset ? gameset : "",
      }
    },
  },
  watch: {
    sortOptions: {
      handler(value) {
        if (value.sortBy[0]) {
          this.$_sort_handleSort(value.sortBy[0])
        }
      },
      deep: true,
    },
  },
  async created() {
    this.$store.commit("SET_LOOKUP_CATEGORY", { menu: MENU.gamesets, category: CATEGORY.gamedata, })
    this.$store.commit("SET_SORT", null)
    this.$store.commit("SET_HIDE_OUTLIER", false)
    this.$store.commit("SET_CURRENT_GAME", this.gameKey)
    this.$store.dispatch("setFilterItems", this.filterItems)
    await this.$store.dispatch("fetchLookupData")
    this.handleSetGameTitle()

    if (this.dataList.length) {
      this.handleSetGameProps()
    }
  },
  methods: {
    handleSetGameTitle() {
      const games = this.gameset.template.games

      for (let i = 0; i < games.length; i++) {
        if (games[i].key === this.gameKey) {
          this.gameTitle = games[i].name

          return
        }
      }
    },
    handleSetGameProps() {
      const summary = this.dataList[0].summary
      const metaHeaders = {
        key: true,
        browser: true,
        device: true,
        os: true,
        elapsed_time: true,
      }

      const isGameHeader = (key) => !metaHeaders[key]

      this.gameHeaders = Object.keys(summary)
        .filter(isGameHeader)
        .map((gameProp) => ({
          text: gameProp,
          value: `summary.${gameProp}`,
          sortable: gameProp.includes("percentile") ? true : false,
          class: gameProp.includes("percentile") ? "table_header_lg" : "table_header_xs",
        }))
    },
    handleRedirect(routeName, item) {
      const gamedataIndex = this.dataList.findIndex((element) => element.id === item.id)

      this.$store.commit("SET_CURRENT_GAMEDATA_INDEX", gamedataIndex)
      this.$router.push({
        name: routeName,
        params: {
          gamedata_id: item.id,
          key: this.gameKey,
        },
      })
    },
    handleUpdateCheckbox(isChecked) {
      this.hideOutlier = isChecked
      this.$store.commit("SET_HIDE_OUTLIER", isChecked)
      this.$store.dispatch("fetchLookupData")
    },
    formattedValue(value) {
      return Array.isArray(value) ? value.join(",") : value
    },
    async fetchExcelFile(excelFileType) {
      try {
        const params = this.$_param_createParams()
        let response
        let fileName

        if (excelFileType === "detectionStudy") {
          response = await getDetectionStudyByGamesetId({ gamesetId: this.gameset.id, })
          fileName = excelFileType
        } else if (excelFileType === "allRawData") {
          response = await getAllRawData({ gamesetId: this.gameset.id, params, })
          fileName = `${this.gameKey}_allrawdata`
        } else {
          response = await getExcelDataByGamesetId({
            gamesetId: this.gameset.id,
            category: CATEGORY.gamedata,
            params,
          })
          fileName = this.gameKey
        }

        this.$_excel_downloadFile(response, fileName)
      } catch (error) {
        this.$store.dispatch("setError", error)
      }
    },
  },
}
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card_wrapper {
  align-self: flex-start;
  margin-bottom: 1em;
}

.title_wrapper {
  margin: 1rem 0;
}

.search_wrapper {
  width: 720px;;
}

.checkbox_wrapper {
  display: flex;
  align-items: center;
}

.header {
  background: black;
  color: white;
  font-weight: bold;
  padding: 0.4em;
  padding-left: 1em;
}

.header_text {
  font-size: 0.9rem;
}

.table_header_sm {
  padding: 0.4em;
  min-width: 120px;
}

.table_header_md {
  padding: 0.4em;
  min-width: 160px;
}

.table_header_lg {
  padding: 0.4em;
  min-width: 200px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding-left: 1rem !important;
  text-align: center;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  background-color: #333 !important;
  color: #fff;
}

.v-data-table > .v-data-table__wrapper > table {
  padding: 12px !important;
}

.theme--light.v-data-table .v-data-footer {
  border-top: 0 !important;
}

.link:hover {
  color: #1976d2;
  text-decoration: underline;
}

.bg_color {
  position: relative;
  left: -16px;
  background: red;
  color: white;
  font-weight: bolder;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 16px;
  width: calc(100% + 32px);
}

.row {
  display: flex;
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
}
</style>

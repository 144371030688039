<template>
  <div class="create_modal_wrapper">
    <v-card
      elevation="3"
      class="pa-4 rounded-lg"
      width="300px"
      min-height="200px"
    >
      <v-card-title class="text-h5">
        {{ title }} {{ $t('common.CREATE') }}
      </v-card-title>
      <v-text-field
        v-model="name"
        type="text"
        class="input_box"
        :placeholder="$t('common.TYPE_NAME')"
        autofocus
        @keyup.enter="createNewItem"
      />
      <v-card-actions class="d-flex justify-space-between">
        <v-btn
          class="text-button"
          @click="createNewItem"
        >
          {{ $t('common.CREATE') }}
        </v-btn>
        <v-btn
          color="error"
          class="text-button"
          @click="cancel"
        >
          {{ $t('modal.NO') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    target: {
      type: Array,
      default: () => [],
    },
    createUrl: {
      type: String,
      default: "",
    },
    storeUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      name: "",
    }
  },
  methods: {
    async createNewItem() {
      if (!this.name.trim()) {
        return
      }

      const response = await this.$store.dispatch(this.createUrl, { url: this.storeUrl, data: { name: this.name, }, })

      if (response.status === 201) {
        this.$emit("initialize")
        this.$emit("closeModal")
      }
    },
    cancel() {
      this.$emit("closeModal")
    },
  },
}
</script>

<style scoped>
.create_modal_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.create_modal_inner {
  width: 300px;
  height: 250px;
  background-color: #fff;
  border-radius: 0.2rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.button_wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.button {
  margin: 0 .5rem;
  width: 3rem;
  height: 2rem;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.create:hover {
  background-color: rgba(17, 109, 230, 0.3);
}

.cancel:hover {
  background-color: rgba(230, 62, 62, 0.3);
}
</style>

<template>
  <v-btn
    :class="['btn', 'white--text']"
    color="green darken-2"
    :disabled="isDisabled"
    @click="handleClick"
  >
    <v-icon>
      mdi-microsoft-excel
    </v-icon>
    {{ title }}
  </v-btn>
</template>

<script>
import i18n from "@/plugins/i18n"

export default {
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: i18n.t("lookup.EXPORT_EXCEL"),
    },
  },
  methods: {
    handleClick() {
      this.$emit("click")
    },
  },
}
</script>

<style scoped>
.btn {
  width: fit-content;
  align-self: flex-end;
}
</style>

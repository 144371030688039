export const mapOptionName = Object.freeze({
  gender: "options.GENDER",
  province: "options.PROVINCE",
  funnel: "options.FUNNEL",
  education: "options.EDUCATION",
  experience: "options.EXPERIENCE",
  position: "options.POSITION",
  industry: "options.BUSINESS_LINE",
  is_active: "options.IS_ACTIVE",
  status: "options.STATUS",
  for_study: "options.FOR_STUDY",
  for_mobile: "options.FOR_MOBILE",
  division: "options.DIVISION",
  used: "options.USED",
  success: "options.SUCCESS",
})

export const mapQueryFieldName = Object.freeze({
  is_active: "active",
  for_study: "study",
  for_mobile: "mobile",
})

export const mapErrorMessage = Object.freeze({
  default: "error.DEFAULT",
  username: "error.validation.USERNAME",
  password: "error.validation.PASSWORD",
  name: "error.validation.NAME",
  signup_key: "error.validation.SIGNUP_KEY",
  non_field_errors: "error.validation.LOGIN_FAILED",
})

export const MENU = Object.freeze({
  users: "users",
  games: "games",
  gamesets: "gamesets",
  surveys: "surveys",
  authCodes: "authCodes",
})

export const CATEGORY = Object.freeze({
  unsub: "unsub",
  staff: "staff",
  person: "person",
  company: "company",
  approve: "approve",
  game: "game",
  gameset: "gameset",
  division: "division",
  data: "data",
  gamedata: "gamedata",
  rawdata: "rawdata",
  survey: "survey",
  authCode: "authCode",
})

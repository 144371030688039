<template>
  <div class="setTemplate_wrapper">
    <PageTitle
      :value="title"
      :suffix="$t('common.MANAGEMENT')"
    />
    <div class="setTemplate_elem_wrapper">
      <div class="template_info_wrapper">
        <UploadImage
          v-if="!isCreateTemplate"
          ref="uploadThumbnail"
          class="template_upload_thumbnail"
          :template-id="templateId"
          :thumbnail="templateData.thumbnail"
          :thumbnail-has-loaded="thumbnailHasLoaded"
        />
        <div class="template_info">
          <Input
            :value="`${templateData.id}`"
            :is-disabled="true"
            label="id"
          />
          <Input
            v-model="templateData.name"
            :is-disabled="false"
            label="name"
          />
          <Input
            v-model="templateData.eet"
            :is-disabled="false"
            label="eet"
          />
        </div>
      </div>
      <DragAndDrop
        ref="draganddrop"
        :template-data="templateData"
        :game-data="gameData"
        @setTemplateGames="setTemplateGames"
      />
      <Input
        v-model="templateData.description"
        :is-disabled="false"
        label="description"
      />
      <UserSearchAndSelect
        desc="users"
        :users="templateData.users"
        @setUsers="setUsers"
      />
      <Select
        :value="templateData.form"
        label="Form"
        item-value="id"
        :items="formsDataList"
        @input="selectedForms"
      />
      <Input
        v-model="templateData.scores_person"
        :is-disabled="false"
        :label="$i18n.t('template.PERSON_RESULT_TEMPLATE')"
      />
      <Input
        v-model="templateData.scores_company"
        :is-disabled="false"
        :label="$i18n.t('template.COMPANY_RESULT_TEMPLATE')"
      />
      <Checkbox
        :value="templateData.for_mobile"
        label="for_mobile"
        @input="setForMobile"
      />
      <Checkbox
        :value="templateData.is_active"
        label="is_active"
        @input="setIsActive"
      />
      <Checkbox
        :value="templateData.is_public"
        label="is_public"
        @input="setIsPublic"
      />
      <div class="button_wrapper">
        <DeleteButton
          @click="deleteTemplate"
        />
        <div class="save_btn_wrapper">
          <SaveButton
            @click="saveTemplate"
          />
        </div>
      </div>
      <Modal
        v-if="deleteStatus"
        key="delete modal"
        :title="$i18n.t('common.DELETE_WARNING1')"
        :desc="$i18n.t('common.DELETE_WARNING2')"
        @yes="removeSelectedItem"
        @no="cancel"
      />
      <Notice
        v-if="saveStatus"
        :desc="$t('common.SAVE_COMPLETE')"
      />
    </div>
  </div>
</template>

<script>
import UserSearchAndSelect from "@/components/setTemplate/UserSearchAndSelect.vue"
import SaveButton from "@/components/common/buttons/SaveButton.vue"
import DeleteButton from "@/components/common/buttons/DeleteButton.vue"
import DragAndDrop from "@/components/setTemplate/DragAndDrop.vue"
import PageTitle from "@/components/common/headers/PageTitle.vue"
import UploadImage from "@/components/common/UploadImage.vue"
import Notice from "@/components/common/modals/Notice.vue"
import Checkbox from "@/components/common/formControls/Checkbox.vue"
import Input from "@/components/common/formControls/Input.vue"
import Select from "@/components/common/formControls/Select.vue"
import Modal from "@/components/common/modals/Modal.vue"
import bus from "@/utils/bus"

export default {
  components: {
    SaveButton,
    DeleteButton,
    UserSearchAndSelect,
    PageTitle,
    UploadImage,
    DragAndDrop,
    Checkbox,
    Notice,
    Input,
    Select,
    Modal,
  },
  data() {
    return {
      templateId: null,
      templateData: {
        id: "",
        name: "",
        eet: 0,
        users: [],
        description: "",
        scores_person: "",
        scores_company: "",
        for_mobile: false,
        is_active: false,
        is_public: false,
        games: [],
        thumbnail: null,
        form: null,
      },
      title: this.$t("title.TEMPLATE"),
      deleteStatus: false,
      saveStatus: false,
      pageTarget: ["tdl", "tpi"],
      gameData: [],
      thumbnailHasLoaded: false,
      formsDataList: [],
    }
  },
  computed: {
    isCreateTemplate() {
      return this.templateId === "new"
    },
  },
  beforeMount() {
    this.templateId = this.$route.params.id

    this.fetchGamesData()
    this.fetchForms()
  },
  methods: {
    async fetchGamesData() {
      const response = await this.$store.dispatch("template/fetchGames")

      this.gameData = response

      if (!this.isCreateTemplate) {
        this.fetchTemplateDetailData()

        return
      }

      bus.$emit("end:loading")
      this.$refs.draganddrop.setInitialTemplateData(this.gameData)
    },
    async fetchTemplateDetailData() {
      const response = await this.$store.dispatch("template/fetchTemplateDetailData", this.templateId)

      if (response.status === 200) {
        this.setTemplateData(response)
        bus.$emit("end:loading")
      }
    },
    setTemplateData(response) {
      const scores_company = response.data.data.scores_company ? response.data.data.scores_company.join(",") : ""
      const scores_person = response.data.data.scores_person ? response.data.data.scores_person.join(",") : ""

      this.templateData = {
        ...this.templateData,
        ...JSON.parse(JSON.stringify(response.data.data)),
        scores_person,
        scores_company,
      }

      this.$refs.draganddrop.setInitialTemplateData(this.gameData, this.templateData)
      this.thumbnailHasLoaded = true
    },
    setUsers(usersArray) {
      this.templateData.users = usersArray
    },
    saveTemplate() {
      this.$refs.draganddrop.submit()
    },
    async sendRequest(data) {
      let Url
      const requestData = {
        templateId: this.templateId,
        data,
      }

      if (this.isCreateTemplate) {
        Url = "template/saveTemplate"
      } else {
        Url = "template/patchTemplateData"
      }

      const response = await this.$store.dispatch(Url, requestData)

      if (response.status === 200 || response.status === 201) {
        this.saveStatus = true
        this.moveToTemplate()
      }
    },
    async fetchForms() {
      const params = { active: true, }
      const response = await this.$store.dispatch("form/fetchDataAll", params)

      this.setFormsDataList(response)
    },
    setFormsDataList(response) {
      if (response && response.data) {
        this.formsDataList = response.data.map((data) => {
          return  { ...data, text: `${data.id} - ${data.name}`, }
        })
      }
    },
    selectedForms(selectedForm) {
      this.templateData.form = { id: selectedForm, }
    },
    moveToTemplate() {
      setTimeout(() => {
        this.$router.push({ name: "Template", })
      }, 700)
    },
    getSurveyGameId() {
      let surveyGameId = null

      this.gameData.forEach((game) => {
        if (game.key === "form") {
          surveyGameId = game.id

          return
        }
      })

      return surveyGameId
    },
    hasSurveyGame(games) {
      const surveyGameId = this.getSurveyGameId()
      let hasSurveyGame = false

      games.forEach((game) => {
        if (game.id === surveyGameId) {
          hasSurveyGame = true

          return
        }
      })

      return hasSurveyGame
    },
    isFormIdEssential(games) {
      const hasSurveyGame = this.hasSurveyGame(games)

      if (hasSurveyGame && this.templateData.form === null) {
        alert(this.$i18n.t("template.FROM_ESSENTIAL_ERROR_MESSAGE"))

        return
      }

      if (!hasSurveyGame) {
        this.templateData.form = null
      }

      this.setRequestData()
    },
    setTemplateGames(games) {
      this.templateData.games = games
      this.isFormIdEssential(games)
    },
    setRequestData() {
      const scores_company = this.templateData.scores_company === "" ? [] : this.templateData.scores_company.split(",")
      const scores_person = this.templateData.scores_person === "" ? [] : this.templateData.scores_person.split(",")
      const requestTemplateData = {
        name: this.templateData.name,
        eet: this.templateData.eet,
        game_list: this.templateData.games,
        description: this.templateData.description,
        scores_person,
        scores_company,
        for_mobile: this.templateData.for_mobile,
        is_active: this.templateData.is_active,
        is_public: this.templateData.is_public,
        form: this.templateData.form,
      }

      if (!requestTemplateData.name.trim()) {
        alert(this.$t("template.NAME_ERROR"))

        return
      }

      this.upToTop()
      this.sendRequest(requestTemplateData)
    },
    upToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      })
    },
    setIsActive(state) {
      this.templateData.is_active = state
    },
    setIsPublic(state) {
      this.templateData.is_public = state
    },
    setForMobile(state) {
      this.templateData.for_mobile = state
    },
    deleteTemplate() {
      if (this.isCreateTemplate) {
        return
      }

      this.upToTop()
      this.deleteStatus = true
    },
    cancel() {
      this.deleteStatus = false
    },
    async removeSelectedItem() {
      const response = await this.$store.dispatch("template/deleteTemplate", this.templateId)

      if (response.error) {
        this.cancel()
        alert(this.$i18n.t("template.DELETE_ERROR_MESSAGE"))

        return
      }

      this.deleteStatus = false
      this.moveToTemplate()
    },
  },
}
</script>

<style scoped>
.setTemplate_wrapper{
  width: 100%;
  height: 100%;
}

.title_header {
  margin: 1rem 0 1.5rem;
}

.setTemplate_elem_wrapper {
  width: 100%;
}

.template_info_wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.template_info {
  width: 100%;
}

.template_upload_thumbnail {
  width: 135px;
  flex-shrink: 0;
  height: 170px;
  margin-right: 1rem;
}

.button_wrapper {
  position: relative;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
}

.save_btn_wrapper {
  position: absolute;
  left: calc(50% - 32px);
}

.check_form_component {
  margin-bottom: .5rem;
}
</style>

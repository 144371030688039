<template>
  <div class="wrapper">
    <div
      v-if="label"
      class="label_wrapper"
    >
      <Label :label-text="label" />
    </div>
    <div class="editor_wrapper">
      <span class="editor_title">
        {{ $t("surveys.JSON_PLACEHOLDER") }}
      </span>
      <codemirror
        id="editor"
        :value="value"
        :options="cmOptions"
        @input="handleUpdateValue"
      />
    </div>
    <div class="result_container">
      <v-btn @click="handleClickButton">
        {{ $t("lookup.VALIDATE_JSON") }}
      </v-btn>
      <div class="result_wrapper">
        <pre class="status_wrapper">
          {{ statusMessage }}
        </pre>
      </div>
    </div>
  </div>
</template>

<script>
import Label from "@/components/inputs/Label.vue"
import { codemirror, } from "vue-codemirror"
import "codemirror/addon/search/search"
import "codemirror/addon/search/searchcursor"
import "codemirror/addon/search/jump-to-line"
import "codemirror/addon/dialog/dialog.js"
import "codemirror/addon/dialog/dialog.css"
import jsonlint from "@/utils/jsonlint"

export default {
  components: {
    Label,
    codemirror,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      editor: null,
      isValid: false,
      statusMessage: "",
      cmOptions: {
        tabSize: 4,
        mode: "application/json",
        lineNumbers: true,
        lint: true,
      },
    }
  },
  methods: {
    handleClickButton() {
      try {
        jsonlint.parse(this.value)
        this.isValid = true
        this.statusMessage = this.$t("lookup.VALID_JSON")
      } catch (error) {
        this.isValid = false
        this.statusMessage = error.message
      }
    },
    handleUpdateValue(value) {
      this.$emit("input", value)
    },
  },
}
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
}

.label_wrapper {
  width: 128px;
}

.editor_wrapper {
  margin-bottom: 1rem;
  border: 1px solid rgb(185, 185, 185);
  padding: 1rem;
  border-radius: 4px;
}

.editor_title {
  width: 100%;
  display: inline-block;
  border-bottom: 1px solid rgb(185, 185, 185);
}

#editor {
  padding-top: 0;
}

.result_wrapper {
  width: 100%;
  font-size: 16px;
  margin-top: 16px;
}

.status_wrapper {
  font-family: Pretendard;
  background: chartreuse;
  background: #efefef;
  padding: 16px;
}
</style>

import * as accountsApi from "../../api/accounts"
import router from "../../router"
import { clearStorage, } from "../../utils/storage"

export const initialState = {
  adminData: {
    id: null,
    key: null,
    username: null,
  },
  loginError: null,
}

export const state = () => ({
  ...initialState,
})

export const mutations = {
  SET_USER(state, payload) {
    state.adminData = { ...payload, }
  },
  LOGOUT_USER(state) {
    state.adminData = { ...initialState.adminData, }
    state.loginError = null
  },
  SET_LOGIN_ERROR(state, payload) {
    state.adminData.loginError = payload
  },
}

export const actions = {
  async connect({ commit, }) {
    try {
      await accountsApi.connect()

    } catch (error) {
      clearStorage()
      commit("LOGOUT_USER")
      router.push({ name: "login", })
    }
  },
  setLoginError({ commit, }) {
    commit("SET_LOGIN_ERROR")
  },
  async requestLogin({ commit, dispatch, }, payload) {
    try {
      const response = await accountsApi.requestLogin(payload)

      router.push({ name: "Home", })
      commit("SET_USER", response.data.data)
    } catch (error) {
      dispatch("setError", error, { root: true, })
    }
  },
  async requestLogout({ commit, }) {
    try {
      await accountsApi.requestLogout()
    } catch (error) {
      if (error.response.statusText === "Unauthorized") {
        throw new Error(error)
      }
    } finally {
      commit("LOGOUT_USER")
      clearStorage()

      if (router.currentRoute.name !== "login") {
        router.push({ name: "login", })
      }
    }
  },
}

<template>
  <div>
    <PageTitle :value="title" />
    <div>
      <div
        v-for="(value, name) in userData"
        :key="name"
      >
        <Input
          v-if="inputData[name].type === 'text'"
          v-model="userData[name]"
          :label="name"
          :is-disabled="inputData[name].isDisabled"
        />
        <Checkbox
          v-else-if="inputData[name].type === 'checkbox'"
          v-model="userData[name]"
          :label="name"
        />
        <Select
          v-else-if="inputData[name].type === 'select'"
          v-model="userData[name]"
          :label="name"
          :options="items[name].options"
          :selected-value="userData[name]"
        />
      </div>
    </div>
    <div class="footer">
      <v-btn
        color="error"
        @click="$_detail_handleOpen"
      >
        {{ $t("detail.WITHDRAW") }}
      </v-btn>
      <div class="save_btn">
        <SaveButton @click="handleClickSave" />
      </div>
    </div>
    <Modal
      v-if="isInputWarningModalShown && inputWarning === 'is_admin'"
      :title="$t('modal.WARNING')"
      :desc="$t('users.MODIFY_ADMIN_WARNING')"
      @yes="handleSave"
      @no="closeInputWarningModal"
    />
    <Modal
      v-if="isInputWarningModalShown && inputWarning === 'is_staff'"
      :title="$t('modal.WARNING')"
      :desc="$t('users.MODIFY_ADMIN_WARNING')"
      @yes="handleSave"
      @no="closeInputWarningModal"
    />
    <Modal
      v-if="isModalShown"
      :title="$t('modal.WARNING')"
      :desc="$t('modal.UNSUBSCRIBE_MESSAGE')"
      @yes="$_detail_handleProceed($_detail_handleDelete)"
      @no="$_detail_handleClose"
    />
  </div>
</template>

<script>
import PageTitle from "@/components/common/headers/PageTitle"
import Input from "@/components/common/formControls/Input"
import Checkbox from "@/components/common/formControls/Checkbox"
import Select from "@/components/common/formControls/Select"
import SaveButton from "@/components/common/buttons/SaveButton"
import Modal from "@/components/common/modals/Modal"
import options from "@/mixins/options"
import detail from "@/mixins/detail"

export default {
  components: {
    PageTitle,
    Input,
    SaveButton,
    Checkbox,
    Select,
    Modal,
  },
  mixins: [detail, options],
  data() {
    return {
      title: this.$t("nav.USER_STAFF"),
      userData: {
        id: "",
        username: "",
        name: "",
        is_active: "",
        is_staff: "",
        is_superuser: "",
        is_admin: "",
        company_approved: "",
        date_joined: "",
      },
      inputData: {
        id: {
          value: "",
          type: "text",
          isDisabled: true,
        },
        username: {
          value: "",
          type: "text",
          isDisabled: true,
        },
        name: {
          value: "",
          type: "text",
        },
        is_active: {
          value: "",
          type: "checkbox",
        },
        is_staff: {
          value: "",
          type: "checkbox",
        },
        is_superuser: {
          value: "",
          type: "checkbox",
        },
        is_admin: {
          value: "",
          type: "checkbox",
        },
        company_approved: {
          value: "",
          type: "checkbox",
        },
        date_joined: {
          value: "",
          type: "text",
        },
      },
      isInputWarningModalShown: false,
    }
  },
  computed: {
    inputWarning() {
      if (!this.userData.is_admin) {
        return "is_admin"
      }

      if (!this.userData.is_staff) {
        return "is_staff"
      }

      return null
    },
  },
  methods: {
    handleClickSave() {
      if (this.inputWarning) {
        this.isInputWarningModalShown = true

        return
      }

      this.$_detail_handleSave(this.userData)
    },
    closeInputWarningModal() {
      this.isInputWarningModalShown = false
    },
    handleSave() {
      this.closeInputWarningModal()
      this.$_detail_handleSave(this.userData)
    },
  },
}
</script>

<style scoped>
.footer {
  display: flex;
  position: relative;
  margin: 2rem 0;
  width: 100%;
}

.save_btn {
  position: absolute;
  left: calc(50% - 32px);
}
</style>

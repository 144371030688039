<template>
  <div class="common_components_wrapper">
    <PageTitle
      :value="title"
      :suffix="$t('common.MANAGEMENT')"
    />
    <div class="create_button_position">
      <CreateButton
        class="createButton"
        @click="openModal"
      />
    </div>
    <div
      v-show="showTable"
      class="v-table-custom"
    >
      <v-data-table
        :headers="tableHeaders"
        :items="tableData"
        :items-per-page="pageSize"
        item-key="id"
        class="elevation-1"
        hide-default-footer
      >
        <template #[`item.name`]="{ item }">
          <Input
            v-model="item.name"
            @input="changed"
          />
        </template>
        <template #[`item.order`]="{ item }">
          <Input
            v-model="item.order"
            @input="changed"
          />
        </template>
        <template #[`item.action`]="{ item }">
          <v-btn
            color="default"
            class="action_button"
            @click.prevent="update(item)"
          >
            {{ $t('common.SAVE') }}
          </v-btn>
          <v-btn
            color="error"
            class="action_button"
            @click.prevent="deleteItem(item)"
          >
            {{ $t('common.DELETE') }}
          </v-btn>
        </template>
      </v-data-table>
    </div>
    <Pagination
      v-if="showTable"
      :target="pageTarget"
      :page-info="pageInfo"
      :page-size="pageSize"
      @resetData="resetData"
      @updatePageSize="$_pageSize_updatePageSize"
    />
    <CreateModal
      v-if="createModal"
      :create-url="createUrl"
      :title="title"
      :target="pageTarget"
      :store-url="storeUrl"
      @closeModal="closeModal"
      @initialize="fetchData"
    />
    <Modal
      v-if="deleteStatus"
      :title="$t('common.DELETE_WARNING1')"
      :desc="$t('common.DELETE_WARNING2')"
      @yes="removeSelectedItem"
      @no="cancel"
    />
  </div>
</template>

<script>
import CreateButton from "@/components/common/buttons/CreateButton"
import CreateModal from "@/components/adminCommon/CreateModal.vue"
import PageTitle from "@/components/common/headers/PageTitle"
import Pagination from "@/components/common/Pagination"
import bus from "../../utils/bus"
import Input from "@/components/common/formControls/Input.vue"
import errorHandling from "@/mixins/dataFetchErrorHandling"
import pageSize from "../../mixins/pageSize"
import Modal from "@/components/common/modals/Modal"

export default {
  components: {
    CreateButton,
    PageTitle,
    CreateModal,
    Pagination,
    Modal,
    Input,
  },
  mixins: [errorHandling, pageSize],
  data() {
    return {
      tableHeaders: [
        { text: "id", align: "start", value: "id", },
        { text: "name", value: "name", sortable: false, },
        { text: "order", value: "order", sortable: false, },
        { text: "action", value: "action", sortable: false, }],
      pageInfo: {},
      selectedItem: {},
      pageTarget: [null, null],
      tableData: [],
      createModal: false,
      showTable: false,
      changedStatus: false,
      deleteStatus: false,
      storeModuleName: "",
      createUrl: "",
      updateUrl: "",
      deleteUrl: "",
      fetchUrl: "",
      storeUrl: "",
      title: "",
      pageSize: 20,
      fetchParams: null,
    }
  },
  watch: {
    $route(to, from) {
      if (to.name !== from.name) {
        this.setInitialData()
      }
    },
  },
  created() {
    this.setInitialData()
  },
  methods: {
    setStoreUrl(routeName) {
      let url

      switch (routeName) {
        case "Funnel":
          url = "funnel"
          break
        case "EmailService":
          url =  "email_provider"
          break
        case "FakeName":
          url =  "fake_name"
          break
      }

      this.storeUrl = url
    },
    setStoreModuleName(routeName) {
      this.storeModuleName = routeName.toLowerCase()
    },
    async setInitialData() {
      const routeName = this.$route.name

      this.createModal = false
      this.setStoreUrl(routeName)
      this.setStoreModuleName(routeName)
      this.setTitle(routeName)
      this.setUrlData()
      this.setPageTarget(routeName)
      this.fetchParams = await this.$_pageSize_getTablePageSize(routeName)
      this.fetchData(this.fetchParams)
    },
    setTitle(routeName) {
      this.title = this.$t(`title.${routeName.toUpperCase()}`)
    },
    setUrlData() {
      this.fetchUrl = "adminCommon/fetchDataList"
      this.createUrl = "adminCommon/createDataItem"
      this.updateUrl = "adminCommon/updateDataItem"
      this.deleteUrl = "adminCommon/deleteDataItem"
    },
    setPageTarget(routeName) {
      if (routeName === "FakeName") {
        this.pageTarget[0] = "fkdl"
        this.pageTarget[1] = "fkpi"
      } else {
        this.pageTarget[0] = `${routeName.slice(0, 1).toLowerCase()}dl`
        this.pageTarget[1] = `${routeName.slice(0, 1).toLowerCase()}pi`
      }
    },
    async fetchData(keyword = {}) {
      try {
        this.showTable = false
        const response = await this.$store.dispatch(this.fetchUrl, { url: this.storeUrl, params: keyword, })

        this.tableData = response.data
        this.pageInfo = response.pagination
        bus.$emit("end:loading")
        this.showTable = true
      } catch (error) {
        this.errorHandling(error)
      }
    },
    openModal() {
      this.createModal = true
    },
    closeModal() {
      this.createModal = false
    },
    async resetData(responseData) {
      this.showTable = false
      this.tableData = responseData.dataList
      this.pageInfo = responseData.pageInfo
      this.showTable = true
    },
    updateTableData(updatedData) {
      this.tableData = updatedData
    },
    async update(item) {
      if (!this.changedStatus) {
        return
      }

      const data = {
        url: this.storeUrl,
        id: item.id,
        data: {
          name: item.name,
          order: ~~item.order,
        },
      }

      await this.$store.dispatch(this.updateUrl, data)
      this.updateItems(item)
      this.changedStatus = false
      bus.$emit("end:loading")
    },
    updateItems(item) {
      this.tableData = this.tableData.map((originItem) => {
        if (originItem.id === item.id) {
          return { ...item, order: ~~item.order, }
        }

        return originItem
      })

      this.fetchData()
    },
    deleteItem(item) {
      this.selectedItem = item
      this.deleteStatus = true
    },
    async removeSelectedItem() {
      const item = this.selectedItem

      await this.$store.dispatch(this.deleteUrl, { url: this.storeUrl, id: item.id, })
      this.tableData = this.tableData.filter((originItem) => originItem.id !== item.id)
      this.fetchData()
      this.deleteStatus = false
    },
    changed() {
      this.changedStatus = true
    },
    cancel() {
      this.deleteStatus = false
    },
    resetItems(data) {
      this.items = data
    },
  },
}
</script>

<style scoped>
@import url("../../assets/styles/common/adminCommonComponents.css");

.common_components_wrapper {
  position: relative;
}

.createButton {
  margin-bottom: 1rem;
}

.create_button_position {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.action_button {
  margin-right: 1rem;
}

.action_button:last-of-type {
  margin-right: 0;
}
</style>

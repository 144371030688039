<template>
  <v-select
    v-model="filterItem"
    class="filter"
    outlined
    dense
    hide-details
    :items="options"
    :item-value="itemValue"
    :label="$t(optionNames[name])"
  />
</template>

<script>
import { mapOptionName, } from "@/constants/mapVarNames"

export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    menu: {
      type: String,
      default: "",
    },
    category: {
      type: String,
      default: "",
    },
    itemValue: {
      type: String,
      default: "value",
    },
    options: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      optionNames: mapOptionName,
    }
  },
  computed: {
    filterItem: {
      get() {
        return this.$store.state.lookup[this.menu][this.category].filterItems[this.name]
      },
      set(value) {
        const item = this.name

        this.$store.commit("SET_FILTER_OPTIONS", { item, value, })
      },
    },
  },
  watch: {
    filterItem() {
      if (this.filterItem) {
        this.filterItem && this.$store.dispatch("fetchLookupData")
      }
    },
  },
}
</script>

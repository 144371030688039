<template>
  <div class="wrapper">
    <PageTitle :value="$t('nav.USER_STAFF')" />
    <Search :label="$t('lookupPlaceholder.STAFF')" />
    <v-app>
      <FilterList />
      <v-data-table
        class="elevation-1"
        :headers="headers"
        :items="dataList"
        :options.sync="sortOptions"
        :hide-default-footer="true"
        :server-items-length="dataList.length"
        @click:row="handleClickRow"
      >
        <template #[`item.is_active`]="{ item }">
          <TableIcon :value="item.is_active" />
        </template>
        <template #[`item.is_staff`]="{ item }">
          <TableIcon :value="item.is_staff" />
        </template>
        <template #[`item.is_admin`]="{ item }">
          <TableIcon :value="item.is_admin" />
        </template>
        <template #[`item.is_superuser`]="{ item }">
          <TableIcon :value="item.is_superuser" />
        </template>
      </v-data-table>
      <Pagination />
    </v-app>
  </div>
</template>

<script>
import table from "@/mixins/table"
import sort from "@/mixins/sort"
import TableIcon from "@/components/lookup/TableIcon"
import { MENU, CATEGORY, } from "@/constants/mapVarNames"

export default {
  components: {
    TableIcon,
  },
  mixins: [table, sort],
  data() {
    return {
      filterItems: {
        is_active: null,
      },
      headers: [
        {
          text: this.$t("headers.ID"),
          value: "id",
          sortable: true,
        },
        {
          text: this.$t("headers.USERNAME"),
          value: "username",
          sortable: true,
        },
        {
          text: this.$t("headers.IS_ACTIVE"),
          value: "is_active",
          sortable: false,
        },
        {
          text: this.$t("headers.IS_STAFF"),
          value: "is_staff",
          sortable: false,
        },
        {
          text: this.$t("headers.IS_SUPERUSER"),
          value: "is_superuser",
          sortable: false,
        },
        {
          text: this.$t("headers.IS_ADMIN"),
          value: "is_admin",
          sortable: false,
        },
        {
          text: this.$t("headers.COMPANY_APPROVED"),
          value: "company_approved",
          sortable: false,
        },
        {
          text: this.$t("headers.DATE_JOINED"),
          value: "date_joined",
          sortable: false,
        }
      ],
      sortOptions: {},
    }
  },
  watch: {
    sortOptions: {
      handler(value) {
        if (value.sortBy[0]) {
          this.$_sort_handleSort(value.sortBy[0])
        }
      },
      deep: true,
    },
  },
  created() {
    this.$store.commit("SET_LOOKUP_CATEGORY", { menu: MENU.users, category: CATEGORY.staff, })
    this.$store.dispatch("setFilterItems", this.filterItems)
    this.$store.dispatch("fetchLookupData")
  },
  methods: {
    handleClickRow(rowData) {
      const dataId = rowData.id

      this.$router.push({ name: "staffDetail", params: { id: dataId, }, })
    },
  },
}
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
